import { getFarmsPriceHelperLpFiles } from '@donaswap/farms/constants/priceHelperLps/getFarmsPriceHelperLpFiles'
import { ChainId } from '@donaswap/sdk'
import PoolsFirechainPriceHelper from './pools/529'
import PoolsRiniaPriceHelper from './pools/917'
import PoolsFirechainZkevmPriceHelper from './pools/814'
import PoolsFirechainZkevmGhostriderPriceHelper from './pools/3885'
import PoolsEthereumPriceHelper from './pools/1'
import PoolsGoerliPriceHelper from './pools/5'
import PoolsSepoliaPriceHelper from './pools/11155111'
import PoolsHoleskyPriceHelper from './pools/17000'
import PoolsBscPriceHelper from './pools/56'
import PoolsBscTestnetPriceHelper from './pools/97'
import PoolsPolygonPriceHelper from './pools/137'
import PoolsPolygonAmoyPriceHelper from './pools/80002'
import PoolsPolygonZkEvmPriceHelper from './pools/1101'
import PoolsPolygonZkEvmTestnetPriceHelper from './pools/1442'
import PoolsCronosPriceHelper from './pools/25'
import PoolsCronosTestnetPriceHelper from './pools/338'
import PoolsAvalanchePriceHelper from './pools/43114'
import PoolsAvalancheFujiPriceHelper from './pools/43113'
import PoolsFantomOperaPriceHelper from './pools/250'
import PoolsFantomTestnetPriceHelper from './pools/4002'
import PoolsCeloPriceHelper from './pools/42220'
import PoolsCeloAlfajoresPriceHelper from './pools/44787'
import PoolsOptimismPriceHelper from './pools/10'
import PoolsOptimismGoerliPriceHelper from './pools/420'
import PoolsArbitrumPriceHelper from './pools/42161'
import PoolsArbitrumGoerliPriceHelper from './pools/421613'
import PoolsShibariumPriceHelper from './pools/109'
import PoolsShibariumTestnetPriceHelper from './pools/719'
import PoolsPulsechainPriceHelper from './pools/369'
import PoolsPulsechainTestnetPriceHelper from './pools/943'
import PoolsXenPriceHelper from './pools/20221200000'
import PoolsXenDevnetPriceHelper from './pools/202212'
import PoolsHarmonyPriceHelper from './pools/1666600000'
import PoolsHarmonyTestnetPriceHelper from './pools/1666700000'
import PoolsCoinExPriceHelper from './pools/52'
import PoolsCoinExTestnetPriceHelper from './pools/53'
import PoolsDogechainPriceHelper from './pools/2000'
import PoolsDogechainTestnetPriceHelper from './pools/568'
import PoolsEngramTestnetPriceHelper from './pools/131'
import PoolsEtcPriceHelper from './pools/61'
import PoolsEtcTestnetPriceHelper from './pools/63'
import PoolsFusionPriceHelper from './pools/32659'
import PoolsFusionTestnetPriceHelper from './pools/46688'
import PoolsHecoPriceHelper from './pools/128'
import PoolsHecoTestnetPriceHelper from './pools/256'
import PoolsKccPriceHelper from './pools/321'
import PoolsKccTestnetPriceHelper from './pools/322'
import PoolsKlaytnPriceHelper from './pools/8217'
import PoolsKlaytnBaobabPriceHelper from './pools/1001'
import PoolsOkxchainPriceHelper from './pools/66'
import PoolsOkxchainTestnetPriceHelper from './pools/65'
import PoolsThetaPriceHelper from './pools/361'
import PoolsThetaTestnetPriceHelper from './pools/365'
import PoolsUltronPriceHelper from './pools/1231'
import PoolsUltronTestnetPriceHelper from './pools/1230'
import PoolsMoonbeamPriceHelper from './pools/1284'
import PoolsMoonriverPriceHelper from './pools/1285'
import PoolsMoonbaseAlphaPriceHelper from './pools/1287'
import PoolsAuroraPriceHelper from './pools/1313161554'
import PoolsAuroraTestnetPriceHelper from './pools/1313161555'
import PoolsBobaPriceHelper from './pools/288'
import PoolsBobaGoerliPriceHelper from './pools/2888'
import PoolsGnosisPriceHelper from './pools/100'
import PoolsGnosisChiadoPriceHelper from './pools/10200'
import PoolsMetisPriceHelper from './pools/1088'
import PoolsMetisGoerliPriceHelper from './pools/599'
import PoolsTelosPriceHelper from './pools/40'
import PoolsTelosTestnetPriceHelper from './pools/41'
import PoolsSyscoinPriceHelper from './pools/57'
import PoolsSyscoinTanenbaumPriceHelper from './pools/5700'
import PoolsIotexPriceHelper from './pools/4689'
import PoolsIotexTestnetPriceHelper from './pools/4690'
import PoolsShidenPriceHelper from './pools/336'
import PoolsAstarPriceHelper from './pools/592'
import PoolsShibunyaTestnetPriceHelper from './pools/81'
import PoolsFusePriceHelper from './pools/122'
import PoolsFuseTestnetPriceHelper from './pools/123'
import PoolsVelasPriceHelper from './pools/106'
import FarmVelasTestnetsPriceHelper from './pools/111'
import PoolsReiPriceHelper from './pools/55555'
import PoolsReiTestnetPriceHelper from './pools/55556'
import PoolsKekchainPriceHelper from './pools/420420'
import PoolsKekchainTestnetPriceHelper from './pools/420666'
import PoolsTomochainPriceHelper from './pools/88'
import PoolsTomochainTestnetPriceHelper from './pools/89'
import PoolsThundercorePriceHelper from './pools/108'
import PoolsThundercoreTestnetPriceHelper from './pools/18'
import PoolsWanchainPriceHelper from './pools/888'
import PoolsWanchainTestnetPriceHelper from './pools/999'
import PoolsRskPriceHelper from './pools/30'
import PoolsRskTestnetPriceHelper from './pools/31'
import PoolsElastosPriceHelper from './pools/20'
import PoolsElastosTestnetPriceHelper from './pools/21'
import PoolsConfluxPriceHelper from './pools/1030'
import PoolsConfluxTestnetPriceHelper from './pools/71'
import PoolsBrisechainPriceHelper from './pools/32520'
import PoolsBrisechainTestnetPriceHelper from './pools/64668'
import PoolsMuuchainPriceHelper from './pools/20402'
import PoolsMuuchainTestnetPriceHelper from './pools/42110'
import PoolsCantoPriceHelper from './pools/7700'
import PoolsCantoTestnetPriceHelper from './pools/7701'
import PoolsOasisEmeraldPriceHelper from './pools/42262'
import PoolsOasisTestnetPriceHelper from './pools/42261'
import PoolsVisionPriceHelper from './pools/888888'
import PoolsVisionTestnetPriceHelper from './pools/666666'
import PoolsStepPriceHelper from './pools/1234'
import PoolsStepTestnetPriceHelper from './pools/12345'
import PoolsMeterPriceHelper from './pools/82'
import PoolsMeterTestnetPriceHelper from './pools/83'
import PoolsGodwokenPriceHelper from './pools/71402'
import PoolsGodwokenTestnetPriceHelper from './pools/71401'
import PoolsCallistoPriceHelper from './pools/820'
import PoolsCallistoTestnetPriceHelper from './pools/20729'
import PoolsEvmosPriceHelper from './pools/9001'
import PoolsEvmosTestnetPriceHelper from './pools/9000'
import PoolsEnergyWebChainPriceHelper from './pools/246'
import PoolsEnergyVoltaPriceHelper from './pools/73799'
import PoolsBasePriceHelper from './pools/8453'
import PoolsBaseGoerliPriceHelper from './pools/84531'
import PoolsKavaPriceHelper from './pools/2222'
import PoolsKavaTestnetPriceHelper from './pools/2221'
import PoolsCloverPriceHelper from './pools/1024'
import PoolsCloverTestnetPriceHelper from './pools/1023'
import PoolsDefichainPriceHelper from './pools/1131'
import PoolsDefichainTestnetPriceHelper from './pools/1132'
import PoolsBronosPriceHelper from './pools/1039'
import PoolsBronosTestnetPriceHelper from './pools/1038'
import PoolsFilecoinPriceHelper from './pools/314'
import PoolsFilecoinCalibrationPriceHelper from './pools/314159'
import PoolsFlarePriceHelper from './pools/14'
import PoolsFlareTestnetPriceHelper from './pools/114'
import PoolsTaraxaPriceHelper from './pools/841'
import PoolsTaraxaTestnetPriceHelper from './pools/842'
import PoolsZksyncPriceHelper from './pools/324'
import PoolsZksyncTestnetPriceHelper from './pools/280'
import PoolsLineaPriceHelper from './pools/59144'
import PoolsLineaTestnetPriceHelper from './pools/59140'
import PoolsOpBnbPriceHelper from './pools/204'
import PoolsOpBnbTestnetPriceHelper from './pools/5611'
import PoolsBobaAvaxPriceHelper from './pools/43288'
import PoolsBobaBnbPriceHelper from './pools/56288'
import PoolsBttchainPriceHelper from './pools/199'
import PoolsBttchainTestnetPriceHelper from './pools/1029'
import PoolsCmpPriceHelper from './pools/256256'
import PoolsCmpTestnetPriceHelper from './pools/512512'
import PoolsCorePriceHelper from './pools/1116'
import PoolsCoreTestnetPriceHelper from './pools/1115'
import PoolsCubechainPriceHelper from './pools/1818'
import PoolsCubechainTestnetPriceHelper from './pools/1819'
import PoolsDarwiniaCrabPriceHelper from './pools/44'
import PoolsDarwiniaPangolinPriceHelper from './pools/43'
import PoolsDiodePretnetPriceHelper from './pools/15'
import PoolsDiodeTestnetPriceHelper from './pools/13'
import PoolsGochainPriceHelper from './pools/60'
import PoolsGochainTestnetPriceHelper from './pools/31337'
import PoolsHaqqPriceHelper from './pools/11235'
import PoolsHaqqTestedgePriceHelper from './pools/54211'
import PoolsKardiachainPriceHelper from './pools/24'
import PoolsKardiachainTestnetPriceHelper from './pools/242'
import PoolsMetadiumPriceHelper from './pools/11'
import PoolsMetadiumTestnetPriceHelper from './pools/12'
import PoolsPalmPriceHelper from './pools/11297108109'
import PoolsPalmTestnetPriceHelper from './pools/11297108099'
import PoolsPolisPriceHelper from './pools/333999'
import PoolsPolisTestnetPriceHelper from './pools/333888'
import PoolsShibachainPriceHelper from './pools/27'
import PoolsSmartBchPriceHelper from './pools/10000'
import PoolsSmartBchTestnetPriceHelper from './pools/10001'
import PoolsSongbirdCanaryPriceHelper from './pools/19'
import PoolsThaichainPriceHelper from './pools/7'
import PoolsUbiqPriceHelper from './pools/8'
import PoolsUbiqTestnetPriceHelper from './pools/9'
import PoolsVechainPriceHelper from './pools/100009'
import PoolsVechainTestnetPriceHelper from './pools/100010'
import PoolsXinfinPriceHelper from './pools/50'
import PoolsXinfinApothemPriceHelper from './pools/51'
import PoolsScrollPriceHelper from './pools/534352'
import PoolsScrollSepoliaPriceHelper from './pools/534351'
import PoolsXrplDevnetPriceHelper from './pools/1440002'
import PoolsZetachainPriceHelper from './pools/7000'
import PoolsZetachainAthensPriceHelper from './pools/7001'
import PoolsZyxPriceHelper from './pools/55'

export { getFarmsPriceHelperLpFiles }

export const getPoolsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.FIRECHAIN:
      return PoolsFirechainPriceHelper
    case ChainId.RINIA:
      return PoolsRiniaPriceHelper
    case ChainId.FIRECHAIN_ZKEVM:
      return PoolsFirechainZkevmPriceHelper
    case ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER:
      return PoolsFirechainZkevmGhostriderPriceHelper
    case ChainId.BSC:
      return PoolsBscPriceHelper
    case ChainId.BSC_TESTNET:
      return PoolsBscTestnetPriceHelper
    case ChainId.ETHEREUM:
      return PoolsEthereumPriceHelper
    case ChainId.GOERLI:
      return PoolsGoerliPriceHelper
    case ChainId.SEPOLIA:
      return PoolsSepoliaPriceHelper
    case ChainId.HOLESKY:
      return PoolsHoleskyPriceHelper
    case ChainId.POLYGON:
      return PoolsPolygonPriceHelper
    case ChainId.POLYGON_AMOY:
      return PoolsPolygonAmoyPriceHelper
    case ChainId.POLYGON_ZKEVM:
      return PoolsPolygonZkEvmPriceHelper
    case ChainId.POLYGON_ZKEVM_TESTNET:
      return PoolsPolygonZkEvmTestnetPriceHelper
    case ChainId.CRO:
      return PoolsCronosPriceHelper
    case ChainId.CRO_TESTNET:
      return PoolsCronosTestnetPriceHelper
    case ChainId.AVALANCHE:
      return PoolsAvalanchePriceHelper
    case ChainId.AVALANCHE_FUJI:
      return PoolsAvalancheFujiPriceHelper
    case ChainId.FANTOM_OPERA:
      return PoolsFantomOperaPriceHelper
    case ChainId.FANTOM_TESTNET:
      return PoolsFantomTestnetPriceHelper
    case ChainId.CELO:
      return PoolsCeloPriceHelper
    case ChainId.CELO_ALFAJORES:
      return PoolsCeloAlfajoresPriceHelper
    case ChainId.OPTIMISM:
      return PoolsOptimismPriceHelper
    case ChainId.OPTIMISM_GOERLI:
      return PoolsOptimismGoerliPriceHelper
    case ChainId.ARBITRUM_ONE:
      return PoolsArbitrumPriceHelper
    case ChainId.ARBITRUM_GOERLI:
      return PoolsArbitrumGoerliPriceHelper
    case ChainId.SHIBARIUM:
      return PoolsShibariumPriceHelper
    case ChainId.SHIBARIUM_TESTNET:
      return PoolsShibariumTestnetPriceHelper
    case ChainId.PULSECHAIN:
      return PoolsPulsechainPriceHelper
    case ChainId.PULSECHAIN_TESTNET:
      return PoolsPulsechainTestnetPriceHelper
    case ChainId.XEN:
      return PoolsXenPriceHelper
    case ChainId.XEN_DEVNET:
      return PoolsXenDevnetPriceHelper
    case ChainId.HARMONY:
      return PoolsHarmonyPriceHelper
    case ChainId.HARMONY_TESTNET:
      return PoolsHarmonyTestnetPriceHelper
    case ChainId.COINEX:
      return PoolsCoinExPriceHelper
    case ChainId.COINEX_TESTNET:
      return PoolsCoinExTestnetPriceHelper
    case ChainId.DOGECHAIN:
      return PoolsDogechainPriceHelper
    case ChainId.DOGECHAIN_TESTNET:
      return PoolsDogechainTestnetPriceHelper
    case ChainId.ENGRAM_TESTNET:
      return PoolsEngramTestnetPriceHelper
    case ChainId.ETHEREUM_CLASSIC:
      return PoolsEtcPriceHelper
    case ChainId.ETHEREUM_CLASSIC_TESTNET:
      return PoolsEtcTestnetPriceHelper
    case ChainId.FUSION:
      return PoolsFusionPriceHelper
    case ChainId.FUSION_TESTNET:
      return PoolsFusionTestnetPriceHelper
    case ChainId.HECO:
      return PoolsHecoPriceHelper
    case ChainId.HECO_TESTNET:
      return PoolsHecoTestnetPriceHelper
    case ChainId.KCC:
      return PoolsKccPriceHelper
    case ChainId.KCC_TESTNET:
      return PoolsKccTestnetPriceHelper
    case ChainId.KLAYTN:
      return PoolsKlaytnPriceHelper
    case ChainId.KLAYTN_BAOBAB:
      return PoolsKlaytnBaobabPriceHelper
    case ChainId.OKXCHAIN:
      return PoolsOkxchainPriceHelper
    case ChainId.OKXCHAIN_TESTNET:
      return PoolsOkxchainTestnetPriceHelper
    case ChainId.THETHA:
      return PoolsThetaPriceHelper
    case ChainId.THETHA_TESTNET:
      return PoolsThetaTestnetPriceHelper
    case ChainId.ULTRON:
      return PoolsUltronPriceHelper
    case ChainId.ULTRON_TESTNET:
      return PoolsUltronTestnetPriceHelper
    case ChainId.MOONBEAM:
      return PoolsMoonbeamPriceHelper
    case ChainId.MOONRIVER:
      return PoolsMoonriverPriceHelper
    case ChainId.MOONBASE_ALPHA:
      return PoolsMoonbaseAlphaPriceHelper
    case ChainId.AURORA:
      return PoolsAuroraPriceHelper
    case ChainId.AURORA_TESTNET:
      return PoolsAuroraTestnetPriceHelper
    case ChainId.BOBA:
      return PoolsBobaPriceHelper
    case ChainId.BOBA_GOERLI:
      return PoolsBobaGoerliPriceHelper
    case ChainId.GNOSIS:
      return PoolsGnosisPriceHelper
    case ChainId.GNOSIS_CHIADO:
      return PoolsGnosisChiadoPriceHelper
    case ChainId.METIS:
      return PoolsMetisPriceHelper
    case ChainId.METIS_GOERLI:
      return PoolsMetisGoerliPriceHelper
    case ChainId.TELOS:
      return PoolsTelosPriceHelper
    case ChainId.TELOS_TESTNET:
      return PoolsTelosTestnetPriceHelper
    case ChainId.SYSCOIN:
      return PoolsSyscoinPriceHelper
    case ChainId.SYSCOIN_TANENBAUM:
      return PoolsSyscoinTanenbaumPriceHelper
    case ChainId.IOTEX:
      return PoolsIotexPriceHelper
    case ChainId.IOTEX_TESTNET:
      return PoolsIotexTestnetPriceHelper
    case ChainId.SHIDEN:
      return PoolsShidenPriceHelper
    case ChainId.ASTAR:
      return PoolsAstarPriceHelper
    case ChainId.SHIBUNYA:
      return PoolsShibunyaTestnetPriceHelper
    case ChainId.FUSE:
      return PoolsFusePriceHelper
    case ChainId.FUSE_SPARKNET:
      return PoolsFuseTestnetPriceHelper
    case ChainId.VELAS:
      return PoolsVelasPriceHelper
    case ChainId.VELAS_TESTNET:
      return FarmVelasTestnetsPriceHelper
    case ChainId.REI:
      return PoolsReiPriceHelper
    case ChainId.REI_TESTNET:
      return PoolsReiTestnetPriceHelper
    case ChainId.KEKCHAIN:
      return PoolsKekchainPriceHelper
    case ChainId.KEKCHAIN_TESTNET:
      return PoolsKekchainTestnetPriceHelper
    case ChainId.TOMOCHAIN:
      return PoolsTomochainPriceHelper
    case ChainId.TOMOCHAIN_TESTNET:
      return PoolsTomochainTestnetPriceHelper
    case ChainId.THUNDERCORE:
      return PoolsThundercorePriceHelper
    case ChainId.THUNDERCORE_TESTNET:
      return PoolsThundercoreTestnetPriceHelper
    case ChainId.WANCHAIN:
      return PoolsWanchainPriceHelper
    case ChainId.WANCHAIN_TESTNET:
      return PoolsWanchainTestnetPriceHelper
    case ChainId.RSK:
      return PoolsRskPriceHelper
    case ChainId.RSK_TESTNET:
      return PoolsRskTestnetPriceHelper
    case ChainId.ELASTOS:
      return PoolsElastosPriceHelper
    case ChainId.ELASTOS_TESTNET:
      return PoolsElastosTestnetPriceHelper
    case ChainId.CONFLUX:
      return PoolsConfluxPriceHelper
    case ChainId.CONFLUX_TESTNET:
      return PoolsConfluxTestnetPriceHelper
    case ChainId.BRISECHAIN:
      return PoolsBrisechainPriceHelper
    case ChainId.BRISECHAIN_TESTNET:
      return PoolsBrisechainTestnetPriceHelper
    case ChainId.MUUCHAIN:
      return PoolsMuuchainPriceHelper
    case ChainId.MUUCHAIN_TESTNET:
      return PoolsMuuchainTestnetPriceHelper
    case ChainId.CANTO:
      return PoolsCantoPriceHelper
    case ChainId.CANTO_TESTNET:
      return PoolsCantoTestnetPriceHelper
    case ChainId.OASIS_EMERALD:
      return PoolsOasisEmeraldPriceHelper
    case ChainId.OASIS_TESTNET:
      return PoolsOasisTestnetPriceHelper
    case ChainId.VISION:
      return PoolsVisionPriceHelper
    case ChainId.VISION_TESTNET:
      return PoolsVisionTestnetPriceHelper
    case ChainId.STEP:
      return PoolsStepPriceHelper
    case ChainId.STEP_TESTNET:
      return PoolsStepTestnetPriceHelper
    case ChainId.METER:
      return PoolsMeterPriceHelper
    case ChainId.METER_TESTNET:
      return PoolsMeterTestnetPriceHelper
    case ChainId.GODWOKEN:
      return PoolsGodwokenPriceHelper
    case ChainId.GODWOKEN_TESTNET:
      return PoolsGodwokenTestnetPriceHelper
    case ChainId.CALLISTO:
      return PoolsCallistoPriceHelper
    case ChainId.CALLISTO_TESTNET:
      return PoolsCallistoTestnetPriceHelper
    case ChainId.EVMOS:
      return PoolsEvmosPriceHelper
    case ChainId.EVMOS_TESTNET:
      return PoolsEvmosTestnetPriceHelper
    case ChainId.ENERGY_WEB_CHAIN:
      return PoolsEnergyWebChainPriceHelper
    case ChainId.ENERGY_VOLTA:
      return PoolsEnergyVoltaPriceHelper
    case ChainId.BASE:
      return PoolsBasePriceHelper
    case ChainId.BASE_GOERLI:
      return PoolsBaseGoerliPriceHelper
    case ChainId.KAVA:
      return PoolsKavaPriceHelper
    case ChainId.KAVA_TESTNET:
      return PoolsKavaTestnetPriceHelper
    case ChainId.CLOVER:
      return PoolsCloverPriceHelper
    case ChainId.CLOVER_TESTNET:
      return PoolsCloverTestnetPriceHelper
    case ChainId.DEFICHAIN:
      return PoolsDefichainPriceHelper
    case ChainId.DEFICHAIN_TESTNET:
      return PoolsDefichainTestnetPriceHelper
    case ChainId.BRONOS:
      return PoolsBronosPriceHelper
    case ChainId.BRONOS_TESTNET:
      return PoolsBronosTestnetPriceHelper
    case ChainId.FILECOIN:
      return PoolsFilecoinPriceHelper
    case ChainId.FILECOIN_CALIBRATION:
      return PoolsFilecoinCalibrationPriceHelper
    case ChainId.FLARE:
      return PoolsFlarePriceHelper
    case ChainId.FLARE_TESTNET:
      return PoolsFlareTestnetPriceHelper
    case ChainId.TARAXA:
      return PoolsTaraxaPriceHelper
    case ChainId.TARAXA_TESTNET:
      return PoolsTaraxaTestnetPriceHelper
    case ChainId.ZKSYNC:
      return PoolsZksyncPriceHelper
    case ChainId.ZKSYNC_TESTNET:
      return PoolsZksyncTestnetPriceHelper
    case ChainId.LINEA:
      return PoolsLineaPriceHelper
    case ChainId.LINEA_TESTNET:
      return PoolsLineaTestnetPriceHelper
    case ChainId.BTTCHAIN:
      return PoolsBttchainPriceHelper
    case ChainId.BTTCHAIN_TESTNET:
      return PoolsBttchainTestnetPriceHelper
    case ChainId.BOBA_AVAX:
      return PoolsBobaAvaxPriceHelper
    case ChainId.BOBA_BNB:
      return PoolsBobaBnbPriceHelper
    case ChainId.CMP:
      return PoolsCmpPriceHelper
    case ChainId.CMP_TESTNET:
      return PoolsCmpTestnetPriceHelper
    case ChainId.CORE:
      return PoolsCorePriceHelper
    case ChainId.CORE_TESTNET:
      return PoolsCoreTestnetPriceHelper
    case ChainId.CUBECHAIN:
      return PoolsCubechainPriceHelper
    case ChainId.CUBECHAIN_TESTNET:
      return PoolsCubechainTestnetPriceHelper
    case ChainId.DARWINIA_CRAB:
      return PoolsDarwiniaCrabPriceHelper
    case ChainId.DARWINIA_PANGOLIN:
      return PoolsDarwiniaPangolinPriceHelper
    case ChainId.DIODE_PRETNET:
      return PoolsDiodePretnetPriceHelper
    case ChainId.DIODE_TESTNET:
      return PoolsDiodeTestnetPriceHelper
    case ChainId.GOCHAIN:
      return PoolsGochainPriceHelper
    case ChainId.GOCHAIN_TESTNET:
      return PoolsGochainTestnetPriceHelper
    case ChainId.HAQQ:
      return PoolsHaqqPriceHelper
    case ChainId.HAQQ_TESTEDGE2:
      return PoolsHaqqTestedgePriceHelper
    case ChainId.KARDIACHAIN:
      return PoolsKardiachainPriceHelper
    case ChainId.KARDIACHAIN_TESTNET:
      return PoolsKardiachainTestnetPriceHelper
    case ChainId.METADIUM:
      return PoolsMetadiumPriceHelper
    case ChainId.METADIUM_TESTNET:
      return PoolsMetadiumTestnetPriceHelper
    case ChainId.OP_BNB:
      return PoolsOpBnbPriceHelper
    case ChainId.OP_BNB_TESTNET:
      return PoolsOpBnbTestnetPriceHelper
    case ChainId.PALM:
      return PoolsPalmPriceHelper
    case ChainId.PALM_TESTNET:
      return PoolsPalmTestnetPriceHelper
    case ChainId.POLIS:
      return PoolsPolisPriceHelper
    case ChainId.POLIS_TESTNET:
      return PoolsPolisTestnetPriceHelper
    case ChainId.SHIBACHAIN:
      return PoolsShibachainPriceHelper
    case ChainId.SMART_BCH:
      return PoolsSmartBchPriceHelper
    case ChainId.SMART_BCH_TESTNET:
      return PoolsSmartBchTestnetPriceHelper
    case ChainId.SONGBIRD_CANARY:
      return PoolsSongbirdCanaryPriceHelper
    case ChainId.THAICHAIN:
      return PoolsThaichainPriceHelper
    case ChainId.UBIQ:
      return PoolsUbiqPriceHelper
    case ChainId.UBIQ_TESTNET:
      return PoolsUbiqTestnetPriceHelper
    case ChainId.VECHAIN:
      return PoolsVechainPriceHelper
    case ChainId.VECHAIN_TESTNET:
      return PoolsVechainTestnetPriceHelper
    case ChainId.XINFIN:
      return PoolsXinfinPriceHelper
    case ChainId.XINFIN_APOTHEM:
      return PoolsXinfinApothemPriceHelper
    case ChainId.SCROLL:
      return PoolsScrollPriceHelper
    case ChainId.SCROLL_SEPOLIA:
      return PoolsScrollSepoliaPriceHelper
    case ChainId.XRPL_DEVNET:
      return PoolsXrplDevnetPriceHelper
    case ChainId.ZETACHAIN:
      return PoolsZetachainPriceHelper
    case ChainId.ZETACHAIN_ATHENS:
      return PoolsZetachainAthensPriceHelper
    case ChainId.ZYX:
      return PoolsZyxPriceHelper
    default:
      return []
  }
}
