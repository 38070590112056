import { ChainId } from '@donaswap/sdk'
import FarmsFirechainPriceHelper from './529'
import FarmsRiniaPriceHelper from './917'
import FarmsFirechainZkevmPriceHelper from './814'
import FarmsFirechainZkevmGhostriderPriceHelper from './3885'
import FarmsBscPriceHelper from './56'
import FarmsBscTestnetPriceHelper from './97'
import FarmsEthereumPriceHelper from './1'
import FarmsGoerliPriceHelper from './5'
import FarmsSepoliaPriceHelper from './11155111'
import FarmsHoleskyPriceHelper from './17000'
import FarmsPolygonPriceHelper from './137'
import FarmsPolygonAmoyPriceHelper from './80002'
import FarmsPolygonZkEvmPriceHelper from './1101'
import FarmsPolygonZkEvmTestnetPriceHelper from './1442'
import FarmsCronosPriceHelper from './25'
import FarmsCronosTestnetPriceHelper from './338'
import FarmsAvalanchePriceHelper from './43114'
import FarmsAvalancheFujiPriceHelper from './43113'
import FarmsFantomOperaPriceHelper from './250'
import FarmsFantomTestnetPriceHelper from './4002'
import FarmsCeloPriceHelper from './42220'
import FarmsCeloAlfajoresPriceHelper from './44787'
import FarmsOptimismPriceHelper from './10'
import FarmsOptimismGoerliPriceHelper from './420'
import FarmsArbitrumPriceHelper from './42161'
import FarmsArbitrumGoerliPriceHelper from './421613'
import FarmsShibariumPriceHelper from './109'
import FarmsShibariumTestnetPriceHelper from './719'
import FarmsPulsechainPriceHelper from './369'
import FarmsPulsechainTestnetPriceHelper from './943'
import FarmsXenPriceHelper from './20221200000'
import FarmsXenDevnetPriceHelper from './202212'
import FarmsHarmonyPriceHelper from './1666600000'
import FarmsHarmonyTestnetPriceHelper from './1666700000'
import FarmsCoinExPriceHelper from './52'
import FarmsCoinExTestnetPriceHelper from './53'
import FarmsDogechainPriceHelper from './2000'
import FarmsDogechainTestnetPriceHelper from './568'
import FarmsEngramTestnetPriceHelper from './131'
import FarmsEtcPriceHelper from './61'
import FarmsEtcTestnetPriceHelper from './63'
import FarmsFusionPriceHelper from './32659'
import FarmsFusionTestnetPriceHelper from './46688'
import FarmsHecoPriceHelper from './128'
import FarmsHecoTestnetPriceHelper from './256'
import FarmsKccPriceHelper from './321'
import FarmsKccTestnetPriceHelper from './322'
import FarmsKlaytnPriceHelper from './8217'
import FarmsKlaytnBaobabPriceHelper from './1001'
import FarmsOkxchainPriceHelper from './66'
import FarmsOkxchainTestnetPriceHelper from './65'
import FarmsThetaPriceHelper from './361'
import FarmsThetaTestnetPriceHelper from './365'
import FarmsUltronPriceHelper from './1231'
import FarmsUltronTestnetPriceHelper from './1230'
import FarmsMoonbeamPriceHelper from './1284'
import FarmsMoonriverPriceHelper from './1285'
import FarmsMoonbaseAlphaPriceHelper from './1287'
import FarmsAuroraPriceHelper from './1313161554'
import FarmsAuroraTestnetPriceHelper from './1313161555'
import FarmsBobaPriceHelper from './288'
import FarmsBobaGoerliPriceHelper from './2888'
import FarmsGnosisPriceHelper from './100'
import FarmsGnosisChiadoPriceHelper from './10200'
import FarmsMetisPriceHelper from './1088'
import FarmsMetisGoerliPriceHelper from './599'
import FarmsTelosPriceHelper from './40'
import FarmsTelosTestnetPriceHelper from './41'
import FarmsSyscoinPriceHelper from './57'
import FarmsSyscoinTanenbaumPriceHelper from './5700'
import FarmsIotexPriceHelper from './4689'
import FarmsIotexTestnetPriceHelper from './4690'
import FarmsShidenPriceHelper from './336'
import FarmsAstarPriceHelper from './592'
import FarmsShibunyaTestnetPriceHelper from './81'
import FarmsFusePriceHelper from './122'
import FarmsFuseSparknetPriceHelper from './123'
import FarmsVelasPriceHelper from './106'
import FarmVelasTestnetsPriceHelper from './111'
import FarmsReiPriceHelper from './55555'
import FarmsReiTestnetPriceHelper from './55556'
import FarmsKekchainPriceHelper from './420420'
import FarmsKekchainTestnetPriceHelper from './420666'
import FarmsTomochainPriceHelper from './88'
import FarmsTomochainTestnetPriceHelper from './89'
import FarmsThundercorePriceHelper from './108'
import FarmsThundercoreTestnetPriceHelper from './18'
import FarmsWanchainPriceHelper from './888'
import FarmsWanchainTestnetPriceHelper from './999'
import FarmsRskPriceHelper from './30'
import FarmsRskTestnetPriceHelper from './31'
import FarmsElastosPriceHelper from './20'
import FarmsElastosTestnetPriceHelper from './21'
import FarmsConfluxPriceHelper from './1030'
import FarmsConfluxTestnetPriceHelper from './71'
import FarmsBrisechainPriceHelper from './32520'
import FarmsBrisechainTestnetPriceHelper from './64668'
import FarmsMuuchainPriceHelper from './20402'
import FarmsMuuchainTestnetPriceHelper from './42110'
import FarmsCantoPriceHelper from './7700'
import FarmsCantoTestnetPriceHelper from './7701'
import FarmsOasisEmeraldPriceHelper from './42262'
import FarmsOasisTestnetPriceHelper from './42261'
import FarmsVisionPriceHelper from './888888'
import FarmsVisionTestnetPriceHelper from './666666'
import FarmsStepPriceHelper from './1234'
import FarmsStepTestnetPriceHelper from './12345'
import FarmsMeterPriceHelper from './82'
import FarmsMeterTestnetPriceHelper from './83'
import FarmsGodwokenPriceHelper from './71402'
import FarmsGodwokenTestnetPriceHelper from './71401'
import FarmsCallistoPriceHelper from './820'
import FarmsCallistoTestnetPriceHelper from './20729'
import FarmsEvmosPriceHelper from './9001'
import FarmsEvmosTestnetPriceHelper from './9000'
import FarmsEnergyWebChainPriceHelper from './246'
import FarmsEnergyVoltaPriceHelper from './73799'
import FarmsBasePriceHelper from './8453'
import FarmsBaseGoerliPriceHelper from './84531'
import FarmsKavaPriceHelper from './2222'
import FarmsKavaTestnetPriceHelper from './2221'
import FarmsCloverPriceHelper from './1024'
import FarmsCloverTestnetPriceHelper from './1023'
import FarmsDefichainPriceHelper from './1131'
import FarmsDefichainTestnetPriceHelper from './1132'
import FarmsBronosPriceHelper from './1039'
import FarmsBronosTestnetPriceHelper from './1038'
import FarmsFilecoinPriceHelper from './314'
import FarmsFilecoinCalibrationPriceHelper from './314159'
import FarmsFlarePriceHelper from './14'
import FarmsFlareTestnetPriceHelper from './114'
import FarmsTaraxaPriceHelper from './841'
import FarmsTaraxaTestnetPriceHelper from './842'
import FarmsZksyncPriceHelper from './324'
import FarmsZksyncTestnetPriceHelper from './280'
import FarmsLineaPriceHelper from './59144'
import FarmsLineaTestnetPriceHelper from './59140'
import FarmsOpBnbPriceHelper from './204'
import FarmsOpBnbTestnetPriceHelper from './5611'
import FarmsBobaAvaxPriceHelper from './43288'
import FarmsBobaBnbPriceHelper from './56288'
import FarmsBttchainPriceHelper from './199'
import FarmsBttchainTestnetPriceHelper from './1029'
import FarmsCmpPriceHelper from './256256'
import FarmsCmpTestnetPriceHelper from './512512'
import FarmsCorePriceHelper from './1116'
import FarmsCoreTestnetPriceHelper from './1115'
import FarmsCubechainPriceHelper from './1818'
import FarmsCubechainTestnetPriceHelper from './1819'
import FarmsDarwiniaCrabPriceHelper from './44'
import FarmsDarwiniaPangolinPriceHelper from './43'
import FarmsDiodePretnetPriceHelper from './15'
import FarmsDiodeTestnetPriceHelper from './13'
import FarmsGochainPriceHelper from './60'
import FarmsGochainTestnetPriceHelper from './31337'
import FarmsHaqqPriceHelper from './11235'
import FarmsHaqqTestedgePriceHelper from './54211'
import FarmsKardiachainPriceHelper from './24'
import FarmsKardiachainTestnetPriceHelper from './242'
import FarmsMetadiumPriceHelper from './11'
import FarmsMetadiumTestnetPriceHelper from './12'
import FarmsPalmPriceHelper from './11297108109'
import FarmsPalmTestnetPriceHelper from './11297108099'
import FarmsPolisPriceHelper from './333999'
import FarmsPolisTestnetPriceHelper from './333888'
import FarmsShibachainPriceHelper from './27'
import FarmsSmartBchPriceHelper from './10000'
import FarmsSmartBchTestnetPriceHelper from './10001'
import FarmsSongbirdCanaryPriceHelper from './19'
import FarmsThaichainPriceHelper from './7'
import FarmsUbiqPriceHelper from './8'
import FarmsUbiqTestnetPriceHelper from './9'
import FarmsVechainPriceHelper from './100009'
import FarmsVechainTestnetPriceHelper from './100010'
import FarmsXinfinPriceHelper from './50'
import FarmsXinfinApothemPriceHelper from './51'
import FarmsScrollPriceHelper from './534352'
import FarmsScrollSepoliaPriceHelper from './534351'
import FarmsXrplDevnetPriceHelper from './1440002'
import FarmsZetachainPriceHelper from './7000'
import FarmsZetachainAthensPriceHelper from './7001'
import FarmsZyxPriceHelper from './55'

export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.FIRECHAIN:
      return FarmsFirechainPriceHelper
    case ChainId.RINIA:
      return FarmsRiniaPriceHelper
    case ChainId.FIRECHAIN_ZKEVM:
      return FarmsFirechainZkevmPriceHelper
    case ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER:
      return FarmsFirechainZkevmGhostriderPriceHelper
    case ChainId.BSC:
      return FarmsBscPriceHelper
    case ChainId.BSC_TESTNET:
      return FarmsBscTestnetPriceHelper
    case ChainId.ETHEREUM:
      return FarmsEthereumPriceHelper
    case ChainId.GOERLI:
      return FarmsGoerliPriceHelper
    case ChainId.SEPOLIA:
      return FarmsSepoliaPriceHelper
    case ChainId.HOLESKY:
      return FarmsHoleskyPriceHelper
    case ChainId.POLYGON:
      return FarmsPolygonPriceHelper
    case ChainId.POLYGON_AMOY:
      return FarmsPolygonAmoyPriceHelper
    case ChainId.POLYGON_ZKEVM:
      return FarmsPolygonZkEvmPriceHelper
    case ChainId.POLYGON_ZKEVM_TESTNET:
      return FarmsPolygonZkEvmTestnetPriceHelper
    case ChainId.CRO:
      return FarmsCronosPriceHelper
    case ChainId.CRO_TESTNET:
      return FarmsCronosTestnetPriceHelper
    case ChainId.AVALANCHE:
      return FarmsAvalanchePriceHelper
    case ChainId.AVALANCHE_FUJI:
      return FarmsAvalancheFujiPriceHelper
    case ChainId.FANTOM_OPERA:
      return FarmsFantomOperaPriceHelper
    case ChainId.FANTOM_TESTNET:
      return FarmsFantomTestnetPriceHelper
    case ChainId.CELO:
      return FarmsCeloPriceHelper
    case ChainId.CELO_ALFAJORES:
      return FarmsCeloAlfajoresPriceHelper
    case ChainId.OPTIMISM:
      return FarmsOptimismPriceHelper
    case ChainId.OPTIMISM_GOERLI:
      return FarmsOptimismGoerliPriceHelper
    case ChainId.ARBITRUM_ONE:
      return FarmsArbitrumPriceHelper
    case ChainId.ARBITRUM_GOERLI:
      return FarmsArbitrumGoerliPriceHelper
    case ChainId.SHIBARIUM:
      return FarmsShibariumPriceHelper
    case ChainId.SHIBARIUM_TESTNET:
      return FarmsShibariumTestnetPriceHelper
    case ChainId.PULSECHAIN:
      return FarmsPulsechainPriceHelper
    case ChainId.PULSECHAIN_TESTNET:
      return FarmsPulsechainTestnetPriceHelper
    case ChainId.XEN:
      return FarmsXenPriceHelper
    case ChainId.XEN_DEVNET:
      return FarmsXenDevnetPriceHelper
    case ChainId.HARMONY:
      return FarmsHarmonyPriceHelper
    case ChainId.HARMONY_TESTNET:
      return FarmsHarmonyTestnetPriceHelper
    case ChainId.COINEX:
      return FarmsCoinExPriceHelper
    case ChainId.COINEX_TESTNET:
      return FarmsCoinExTestnetPriceHelper
    case ChainId.DOGECHAIN:
      return FarmsDogechainPriceHelper
    case ChainId.DOGECHAIN_TESTNET:
      return FarmsDogechainTestnetPriceHelper
    case ChainId.ENGRAM_TESTNET:
      return FarmsEngramTestnetPriceHelper
    case ChainId.ETHEREUM_CLASSIC:
      return FarmsEtcPriceHelper
    case ChainId.ETHEREUM_CLASSIC_TESTNET:
      return FarmsEtcTestnetPriceHelper
    case ChainId.FUSION:
      return FarmsFusionPriceHelper
    case ChainId.FUSION_TESTNET:
      return FarmsFusionTestnetPriceHelper
    case ChainId.HECO:
      return FarmsHecoPriceHelper
    case ChainId.HECO_TESTNET:
      return FarmsHecoTestnetPriceHelper
    case ChainId.KCC:
      return FarmsKccPriceHelper
    case ChainId.KCC_TESTNET:
      return FarmsKccTestnetPriceHelper
    case ChainId.KLAYTN:
      return FarmsKlaytnPriceHelper
    case ChainId.KLAYTN_BAOBAB:
      return FarmsKlaytnBaobabPriceHelper
    case ChainId.OKXCHAIN:
      return FarmsOkxchainPriceHelper
    case ChainId.OKXCHAIN_TESTNET:
      return FarmsOkxchainTestnetPriceHelper
    case ChainId.THETHA:
      return FarmsThetaPriceHelper
    case ChainId.THETHA_TESTNET:
      return FarmsThetaTestnetPriceHelper
    case ChainId.ULTRON:
      return FarmsUltronPriceHelper
    case ChainId.ULTRON_TESTNET:
      return FarmsUltronTestnetPriceHelper
    case ChainId.MOONBEAM:
      return FarmsMoonbeamPriceHelper
    case ChainId.MOONRIVER:
      return FarmsMoonriverPriceHelper
    case ChainId.MOONBASE_ALPHA:
      return FarmsMoonbaseAlphaPriceHelper
    case ChainId.AURORA:
      return FarmsAuroraPriceHelper
    case ChainId.AURORA_TESTNET:
      return FarmsAuroraTestnetPriceHelper
    case ChainId.BOBA:
      return FarmsBobaPriceHelper
    case ChainId.BOBA_GOERLI:
      return FarmsBobaGoerliPriceHelper
    case ChainId.GNOSIS:
      return FarmsGnosisPriceHelper
    case ChainId.GNOSIS_CHIADO:
      return FarmsGnosisChiadoPriceHelper
    case ChainId.METIS:
      return FarmsMetisPriceHelper
    case ChainId.METIS_GOERLI:
      return FarmsMetisGoerliPriceHelper
    case ChainId.TELOS:
      return FarmsTelosPriceHelper
    case ChainId.TELOS_TESTNET:
      return FarmsTelosTestnetPriceHelper
    case ChainId.SYSCOIN:
      return FarmsSyscoinPriceHelper
    case ChainId.SYSCOIN_TANENBAUM:
      return FarmsSyscoinTanenbaumPriceHelper
    case ChainId.IOTEX:
      return FarmsIotexPriceHelper
    case ChainId.IOTEX_TESTNET:
      return FarmsIotexTestnetPriceHelper
    case ChainId.SHIDEN:
      return FarmsShidenPriceHelper
    case ChainId.ASTAR:
      return FarmsAstarPriceHelper
    case ChainId.SHIBUNYA:
      return FarmsShibunyaTestnetPriceHelper
    case ChainId.FUSE:
      return FarmsFusePriceHelper
    case ChainId.FUSE_SPARKNET:
      return FarmsFuseSparknetPriceHelper
    case ChainId.VELAS:
      return FarmsVelasPriceHelper
    case ChainId.VELAS_TESTNET:
      return FarmVelasTestnetsPriceHelper
    case ChainId.REI:
      return FarmsReiPriceHelper
    case ChainId.REI_TESTNET:
      return FarmsReiTestnetPriceHelper
    case ChainId.KEKCHAIN:
      return FarmsKekchainPriceHelper
    case ChainId.KEKCHAIN_TESTNET:
      return FarmsKekchainTestnetPriceHelper
    case ChainId.TOMOCHAIN:
      return FarmsTomochainPriceHelper
    case ChainId.TOMOCHAIN_TESTNET:
      return FarmsTomochainTestnetPriceHelper
    case ChainId.THUNDERCORE:
      return FarmsThundercorePriceHelper
    case ChainId.THUNDERCORE_TESTNET:
      return FarmsThundercoreTestnetPriceHelper
    case ChainId.WANCHAIN:
      return FarmsWanchainPriceHelper
    case ChainId.WANCHAIN_TESTNET:
      return FarmsWanchainTestnetPriceHelper
    case ChainId.RSK:
      return FarmsRskPriceHelper
    case ChainId.RSK_TESTNET:
      return FarmsRskTestnetPriceHelper
    case ChainId.ELASTOS:
      return FarmsElastosPriceHelper
    case ChainId.ELASTOS_TESTNET:
      return FarmsElastosTestnetPriceHelper
    case ChainId.CONFLUX:
      return FarmsConfluxPriceHelper
    case ChainId.CONFLUX_TESTNET:
      return FarmsConfluxTestnetPriceHelper
    case ChainId.BRISECHAIN:
      return FarmsBrisechainPriceHelper
    case ChainId.BRISECHAIN_TESTNET:
      return FarmsBrisechainTestnetPriceHelper
    case ChainId.MUUCHAIN:
      return FarmsMuuchainPriceHelper
    case ChainId.MUUCHAIN_TESTNET:
      return FarmsMuuchainTestnetPriceHelper
    case ChainId.CANTO:
      return FarmsCantoPriceHelper
    case ChainId.CANTO_TESTNET:
      return FarmsCantoTestnetPriceHelper
    case ChainId.OASIS_EMERALD:
      return FarmsOasisEmeraldPriceHelper
    case ChainId.OASIS_TESTNET:
      return FarmsOasisTestnetPriceHelper
    case ChainId.VISION:
      return FarmsVisionPriceHelper
    case ChainId.VISION_TESTNET:
      return FarmsVisionTestnetPriceHelper
    case ChainId.STEP:
      return FarmsStepPriceHelper
    case ChainId.STEP_TESTNET:
      return FarmsStepTestnetPriceHelper
    case ChainId.METER:
      return FarmsMeterPriceHelper
    case ChainId.METER_TESTNET:
      return FarmsMeterTestnetPriceHelper
    case ChainId.GODWOKEN:
      return FarmsGodwokenPriceHelper
    case ChainId.GODWOKEN_TESTNET:
      return FarmsGodwokenTestnetPriceHelper
    case ChainId.CALLISTO:
      return FarmsCallistoPriceHelper
    case ChainId.CALLISTO_TESTNET:
      return FarmsCallistoTestnetPriceHelper
    case ChainId.EVMOS:
      return FarmsEvmosPriceHelper
    case ChainId.EVMOS_TESTNET:
      return FarmsEvmosTestnetPriceHelper
    case ChainId.ENERGY_WEB_CHAIN:
      return FarmsEnergyWebChainPriceHelper
    case ChainId.ENERGY_VOLTA:
      return FarmsEnergyVoltaPriceHelper
    case ChainId.BASE:
      return FarmsBasePriceHelper
    case ChainId.BASE_GOERLI:
      return FarmsBaseGoerliPriceHelper
    case ChainId.KAVA:
      return FarmsKavaPriceHelper
    case ChainId.KAVA_TESTNET:
      return FarmsKavaTestnetPriceHelper
    case ChainId.CLOVER:
      return FarmsCloverPriceHelper
    case ChainId.CLOVER_TESTNET:
      return FarmsCloverTestnetPriceHelper
    case ChainId.DEFICHAIN:
      return FarmsDefichainPriceHelper
    case ChainId.DEFICHAIN_TESTNET:
      return FarmsDefichainTestnetPriceHelper
    case ChainId.BRONOS:
      return FarmsBronosPriceHelper
    case ChainId.BRONOS_TESTNET:
      return FarmsBronosTestnetPriceHelper
    case ChainId.FILECOIN:
      return FarmsFilecoinPriceHelper
    case ChainId.FILECOIN_CALIBRATION:
      return FarmsFilecoinCalibrationPriceHelper
    case ChainId.FLARE:
      return FarmsFlarePriceHelper
    case ChainId.FLARE_TESTNET:
      return FarmsFlareTestnetPriceHelper
    case ChainId.TARAXA:
      return FarmsTaraxaPriceHelper
    case ChainId.TARAXA_TESTNET:
      return FarmsTaraxaTestnetPriceHelper
    case ChainId.ZKSYNC:
      return FarmsZksyncPriceHelper
    case ChainId.ZKSYNC_TESTNET:
      return FarmsZksyncTestnetPriceHelper
    case ChainId.LINEA:
      return FarmsLineaPriceHelper
    case ChainId.LINEA_TESTNET:
      return FarmsLineaTestnetPriceHelper
    case ChainId.BTTCHAIN:
      return FarmsBttchainPriceHelper
    case ChainId.BTTCHAIN_TESTNET:
      return FarmsBttchainTestnetPriceHelper
    case ChainId.BOBA_AVAX:
      return FarmsBobaAvaxPriceHelper
    case ChainId.BOBA_BNB:
      return FarmsBobaBnbPriceHelper
    case ChainId.CMP:
      return FarmsCmpPriceHelper
    case ChainId.CMP_TESTNET:
      return FarmsCmpTestnetPriceHelper
    case ChainId.CORE:
      return FarmsCorePriceHelper
    case ChainId.CORE_TESTNET:
      return FarmsCoreTestnetPriceHelper
    case ChainId.CUBECHAIN:
      return FarmsCubechainPriceHelper
    case ChainId.CUBECHAIN_TESTNET:
      return FarmsCubechainTestnetPriceHelper
    case ChainId.DARWINIA_CRAB:
      return FarmsDarwiniaCrabPriceHelper
    case ChainId.DARWINIA_PANGOLIN:
      return FarmsDarwiniaPangolinPriceHelper
    case ChainId.DIODE_PRETNET:
      return FarmsDiodePretnetPriceHelper
    case ChainId.DIODE_TESTNET:
      return FarmsDiodeTestnetPriceHelper
    case ChainId.GOCHAIN:
      return FarmsGochainPriceHelper
    case ChainId.GOCHAIN_TESTNET:
      return FarmsGochainTestnetPriceHelper
    case ChainId.HAQQ:
      return FarmsHaqqPriceHelper
    case ChainId.HAQQ_TESTEDGE2:
      return FarmsHaqqTestedgePriceHelper
    case ChainId.KARDIACHAIN:
      return FarmsKardiachainPriceHelper
    case ChainId.KARDIACHAIN_TESTNET:
      return FarmsKardiachainTestnetPriceHelper
    case ChainId.METADIUM:
      return FarmsMetadiumPriceHelper
    case ChainId.METADIUM_TESTNET:
      return FarmsMetadiumTestnetPriceHelper
    case ChainId.OP_BNB:
      return FarmsOpBnbPriceHelper
    case ChainId.OP_BNB_TESTNET:
      return FarmsOpBnbTestnetPriceHelper
    case ChainId.PALM:
      return FarmsPalmPriceHelper
    case ChainId.PALM_TESTNET:
      return FarmsPalmTestnetPriceHelper
    case ChainId.POLIS:
      return FarmsPolisPriceHelper
    case ChainId.POLIS_TESTNET:
      return FarmsPolisTestnetPriceHelper
    case ChainId.SHIBACHAIN:
      return FarmsShibachainPriceHelper
    case ChainId.SMART_BCH:
      return FarmsSmartBchPriceHelper
    case ChainId.SMART_BCH_TESTNET:
      return FarmsSmartBchTestnetPriceHelper
    case ChainId.SONGBIRD_CANARY:
      return FarmsSongbirdCanaryPriceHelper
    case ChainId.THAICHAIN:
      return FarmsThaichainPriceHelper
    case ChainId.UBIQ:
      return FarmsUbiqPriceHelper
    case ChainId.UBIQ_TESTNET:
      return FarmsUbiqTestnetPriceHelper
    case ChainId.VECHAIN:
      return FarmsVechainPriceHelper
    case ChainId.VECHAIN_TESTNET:
      return FarmsVechainTestnetPriceHelper
    case ChainId.XINFIN:
      return FarmsXinfinPriceHelper
    case ChainId.XINFIN_APOTHEM:
      return FarmsXinfinApothemPriceHelper
    case ChainId.SCROLL:
      return FarmsScrollPriceHelper
    case ChainId.SCROLL_SEPOLIA:
      return FarmsScrollSepoliaPriceHelper
    case ChainId.XRPL_DEVNET:
      return FarmsXrplDevnetPriceHelper
    case ChainId.ZETACHAIN:
      return FarmsZetachainPriceHelper
    case ChainId.ZETACHAIN_ATHENS:
      return FarmsZetachainAthensPriceHelper
    case ChainId.ZYX:
      return FarmsZyxPriceHelper
    default:
      return []
  }
}
