import { useTranslation } from '@donaswap/localization'
import { ChainId, NATIVE } from '@donaswap/sdk'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  InfoIcon,
  Input,
  Text,
  UserMenu,
  UserMenuDivider,
  UserMenuItem,
  useTooltip,
} from '@donaswap/uikit'
import { useNetwork } from 'wagmi'
import { useActiveChainId, useLocalNetworkChain } from 'hooks/useActiveChainId'
import { useNetworkConnectorUpdater } from 'hooks/useActiveWeb3React'
import { useHover } from 'hooks/useHover'
import { useSessionChainId } from 'hooks/useSessionChainId'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import { useCallback, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { chains } from 'utils/wagmi'
import Image from 'next/image'
// import { ASSET_CDN } from 'config/constants/endpoints'

import { ChainLogo } from './Logo/ChainLogo'
// import { skaleTitanTestnet } from 'viem/chains'

// const AptosChain = {
//   id: 1,
//   name: 'Aptos',
// }

// const SolanaChain = {
//   id: 2,
//   name: 'Solana',
// }

// const Firechain = {
//   id: 1,
//   name: 'Firechain',
// }

// const Rinia = {
//   id: 3,
//   name: 'Rinia',
// }

// const FirechainZkevm = {
//   id: 2,
//   name: 'Firechain Zkevm',
// }

// const FirechainZkevmGhostrider = {
//   id: 3,
//   name: 'Firechain Zkevm Ghostrider',
// }

const NetworkSelect = ({ switchNetwork, chainId }) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState("")
  const [showTestnets, setShowTestnets] = useState(false)

  const toggleTestnetCheckbox = useCallback((e) => {
    e.stopPropagation()
    setShowTestnets(prev => !prev)
  }, [])

  const filteredChains = useMemo(() => chains
    .filter((chain) => !filter || chain.name.toLowerCase().includes(filter.toLowerCase()))
    .filter((chain) => showTestnets || !(chain as any).testnet)
    , [filter, showTestnets])

  const NEW_CHAINS: number[] = [
    ChainId.RINIA,
    ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER,
    // ChainId.METER_TESTNET,
    // ChainId.XINFIN_APOTHEM,
    // ChainId.ZETACHAIN_ATHENS,
    // ChainId.ENGRAM_TESTNET,
    ChainId.XRPL_DEVNET,
  ]

  return (
    <Flex flexDirection="column">
      <Box px="16px" py="8px">
        <Input
          // style={{ color: 'inherit', backgroundColor: 'inherit' }}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => setFilter(e.currentTarget.value)}
          placeholder={t('Select a Network')}
        />
      </Box>
      <Flex px="16px" alignItems="center" onClick={toggleTestnetCheckbox}>
        <Checkbox
          scale="sm"
          name="show_testnets"
          type="checkbox"
          checked={showTestnets}
        />
        <Text ml="8px" style={{ userSelect: 'none' }}>
          {t('Show Testnets')}
        </Text>
      </Flex>
      <UserMenuDivider />
      <Box overflowY="auto" overflowX="hidden" maxHeight="300px">
        {/* <UserMenuItem
          key={`firechain-${Firechain.id}`}
          style={{ justifyContent: 'flex-start' }}
        // as="a"
        // href=""
        >
          <Image
            src="/images/chains/529.png"
            width={24}
            height={24}
            unoptimized
            alt={`chain-firechain-${Firechain.id}`}
          />
          <Text color="text" pl="12px">
            {Firechain.name}
          </Text>
          <Text color='white'
            style={{
              color: 'white',
              background: `linear-gradient(to right, rgb(0, 0, 255), rgb(255, 0, 255))`, // Gradient background with RGB colors
              borderRadius: '9999px',
              padding: '6px',
              fontStyle: 'italic',
              fontWeight: 'bold',
              marginLeft: '2px',
              fontSize: '10px',
              display: 'inline-block',
            }}
          >
            Coming Soon
          </Text>
        </UserMenuItem>


        <UserMenuItem
          key={`firechainZkevm-${FirechainZkevm.id}`}
          style={{ justifyContent: 'flex-start' }}
        // as="a"
        // href=""
        >
          <Image
            src="/images/chains/529.png"
            width={24}
            height={24}
            unoptimized
            alt={`chain-firechainZkevm-${FirechainZkevm.id}`}
          />
          <Text color="text" pl="12px">
            {FirechainZkevm.name}
          </Text>
          <Text color='white'
            style={{
              color: 'white',
              background: `linear-gradient(to right, rgb(0, 0, 255), rgb(255, 0, 255))`, // Gradient background with RGB colors
              borderRadius: '9999px',
              padding: '6px',
              fontStyle: 'italic',
              fontWeight: 'bold',
              marginLeft: '2px',
              fontSize: '10px',
              display: 'inline-block',
            }}
          >
            Coming Soon
          </Text>
        </UserMenuItem>
        
        <UserMenuItem
          key={`firechainZkevmGhostrider-${FirechainZkevmGhostrider.id}`}
          style={{ justifyContent: 'flex-start' }}
        // as="a"
        // href=""
        >
          <Image
            src="/images/chains/529.png"
            width={24}
            height={24}
            unoptimized
            alt={`chain-firechainZkevmGhostrider-${FirechainZkevmGhostrider.id}`}
          />
          <Text color="text" pl="12px">
            {FirechainZkevmGhostrider.name}
          </Text>
          <Text color='white'
            style={{
              color: 'white',
              background: `linear-gradient(to right, rgb(0, 0, 255), rgb(255, 0, 255))`, // Gradient background with RGB colors
              borderRadius: '9999px',
              padding: '6px',
              fontStyle: 'italic',
              fontWeight: 'bold',
              marginLeft: '2px',
              fontSize: '10px',
              display: 'inline-block',
            }}
          >
            Coming Soon
          </Text>
        </UserMenuItem>    

        <UserMenuItem
          key={`rinia-${Rinia.id}`}
          style={{ justifyContent: 'flex-start' }}
        // as="a"
        // href=""
        >
          <Image
            src="/images/chains/917.png"
            width={24}
            height={24}
            unoptimized
            alt={`chain-rinia-${Rinia.id}`}
          />
          <Text color="text" pl="12px">
            {Rinia.name}
          </Text>
          <Text color='white'
            style={{
              color: 'white',
              background: `linear-gradient(to right, rgb(0, 0, 255), rgb(255, 0, 255))`, // Gradient background with RGB colors
              borderRadius: '9999px',
              padding: '6px',
              fontStyle: 'italic',
              fontWeight: 'bold',
              marginLeft: '2px',
              fontSize: '10px',
              display: 'inline-block',
            }}
          >
            Coming Soon
          </Text>
        </UserMenuItem>    */}

        {filteredChains
          .map((chain) => (
            <UserMenuItem
              key={chain.id}
              style={{ justifyContent: 'flex-start' }}
              onClick={() => chain.id !== chainId && switchNetwork(chain.id)}
            >
              <ChainLogo chainId={chain.id} />
              <Text color={chain.id === chainId ? 'secondary' : 'text'} bold={chain.id === chainId} pl="12px">
                {chain.name}
              </Text>
              {NEW_CHAINS.includes(chain.id) && (
                <Text color={chain.id === chainId ? 'secondary' : 'text'}
                  style={{
                    color: 'white',
                    background: `linear-gradient(to right, rgb(0, 0, 255), rgb(255, 0, 255))`, // Gradient background with RGB colors
                    borderRadius: '9999px',
                    padding: '6px',
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                    marginLeft: '2px',
                    fontSize: '10px',
                    display: 'inline-block',
                  }}>
                  Coming Soon
                </Text>
              )}
            </UserMenuItem>
          ))}

        {filteredChains?.length === 0 ? <Box px="16px" py="8px"><Text>{t('No chains found.')}</Text></Box> : null}
        {/* <UserMenuItem
          key={`aptos-${AptosChain.id}`}
          style={{ justifyContent: 'flex-start' }}
          as="a"
          target="_blank"
          href="https://interface-donaswap.vercel.app/swap"
        >
          <Image
            src="https://aptos.donaswap.io/images/apt.png"
            width={24}
            height={24}
            unoptimized
            alt={`chain-aptos-${AptosChain.id}`}
          />
          <Text color="text" pl="12px">
            {AptosChain.name}
          </Text>
        </UserMenuItem> */}
        {/* <UserMenuItem
          key={`solona-${SolanaChain.id}`}
          style={{ justifyContent: 'flex-start' }}
          as="a"
          target="_blank"
          href="https://solana-interface.vercel.app/swap"
        >
          <Image
            src="/images/chains/solana.png"
            width={24}
            height={24}
            unoptimized
            alt={`chain-solona-${SolanaChain.id}`}
          />
          <Text color="text" pl="12px">
            {SolanaChain.name}
          </Text>
        </UserMenuItem> */}
      </Box>
    </Flex>
  )
}

const WrongNetworkSelect = ({ switchNetwork, chainId }) => {
  const { t } = useTranslation()
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t(
      'The URL you are accessing (Chain id: %chainId%) belongs to %network%; mismatching your wallet’s network. Please switch the network to continue.',
      {
        chainId,
        network: chains.find((c) => c.id === chainId)?.name ?? 'Unknown network',
      },
    ),
    {
      placement: 'auto-start',
      hideTimeout: 0,
    },
  )
  const { chain } = useNetwork()
  const localChainId = useLocalNetworkChain() || ChainId.BSC
  const [, setSessionChainId] = useSessionChainId()

  const localChainName = chains.find((c) => c.id === localChainId)?.name ?? 'BSC'

  const [ref1, isHover] = useHover<HTMLButtonElement>()

  return (
    <>
      <Flex ref={targetRef} alignItems="center" px="16px" py="8px">
        <InfoIcon color="textSubtle" />
        <Text color="textSubtle" pl="6px">
          {t('Please switch network')}
        </Text>
      </Flex>
      {tooltipVisible && tooltip}
      <UserMenuDivider />
      {chain && (
        <UserMenuItem ref={ref1} onClick={() => setSessionChainId(chain.id)} style={{ justifyContent: 'flex-start' }}>
          <ChainLogo chainId={chain.id} />
          <Text color="secondary" bold pl="12px">
            {chain.name}
          </Text>
        </UserMenuItem>
      )}
      <Box px="16px" pt="8px">
        {isHover ? <ArrowUpIcon color="text" /> : <ArrowDownIcon color="text" />}
      </Box>
      <UserMenuItem onClick={() => switchNetwork(localChainId)} style={{ justifyContent: 'flex-start' }}>
        <ChainLogo chainId={localChainId} />
        <Text pl="12px">{localChainName}</Text>
      </UserMenuItem>
      <Button mx="16px" my="8px" scale="sm" onClick={() => switchNetwork(localChainId)}>
        {t('Switch network in wallet')}
      </Button>
    </>
  )
}

const SHORT_SYMBOL = {
  [ChainId.FIRECHAIN]: 'FIRE',
  [ChainId.RINIA]: 'tFIRE',
  [ChainId.FIRECHAIN_ZKEVM]: 'ETH',
  [ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER]: 'tETH',
  [ChainId.ETHEREUM]: 'ETH',
  [ChainId.GOERLI]: 'tETH',
  [ChainId.SEPOLIA]: 'tETH',
  [ChainId.HOLESKY]: 'tETH',
  [ChainId.BSC]: 'BNB',
  [ChainId.BSC_TESTNET]: 'tBNB',
  [ChainId.POLYGON]: 'MATIC',
  [ChainId.POLYGON_AMOY]: 'tMATIC',
  [ChainId.POLYGON_ZKEVM]: 'ETH',
  [ChainId.POLYGON_ZKEVM_TESTNET]: 'tETH',
  [ChainId.CRO]: 'CRO',
  [ChainId.CRO_TESTNET]: 'tCRO',
  [ChainId.AVALANCHE]: 'AVAX',
  [ChainId.AVALANCHE_FUJI]: 'tAVAX',
  [ChainId.FANTOM_OPERA]: 'FTM',
  [ChainId.FANTOM_TESTNET]: 'tFTM',
  [ChainId.CELO]: 'CELO',
  [ChainId.CELO_ALFAJORES]: 'tCELO',
  [ChainId.OPTIMISM]: 'ETH',
  [ChainId.OPTIMISM_GOERLI]: 'tETH',
  [ChainId.SHIBARIUM]: 'BONE',
  [ChainId.SHIBARIUM_TESTNET]: 'tBONE',
  [ChainId.PULSECHAIN]: 'PLS',
  [ChainId.PULSECHAIN_TESTNET]: 'tPLS',
  [ChainId.XEN]: 'XN',
  [ChainId.XEN_DEVNET]: 'tXN',
  [ChainId.HARMONY]: 'ONE',
  [ChainId.HARMONY_TESTNET]: 'tONE',
  [ChainId.COINEX]: 'CET',
  [ChainId.COINEX_TESTNET]: 'tCET',
  [ChainId.DOGECHAIN]: 'DOGE',
  [ChainId.DOGECHAIN_TESTNET]: 'tDOGE',
  [ChainId.ENGRAM_TESTNET]: 'tGRAM',
  [ChainId.ETHEREUM_CLASSIC]: 'ETC',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: 'tETC',
  [ChainId.FUSION]: 'FSN',
  [ChainId.FUSION_TESTNET]: 'tFSN',
  [ChainId.HECO]: 'HT',
  [ChainId.HECO_TESTNET]: 'tHT',
  [ChainId.KCC]: 'KCS',
  [ChainId.KCC_TESTNET]: 'tKCS',
  [ChainId.KLAYTN]: 'KLAY',
  [ChainId.KLAYTN_BAOBAB]: 'tKLAY',
  [ChainId.OKXCHAIN]: 'OKT',
  [ChainId.OKXCHAIN_TESTNET]: 'tOKT',
  [ChainId.THETHA]: 'TFUEL',
  [ChainId.THETHA_TESTNET]: 'tTFUEL',
  [ChainId.ULTRON]: 'ULX',
  [ChainId.ULTRON_TESTNET]: '',
  [ChainId.MOONBEAM]: 'DEV',
  [ChainId.MOONRIVER]: 'GLMR',
  [ChainId.MOONBASE_ALPHA]: 'MOVR',
  [ChainId.AURORA]: 'ETH',
  [ChainId.AURORA_TESTNET]: 'tETH',
  [ChainId.BOBA]: 'ETH',
  [ChainId.BOBA_GOERLI]: 'tETH',
  [ChainId.GNOSIS]: 'xDAI',
  [ChainId.GNOSIS_CHIADO]: 'txDAI',
  [ChainId.METIS]: 'METIS',
  [ChainId.METIS_GOERLI]: 'tMETIS',
  [ChainId.TELOS]: 'TLOS',
  [ChainId.TELOS_TESTNET]: 'tTLOS',
  [ChainId.SYSCOIN]: 'SYS',
  [ChainId.SYSCOIN_TANENBAUM]: 'tSYS',
  [ChainId.IOTEX]: 'IOTX',
  [ChainId.IOTEX_TESTNET]: 'tIOTX',
  [ChainId.SHIDEN]: 'SDN',
  [ChainId.ASTAR]: 'ASTR',
  [ChainId.SHIBUNYA]: 'SBY',
  [ChainId.FUSE]: 'FUSE',
  [ChainId.FUSE_SPARKNET]: 'tFUSE',
  [ChainId.VELAS]: 'VLX',
  [ChainId.VELAS_TESTNET]: 'tVLX',
  [ChainId.REI]: 'REI',
  [ChainId.REI_TESTNET]: 'tREI',
  [ChainId.KEKCHAIN]: 'KEK',
  [ChainId.KEKCHAIN_TESTNET]: 'tKEK',
  [ChainId.TOMOCHAIN]: 'TOMO',
  [ChainId.TOMOCHAIN_TESTNET]: 'tTOMO',
  [ChainId.THUNDERCORE]: 'TT',
  [ChainId.THUNDERCORE_TESTNET]: 'TST',
  [ChainId.WANCHAIN]: 'WAN',
  [ChainId.WANCHAIN_TESTNET]: 'tWAN',
  [ChainId.RSK]: 'RBTC',
  [ChainId.RSK_TESTNET]: 'tRBTC',
  [ChainId.ELASTOS]: 'ELA',
  [ChainId.ELASTOS_TESTNET]: 'tELA',
  [ChainId.CONFLUX]: 'CFX',
  [ChainId.CONFLUX_TESTNET]: 'tCFX',
  [ChainId.BRISECHAIN]: 'BRISE',
  [ChainId.BRISECHAIN_TESTNET]: 'tBRISE',
  [ChainId.MUUCHAIN]: 'MUU',
  [ChainId.MUUCHAIN_TESTNET]: 'tMUU',
  [ChainId.CANTO]: 'CANTO',
  [ChainId.CANTO_TESTNET]: 'tCANTO',
  [ChainId.OASIS_EMERALD]: 'ROSE',
  [ChainId.OASIS_TESTNET]: 'tROSE',
  [ChainId.VISION]: 'VS',
  [ChainId.VISION_TESTNET]: 'tVS',
  [ChainId.STEP]: 'FITFI',
  [ChainId.STEP_TESTNET]: 'tFITFI',
  [ChainId.METER]: 'MTR',
  [ChainId.METER_TESTNET]: 'tMTR',
  [ChainId.GODWOKEN]: 'pCKB',
  [ChainId.GODWOKEN_TESTNET]: 'tpCKB',
  [ChainId.CALLISTO]: 'CLO',
  [ChainId.CALLISTO_TESTNET]: 'tCLO',
  [ChainId.EVMOS]: 'EVMOS',
  [ChainId.EVMOS_TESTNET]: 'tEVMOS',
  [ChainId.ENERGY_WEB_CHAIN]: 'EWT',
  [ChainId.ENERGY_VOLTA]: 'VT',
  [ChainId.BASE]: 'ETH',
  [ChainId.BASE_GOERLI]: 'tETH',
  [ChainId.KAVA]: 'KAVA',
  [ChainId.KAVA_TESTNET]: 'tKAVA',
  [ChainId.CLOVER]: 'CLV',
  [ChainId.CLOVER_TESTNET]: 'tCLV',
  [ChainId.DEFICHAIN]: 'DFI',
  [ChainId.DEFICHAIN_TESTNET]: 'tDFI',
  [ChainId.BRONOS]: 'BRO',
  [ChainId.BRONOS_TESTNET]: 'tBRO',
  [ChainId.FILECOIN]: '',
  [ChainId.FILECOIN_CALIBRATION]: '',
  [ChainId.FLARE]: 'FLR',
  [ChainId.FLARE_TESTNET]: 'tFLR',
  [ChainId.TARAXA]: 'TARA',
  [ChainId.TARAXA_TESTNET]: 'tTARA',
  [ChainId.ARBITRUM_ONE]: 'ERH',
  [ChainId.ARBITRUM_GOERLI]: 'tETH',
  [ChainId.ZKSYNC]: 'zkSync',
  [ChainId.ZKSYNC_TESTNET]: 'tZkSync',
  [ChainId.LINEA]: 'ETH',
  [ChainId.LINEA_TESTNET]: 'tETH',
  [ChainId.BTTCHAIN]: 'BTT',
  [ChainId.BTTCHAIN_TESTNET]: 'tBTT',
  [ChainId.BOBA_AVAX]: 'BOBA',
  [ChainId.BOBA_BNB]: 'BOBA',
  [ChainId.CMP]: 'CMP',
  [ChainId.CMP_TESTNET]: 'tCMP',
  [ChainId.CORE]: 'CORE',
  [ChainId.CORE_TESTNET]: 'tCORE',
  [ChainId.CUBECHAIN]: 'CUBE',
  [ChainId.CUBECHAIN_TESTNET]: 'tCUBE',
  [ChainId.DARWINIA_CRAB]: 'CRAB',
  [ChainId.DARWINIA_PANGOLIN]: 'PRING',
  [ChainId.DIODE_PRETNET]: 'DIODE',
  [ChainId.DIODE_TESTNET]: 'tDIODE',
  [ChainId.GOCHAIN]: 'GO',
  [ChainId.GOCHAIN_TESTNET]: 'tGO',
  [ChainId.HAQQ]: 'ISLM',
  [ChainId.HAQQ_TESTEDGE2]: 'ISLMt',
  [ChainId.KARDIACHAIN]: 'KAI',
  [ChainId.KARDIACHAIN_TESTNET]: 'tKAI',
  [ChainId.METADIUM]: 'META',
  [ChainId.METADIUM_TESTNET]: 'KAL',
  [ChainId.OP_BNB]: 'BNB',
  [ChainId.OP_BNB_TESTNET]: 'tBNB',
  [ChainId.PALM]: 'PALM',
  [ChainId.PALM_TESTNET]: 'tPALM',
  [ChainId.POLIS]: 'POLIS',
  [ChainId.POLIS_TESTNET]: 'tPOLIS',
  [ChainId.SHIBACHAIN]: 'SHIB',
  [ChainId.SMART_BCH]: 'BCH',
  [ChainId.SMART_BCH_TESTNET]: 'tBCH',
  [ChainId.SONGBIRD_CANARY]: 'SGB',
  [ChainId.THAICHAIN]: 'TCH',
  [ChainId.UBIQ]: 'UBQ',
  [ChainId.UBIQ_TESTNET]: 'tUBQ',
  [ChainId.VECHAIN]: 'VET',
  [ChainId.VECHAIN_TESTNET]: 'TVET',
  [ChainId.XINFIN]: 'XDC',
  [ChainId.XINFIN_APOTHEM]: 'tXDC',
  [ChainId.SCROLL]: 'ETH',
  [ChainId.SCROLL_SEPOLIA]: 'tETH',
  [ChainId.XRPL_DEVNET]: 'tXRPL',
  [ChainId.ZETACHAIN]: 'ZETA',
  [ChainId.ZETACHAIN_ATHENS]: 'tZETA',
  [ChainId.ZYX]: 'ZYX',
} as const satisfies Record<ChainId, string>

export const NetworkSwitcher = () => {
  const { t } = useTranslation()
  const { chainId, isWrongNetwork, isNotMatched } = useActiveChainId()
  const { pendingChainId, isLoading, canSwitch, switchNetworkAsync } = useSwitchNetwork()
  const router = useRouter()

  useNetworkConnectorUpdater()

  const foundChain = useMemo(
    () => chains.find((c) => c.id === (isLoading ? pendingChainId || chainId : chainId)),
    [isLoading, pendingChainId, chainId],
  )
  const symbol = SHORT_SYMBOL[foundChain?.id] ?? NATIVE[foundChain?.id]?.symbol ?? foundChain?.nativeCurrency?.symbol
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('Unable to switch network. Please try it on your wallet'),
    { placement: 'bottom' },
  )

  const cannotChangeNetwork = !canSwitch

  if (!chainId || router.pathname.includes('/info')) {
    return null
  }

  return (
    <Box ref={cannotChangeNetwork ? targetRef : null} height="100%">
      {cannotChangeNetwork && tooltipVisible && tooltip}
      <UserMenu
        mr="8px"
        placement="bottom"
        variant={isLoading ? 'pending' : isWrongNetwork ? 'danger' : 'default'}
        avatarSrc={`/images/chains/${chainId}.png`}
        disabled={cannotChangeNetwork}
        text={
          isLoading ? (
            t('Requesting')
          ) : isWrongNetwork ? (
            t('Network')
          ) : foundChain ? (
            <>
              <Box display={['none', null, null, null, null, 'block']}>{foundChain.name}</Box>
              <Box display={['block', null, null, null, null, 'none']}>{symbol}</Box>
            </>
          ) : (
            t('Select a Network')
          )
        }
      >
        {() =>
          isNotMatched ? (
            <WrongNetworkSelect switchNetwork={switchNetworkAsync} chainId={chainId} />
          ) : (
            <NetworkSelect switchNetwork={switchNetworkAsync} chainId={chainId} />
          )
        }
      </UserMenu>
    </Box>
  )
}
