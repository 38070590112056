import BigNumber from 'bignumber.js'
import { BIG_ONE, BIG_ZERO } from '@donaswap/utils/bigNumber'
import { filterFarmsByQuoteToken, SerializedFarm } from '@donaswap/farms'
import { ChainId } from '@donaswap/sdk'

const getFarmFromTokenSymbol = (
  farms: SerializedFarm[],
  tokenSymbol: string,
  preferredQuoteTokens?: string[],
): SerializedFarm => {
  const farmsWithTokenSymbol = farms.filter((farm) => farm.token.symbol === tokenSymbol)
  const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
  return filteredFarm
}

const getFarmBaseTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  nativePriceUSD: BigNumber,
  wNative: string,
  stable: string,
): BigNumber => {
  const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

  if (farm.quoteToken.symbol === stable) {
    return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (farm.quoteToken.symbol === wNative) {
    return hasTokenPriceVsQuote ? nativePriceUSD.times(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  // We can only calculate profits without a quoteTokenFarm for BUSD/BNB farms
  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  // Possible alternative farm quoteTokens:
  // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
  // If the farm's quote token isn't BUSD or WBNB, we then use the quote token, of the original farm's quote token
  // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - BNB, (pBTC - BNB)
  // from the BNB - pBTC price, we can calculate the PNT - BUSD price
  if (quoteTokenFarm.quoteToken.symbol === wNative) {
    const quoteTokenInBusd = nativePriceUSD.times(quoteTokenFarm.tokenPriceVsQuote)
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === stable) {
    const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  // Catch in case token does not have immediate or once-removed BUSD/WBNB quoteToken
  return BIG_ZERO
}

const getFarmQuoteTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  nativePriceUSD: BigNumber,
  wNative: string,
  stable: string,
): BigNumber => {
  if (farm.quoteToken.symbol === stable) {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === wNative) {
    return nativePriceUSD
  }

  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === wNative) {
    return quoteTokenFarm.tokenPriceVsQuote ? nativePriceUSD.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === stable) {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  return BIG_ZERO
}

/**
 * @deprecated use packages/farms/src/farmPrice instead
 */
const getFarmsPrices = (farms: SerializedFarm[], chainId: number) => {
  if (!nativeStableLpMap[chainId]) {
    throw new Error(`chainId ${chainId} not supported`)
  }

  const nativeStableFarm = farms.find(
    (farm) => farm.lpAddress.toLowerCase() === nativeStableLpMap[chainId].address.toLowerCase(),
  )
  const nativePriceUSD = nativeStableFarm.tokenPriceVsQuote ? BIG_ONE.div(nativeStableFarm.tokenPriceVsQuote) : BIG_ZERO
  const farmsWithPrices = farms.map((farm) => {
    const { wNative, stable } = nativeStableLpMap[chainId]
    const quoteTokenFarm =
      farm.quoteToken.symbol !== stable && farm.quoteToken.symbol !== wNative
        ? getFarmFromTokenSymbol(farms, farm.quoteToken.symbol, [wNative, stable])
        : null
    const tokenPriceBusd = getFarmBaseTokenPrice(farm, quoteTokenFarm, nativePriceUSD, wNative, stable)
    const quoteTokenPriceBusd = getFarmQuoteTokenPrice(farm, quoteTokenFarm, nativePriceUSD, wNative, stable)

    return {
      ...farm,
      tokenPriceBusd: tokenPriceBusd.toJSON(),
      quoteTokenPriceBusd: quoteTokenPriceBusd.toJSON(),
    }
  })

  return farmsWithPrices
}

export default getFarmsPrices

const nativeStableLpMap = {
  [ChainId.FIRECHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFIRE',
    stable: 'USDT',
  },
  [ChainId.RINIA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFIRE',
    stable: 'USDT',
  },
  [ChainId.FIRECHAIN_ZKEVM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.BSC]: {
    address: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    wNative: 'WBNB',
    stable: 'BUSD',
  },
  [ChainId.BSC_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBNB',
    stable: 'BUSD',
  },
  [ChainId.ETHEREUM]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDC',
  },
  [ChainId.GOERLI]: {
    address: '0xf5bf0C34d3c428A74Ceb98d27d38d0036C587200',
    wNative: 'WETH',
    stable: 'tUSDC',
  },
  [ChainId.SEPOLIA]: {
    address: '0xf5bf0C34d3c428A74Ceb98d27d38d0036C587200',
    wNative: 'WETH',
    stable: 'tUSDC',
  },
  [ChainId.HOLESKY]: {
    address: '0xf5bf0C34d3c428A74Ceb98d27d38d0036C587200',
    wNative: 'WETH',
    stable: 'tUSDC',
  },
  [ChainId.POLYGON]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMATIC',
    stable: 'USDT',
  },
  [ChainId.POLYGON_AMOY]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMATIC',
    stable: 'USDT',
  },
  [ChainId.POLYGON_ZKEVM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMATIC',
    stable: 'USDT',
  },
  [ChainId.POLYGON_ZKEVM_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMATIC',
    stable: 'USDT',
  },
  [ChainId.CRO]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCRO',
    stable: 'USDT',
  },
  [ChainId.CRO_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCRO',
    stable: 'USDT',
  },
  [ChainId.AVALANCHE]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WAVAX',
    stable: 'USDT',
  },
  [ChainId.AVALANCHE_FUJI]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WAVAX',
    stable: 'USDT',
  },
  [ChainId.FANTOM_OPERA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFTM',
    stable: 'USDT',
  },
  [ChainId.FANTOM_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFTM',
    stable: 'USDT',
  },
  [ChainId.CELO]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCELO',
    stable: 'USDT',
  },
  [ChainId.CELO_ALFAJORES]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCELO',
    stable: 'USDT',
  },
  [ChainId.OPTIMISM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.OPTIMISM_GOERLI]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.ARBITRUM_ONE]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.ARBITRUM_GOERLI]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.SHIBARIUM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBONE',
    stable: 'USDT',
  },
  [ChainId.SHIBARIUM_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBONE',
    stable: 'USDT',
  },
  [ChainId.PULSECHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPULSE',
    stable: 'USDT',
  },
  [ChainId.PULSECHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPULSE',
    stable: 'USDT',
  },
  [ChainId.XEN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXN',
    stable: 'USDT',
  },
  [ChainId.XEN_DEVNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXN',
    stable: 'USDT',
  },
  [ChainId.HARMONY]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WONE',
    stable: 'USDT',
  },
  [ChainId.HARMONY_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WONE',
    stable: 'USDT',
  },
  [ChainId.COINEX]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCET',
    stable: 'USDT',
  },
  [ChainId.COINEX_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCET',
    stable: 'USDT',
  },
  [ChainId.DOGECHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WDOGE',
    stable: 'USDT',
  },
  [ChainId.DOGECHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WDOGE',
    stable: 'USDT',
  },
  [ChainId.ENGRAM_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WDOGE',
    stable: 'USDT',
  },
  [ChainId.ETHEREUM_CLASSIC]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETC',
    stable: 'USDT',
  },
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETC',
    stable: 'USDT',
  },
  [ChainId.FUSION]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFSN',
    stable: 'USDT',
  },
  [ChainId.FUSION_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFSN',
    stable: 'USDT',
  },
  [ChainId.HECO]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WHT',
    stable: 'USDT',
  },
  [ChainId.HECO_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WHT',
    stable: 'USDT',
  },
  [ChainId.KCC]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKCS',
    stable: 'USDT',
  },
  [ChainId.KCC_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKCS',
    stable: 'USDT',
  },
  [ChainId.KLAYTN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKLAY',
    stable: 'USDT',
  },
  [ChainId.KLAYTN_BAOBAB]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKLAY',
    stable: 'USDT',
  },
  [ChainId.OKXCHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WOKT',
    stable: 'USDT',
  },
  [ChainId.OKXCHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WOKT',
    stable: 'USDT',
  },
  [ChainId.THETHA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTFUEL',
    stable: 'USDT',
  },
  [ChainId.THETHA_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTFUEL',
    stable: 'USDT',
  },
  [ChainId.ULTRON]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WULX',
    stable: 'USDT',
  },
  [ChainId.ULTRON_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WULX',
    stable: 'USDT',
  },
  [ChainId.MOONBEAM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WGLMR',
    stable: 'USDT',
  },
  [ChainId.MOONRIVER]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMOVR',
    stable: 'USDT',
  },
  [ChainId.MOONBASE_ALPHA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WDEV',
    stable: 'USDT',
  },
  [ChainId.AURORA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.AURORA_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.BOBA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.BOBA_GOERLI]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.GNOSIS]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXDAI',
    stable: 'USDT',
  },
  [ChainId.GNOSIS_CHIADO]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXDAI',
    stable: 'USDT',
  },
  [ChainId.METIS]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMETIS',
    stable: 'USDT',
  },
  [ChainId.METIS_GOERLI]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMETIS',
    stable: 'USDT',
  },
  [ChainId.TELOS]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTLOS',
    stable: 'USDT',
  },
  [ChainId.TELOS_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBNB',
    stable: 'USDT',
  },
  [ChainId.SYSCOIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTLOS',
    stable: 'USDT',
  },
  [ChainId.SYSCOIN_TANENBAUM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WSYS',
    stable: 'USDT',
  },
  [ChainId.IOTEX]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WIOTX',
    stable: 'USDT',
  },
  [ChainId.IOTEX_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WIOTX',
    stable: 'USDT',
  },
  [ChainId.SHIDEN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WSDN',
    stable: 'USDT',
  },
  [ChainId.ASTAR]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WASTR',
    stable: 'USDT',
  },
  [ChainId.SHIBUNYA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WSBY',
    stable: 'USDT',
  },
  [ChainId.FUSE]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFUSE',
    stable: 'USDT',
  },
  [ChainId.FUSE_SPARKNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFUSE',
    stable: 'USDT',
  },
  [ChainId.VELAS]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WVLX',
    stable: 'USDT',
  },
  [ChainId.VELAS_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WVLX',
    stable: 'USDT',
  },
  [ChainId.REI]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WREI',
    stable: 'USDT',
  },
  [ChainId.REI_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WREI',
    stable: 'USDT',
  },
  [ChainId.KEKCHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKEK',
    stable: 'USDT',
  },
  [ChainId.KEKCHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKEK',
    stable: 'USDT',
  },
  [ChainId.TOMOCHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTOMO',
    stable: 'USDT',
  },
  [ChainId.TOMOCHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTOMO',
    stable: 'USDT',
  },
  [ChainId.THUNDERCORE]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTT',
    stable: 'USDT',
  },
  [ChainId.THUNDERCORE_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTT',
    stable: 'USDT',
  },
  [ChainId.WANCHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WWAN',
    stable: 'USDT',
  },
  [ChainId.WANCHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WWAN',
    stable: 'USDT',
  },
  [ChainId.RSK]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WRBTC',
    stable: 'USDT',
  },
  [ChainId.RSK_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WRBTC',
    stable: 'USDT',
  },
  [ChainId.ELASTOS]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WELA',
    stable: 'USDT',
  },
  [ChainId.ELASTOS_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WELA',
    stable: 'USDT',
  },
  [ChainId.CONFLUX]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCFX',
    stable: 'USDT',
  },
  [ChainId.CONFLUX_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCFX',
    stable: 'USDT',
  },
  [ChainId.BRISECHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBRISE',
    stable: 'USDT',
  },
  [ChainId.BRISECHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBRISE',
    stable: 'USDT',
  },
  [ChainId.MUUCHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMUU',
    stable: 'USDT',
  },
  [ChainId.MUUCHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMUU',
    stable: 'USDT',
  },
  [ChainId.CANTO]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCANTO',
    stable: 'USDT',
  },
  [ChainId.CANTO_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCANTO',
    stable: 'USDT',
  },
  [ChainId.OASIS_EMERALD]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WROSE',
    stable: 'USDT',
  },
  [ChainId.OASIS_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WROSE',
    stable: 'USDT',
  },
  [ChainId.VISION]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WVS',
    stable: 'USDT',
  },
  [ChainId.VISION_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WVS',
    stable: 'USDT',
  },
  [ChainId.STEP]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFITFI',
    stable: 'USDT',
  },
  [ChainId.STEP_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFITFI',
    stable: 'USDT',
  },
  [ChainId.METER]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMTR',
    stable: 'USDT',
  },
  [ChainId.METER_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMTR',
    stable: 'USDT',
  },
  [ChainId.GODWOKEN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPCKB',
    stable: 'USDT',
  },
  [ChainId.GODWOKEN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPCKB',
    stable: 'USDT',
  },
  [ChainId.CALLISTO]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCLO',
    stable: 'USDT',
  },
  [ChainId.CALLISTO_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCLO',
    stable: 'USDT',
  },
  [ChainId.EVMOS]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WEVMOS',
    stable: 'USDT',
  },
  [ChainId.EVMOS_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WEVMOS',
    stable: 'USDT',
  },
  [ChainId.ENERGY_WEB_CHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WEWT',
    stable: 'USDT',
  },
  [ChainId.ENERGY_VOLTA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WEWT',
    stable: 'USDT',
  },
  [ChainId.BASE]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.BASE_GOERLI]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },

  [ChainId.KAVA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKAVA',
    stable: 'USDT',
  },
  [ChainId.KAVA_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKAVA',
    stable: 'USDT',
  },
  [ChainId.CLOVER]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCLV',
    stable: 'USDT',
  },
  [ChainId.CLOVER_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCLV',
    stable: 'USDT',
  },
  [ChainId.DEFICHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WDFI',
    stable: 'USDT',
  },
  [ChainId.DEFICHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WDFI',
    stable: 'USDT',
  },
  [ChainId.BRONOS]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBRO',
    stable: 'USDT',
  },
  [ChainId.BRONOS_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBRO',
    stable: 'USDT',
  },
  [ChainId.FILECOIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFIL',
    stable: 'USDT',
  },
  [ChainId.FILECOIN_CALIBRATION]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFIL',
    stable: 'USDT',
  },
  [ChainId.FLARE]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFLR',
    stable: 'USDT',
  },
  [ChainId.FLARE_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WFLR',
    stable: 'USDT',
  },
  [ChainId.TARAXA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTARA',
    stable: 'USDT',
  },
  [ChainId.TARAXA_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTARA',
    stable: 'USDT',
  },
  [ChainId.ZKSYNC]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.ZKSYNC_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },

  [ChainId.LINEA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.LINEA_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.BTTCHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBTT',
    stable: 'USDT',
  },
  [ChainId.BTTCHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBTT',
    stable: 'USDT',
  },
  [ChainId.BOBA_AVAX]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBOBA',
    stable: 'USDT',
  },
  [ChainId.BOBA_BNB]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBOBA',
    stable: 'USDT',
  },
  [ChainId.CMP]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCMP',
    stable: 'USDT',
  },
  [ChainId.CMP_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCMP',
    stable: 'USDT',
  },
  [ChainId.CORE]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCORE',
    stable: 'USDT',
  },
  [ChainId.CORE_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCORE',
    stable: 'USDT',
  },
  [ChainId.CUBECHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCUBE',
    stable: 'USDT',
  },
  [ChainId.CUBECHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCUBE',
    stable: 'USDT',
  },
  [ChainId.DARWINIA_CRAB]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCRAB',
    stable: 'USDT',
  },
  [ChainId.DARWINIA_PANGOLIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPRING',
    stable: 'USDT',
  },
  [ChainId.DIODE_PRETNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WDIODE',
    stable: 'USDT',
  },
  [ChainId.DIODE_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WDIODE',
    stable: 'USDT',
  },
  [ChainId.GOCHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WGO',
    stable: 'USDT',
  },
  [ChainId.GOCHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WGO',
    stable: 'USDT',
  },
  [ChainId.HAQQ]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WISLM',
    stable: 'USDT',
  },
  [ChainId.HAQQ_TESTEDGE2]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WISLM',
    stable: 'USDT',
  },
  [ChainId.KARDIACHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKAI',
    stable: 'USDT',
  },
  [ChainId.KARDIACHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKAI',
    stable: 'USDT',
  },
  [ChainId.METADIUM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMETA',
    stable: 'USDT',
  },
  [ChainId.METADIUM_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKAL',
    stable: 'USDT',
  },
  [ChainId.OP_BNB]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBNB',
    stable: 'USDT',
  },
  [ChainId.OP_BNB_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBNB',
    stable: 'USDT',
  },
  [ChainId.PALM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPALM',
    stable: 'USDT',
  },
  [ChainId.PALM_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPALM',
    stable: 'USDT',
  },
  [ChainId.POLIS]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPOLIS',
    stable: 'USDT',
  },
  [ChainId.POLIS_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPOLIS',
    stable: 'USDT',
  },
  [ChainId.SHIBACHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WSHIB',
    stable: 'USDT',
  },
  [ChainId.SMART_BCH]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBCH',
    stable: 'USDT',
  },
  [ChainId.SMART_BCH_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBCH',
    stable: 'USDT',
  },
  [ChainId.SONGBIRD_CANARY]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WSNB',
    stable: 'USDT',
  },
  [ChainId.THAICHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTCH',
    stable: 'USDT',
  },
  [ChainId.UBIQ]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WUBQ',
    stable: 'USDT',
  },
  [ChainId.UBIQ_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WUBQ',
    stable: 'USDT',
  },
  [ChainId.VECHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WVET',
    stable: 'USDT',
  },
  [ChainId.VECHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WVET',
    stable: 'USDT',
  },
  [ChainId.XINFIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXDC',
    stable: 'USDT',
  },
  [ChainId.XINFIN_APOTHEM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXDC',
    stable: 'USDT',
  },
  [ChainId.SCROLL]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXDC',
    stable: 'USDT',
  },
  [ChainId.SCROLL_SEPOLIA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXDC',
    stable: 'USDT',
  },
  [ChainId.XRPL_DEVNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXDC',
    stable: 'USDT',
  },
  [ChainId.ZETACHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXDC',
    stable: 'USDT',
  },
  [ChainId.ZETACHAIN_ATHENS]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXDC',
    stable: 'USDT',
  },
  [ChainId.ZYX]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WZYX',
    stable: 'USDT',
  },
}
