import type { FarmConfigBaseProps, SerializedFarmConfig } from '@donaswap/farms'
import { ChainId, Currency, CurrencyAmount, Percent, Price, Token, Trade, TradeType } from '@donaswap/sdk'
import { LegacyTradeWithStableSwap as TradeWithStableSwap } from '@donaswap/smart-router/legacy-router'
import BigNumber from 'bignumber.js'
import { Address } from 'wagmi'
// a list of tokens by chain
export type ChainMap<T> = {
  readonly [chainId in ChainId]: T
}

export type ChainTokenList = ChainMap<Token[]>

export type TranslatableText =
  | string
  | {
      key: string
      data?: {
        [key: string]: string | number
      }
    }
export interface Addresses {
  529?: Address
  917?: Address
  814?: Address
  3885?: Address
  97?: Address
  56: Address
  11155111?: Address
  17000?: Address
  137?: Address
  80002?: Address
  1101?: Address
  1442?: Address
  25?: Address
  338?: Address
  43114?: Address
  43113?: Address
  250?: Address
  4002?: Address
  42220?: Address
  44787?: Address
  10?: Address
  420?: Address
  42161?: Address
  421613?: Address
  109?: Address
  719?: Address
  369?: Address
  943?: Address
  20221200000?: Address
  202212?: Address
  1666600000?: Address
  1666700000?: Address
  52?: Address
  53?: Address
  2000?: Address
  568?: Address
  131?: Address
  61?: Address
  63?: Address
  32659?: Address
  46688?: Address
  128?: Address
  256?: Address
  321?: Address
  322?: Address
  8217?: Address
  1001?: Address
  66?: Address
  65?: Address
  361?: Address
  365?: Address
  1231?: Address
  1230?: Address
  1284?: Address
  1285?: Address
  1287?: Address
  1313161554?: Address
  1313161555?: Address
  288?: Address
  2888?: Address
  100?: Address
  10200?: Address
  1088?: Address
  599?: Address
  40?: Address
  41?: Address
  57?: Address
  5700?: Address
  4689?: Address
  4690?: Address
  336?: Address
  592?: Address
  81?: Address
  122?: Address
  123?: Address
  106?: Address
  111?: Address
  55555?: Address
  55556?: Address
  420420?: Address
  420666?: Address
  88?: Address
  89?: Address
  108?: Address
  18?: Address
  888?: Address
  999?: Address
  30?: Address
  31?: Address
  20?: Address
  21?: Address
  1030?: Address
  71?: Address
  32520?: Address
  64668?: Address
  20402?: Address
  42110?: Address
  7700?: Address
  7701?: Address
  42262?: Address
  42261?: Address
  888888?: Address
  666666?: Address
  1234?: Address
  12345?: Address
  82?: Address
  83?: Address
  71402?: Address
  71401?: Address
  820?: Address
  20729?: Address
  9001?: Address
  9000?: Address
  246?: Address
  73799?: Address
  8453?: Address
  84531?: Address
  2222?: Address
  2221?: Address
  1024?: Address
  1023?: Address
  1131?: Address
  1132?: Address
  1039?: Address
  1038?: Address
  314?: Address
  314159?: Address
  14?: Address
  114?: Address
  841?: Address
  842?: Address
  324?: Address
  280?: Address
  59140?: Address
  59144?: Address
  204?: Address
  5611?: Address
  43288?: Address
  56288?: Address
  199?: Address
  1029?: Address
  256256?: Address
  512512?: Address
  1116?: Address
  1115?: Address
  1818?: Address
  1819?: Address
  44?: Address
  43?: Address
  15?: Address
  13?: Address
  60?: Address
  31337?: Address
  11235?: Address
  54211?: Address
  24?: Address
  242?: Address
  11?: Address
  12?: Address
  11297108109?: Address
  11297108099?: Address
  333999?: Address
  333888?: Address
  27?: Address
  10000?: Address
  10001?: Address
  19?: Address
  7?: Address
  8?: Address
  9?: Address
  100009?: Address
  100010?: Address
  50?: Address
  51?: Address
  534352?: Address
  534351?: Address
  7000?: Address
  7001?: Address
  55?: Address
  [chainId: number]: Address
}

export enum PoolIds {
  poolBasic = 'poolBasic',
  poolUnlimited = 'poolUnlimited',
}

export type IfoStatus = 'idle' | 'coming_soon' | 'live' | 'finished'

interface IfoPoolInfo {
  saleAmount?: string
  raiseAmount: string
  flameToBurn?: string
  distributionRatio?: number // Range [0-1]
}

export interface Ifo {
  id: string
  isActive: boolean
  address: Address
  name: string
  currency: Token
  token: Token
  articleUrl: string
  campaignId: string
  tokenOfferingPrice: number
  description?: string
  twitterUrl?: string
  telegramUrl?: string
  version: number
  vestingTitle?: string
  cIFO?: boolean
  plannedStartTime?: number
  [PoolIds.poolBasic]?: IfoPoolInfo
  [PoolIds.poolUnlimited]: IfoPoolInfo
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
  'AUTO' = 'Auto',
}

export type { SerializedFarmConfig, FarmConfigBaseProps }

export type Images = {
  lg: string
  md: string
  sm: string
  ipfs?: string
}

export type TeamImages = {
  alt: string
} & Images

export type Team = {
  id: number
  name: string
  description: string
  isJoinable?: boolean
  users: number
  points: number
  images: TeamImages
  background: string
  textColor: string
}

export type CampaignType = 'ifo' | 'teambattle' | 'participation'

export type Campaign = {
  id: string
  type: CampaignType
  title?: TranslatableText
  description?: TranslatableText
  badge?: string
}

export type PageMeta = {
  title: string
  description?: string
  image?: string
}

export enum LotteryStatus {
  PENDING = 'pending',
  OPEN = 'open',
  CLOSE = 'close',
  CLAIMABLE = 'claimable',
}

export interface LotteryTicket {
  id: string
  number: string
  status: boolean
  rewardBracket?: number
  roundId?: string
  flameReward?: string
}

export interface LotteryTicketClaimData {
  ticketsWithUnclaimedRewards: LotteryTicket[]
  allWinningTickets: LotteryTicket[]
  flameTotal: BigNumber
  roundId: string
}

// Farm Auction
export interface FarmAuctionBidderConfig {
  account: string
  farmName: string
  tokenAddress: string
  quoteToken: Token
  tokenName: string
  projectSite?: string
  lpAddress?: string
}

// Note: this status is slightly different compared to 'status' config
// from Farm Auction smart contract
export enum AuctionStatus {
  ToBeAnnounced, // No specific dates/blocks to display
  Pending, // Auction is scheduled but not live yet (i.e. waiting for startBlock)
  Open, // Auction is open for bids
  Finished, // Auction end block is reached, bidding is not possible
  Closed, // Auction was closed in smart contract
}

export interface Auction {
  id: number
  status: AuctionStatus
  startBlock: number
  startDate: Date
  endBlock: number
  endDate: Date
  auctionDuration: number
  initialBidAmount: number
  topLeaderboard: number
  leaderboardThreshold: BigNumber
}

export interface BidderAuction {
  id: number
  amount: BigNumber
  claimed: boolean
}

export interface Bidder extends FarmAuctionBidderConfig {
  position?: number
  isTopPosition: boolean
  samePositionAsAbove: boolean
  amount: BigNumber
}

export interface ConnectedBidder {
  account: string
  isWhitelisted: boolean
  bidderData?: Bidder
}

export const FetchStatus = {
  Idle: 'idle',
  Fetching: 'loading',
  Fetched: 'success',
  Failed: 'error',
} as const

export type TFetchStatus = (typeof FetchStatus)[keyof typeof FetchStatus]

export const isStableSwap = (trade: ITrade): trade is StableTrade => {
  return (
    Boolean((trade as StableTrade)?.maximumAmountIn) &&
    !(trade as Trade<Currency, Currency, TradeType> | TradeWithStableSwap<Currency, Currency, TradeType>)?.route
  )
}

export type ITrade =
  | Trade<Currency, Currency, TradeType>
  | StableTrade
  | TradeWithStableSwap<Currency, Currency, TradeType>
  | undefined

export type V2TradeAndStableSwap = Trade<Currency, Currency, TradeType> | StableTrade | undefined

export interface StableTrade {
  tradeType: TradeType
  inputAmount: CurrencyAmount<Currency>
  outputAmount: CurrencyAmount<Currency>
  executionPrice: Price<Currency, Currency>
  priceImpact: null
  maximumAmountIn: (slippaged: Percent) => CurrencyAmount<Currency>
  minimumAmountOut: (slippaged: Percent) => CurrencyAmount<Currency>
}

export enum Bound {
  LOWER = 'LOWER',
  UPPER = 'UPPER',
}
