import {
  FarmV3DataWithPrice,
  FarmV3DataWithPriceAndUserInfo,
  FarmV3DataWithPriceTVL,
  FarmsV3Response,
  IPendingFlameByTokenId,
  SerializedFarmsV3Response,
  createFarmFetcherV3,
  supportedChainIdV3,
} from '@donaswap/farms'
import { priceHelperTokens } from '@donaswap/farms/constants/common'
import { farmsV3ConfigChainMap } from '@donaswap/farms/constants/v3'
import { TvlMap, fetchCommonTokenUSDValue } from '@donaswap/farms/src/fetchFarmsV3'
import { ChainId } from '@donaswap/sdk'
import { deserializeToken } from '@donaswap/token-lists'
import { useFlamePriceAsBN } from '@donaswap/utils/useFlamePrice'
import { bFlameFarmBoosterV3ABI } from 'config/abi/bFlameFarmBoosterV3'
import { FAST_INTERVAL } from 'config/constants'
import { FARMS_API } from 'config/constants/endpoints'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useBFlameFarmBoosterV3Contract, useMasterchefV3, useV3NFTPositionManagerContract } from 'hooks/useContract'
import { useV3PositionsFromTokenIds, useV3TokenIdsByAccount } from 'hooks/v3/useV3Positions'
import toLower from 'lodash/toLower'
import { useMemo } from 'react'
import useSWR from 'swr'
import fetchWithTimeout from 'utils/fetchWithTimeout'
import { getViemClients } from 'utils/viem'
import { publicClient } from 'utils/wagmi'
import { Hex, decodeFunctionResult, encodeFunctionData } from 'viem'
import { useAccount } from 'wagmi'

export const farmV3ApiFetch = (chainId: number): Promise<FarmsV3Response> =>
  fetch(`/api/v3/${chainId}/farms`)
    .then((res) => res.json())
    .then((data: SerializedFarmsV3Response) => {
      const farmsWithPrice = data.farmsWithPrice.map((f) => ({
        ...f,
        token: deserializeToken(f.token),
        quoteToken: deserializeToken(f.quoteToken),
      }))

      return {
        ...data,
        farmsWithPrice,
      }
    })

const fallback: Awaited<ReturnType<typeof farmFetcherV3.fetchFarms>> = {
  farmsWithPrice: [],
  poolLength: 0,
  flamePerSecond: '0',
  totalAllocPoint: '0',
}

const API_FLAG = false

const farmFetcherV3 = createFarmFetcherV3(getViemClients)

export const useFarmsV3Public = () => {
  const { chainId } = useActiveChainId()

  return useSWR(
    farmFetcherV3.isChainSupported(chainId) && [chainId, 'farmV3ApiFetch'],
    async () => {
      if (API_FLAG) {
        return farmV3ApiFetch(chainId).catch((err) => {
          console.error(err)
          return fallback
        })
      }

      // direct copy from api routes, the client side fetch is preventing cache due to migration phase we want fresh data
      const farms = farmsV3ConfigChainMap[chainId as ChainId]

      const commonPrice = await fetchCommonTokenUSDValue(priceHelperTokens[chainId])

      try {
        const data = await farmFetcherV3.fetchFarms({
          chainId,
          farms,
          commonPrice,
        })

        return data
      } catch (error) {
        console.error(error)
        // return fallback for now since not all chains supported
        return fallback
      }
    },
    {
      refreshInterval: FAST_INTERVAL * 3,
      keepPreviousData: true,
      fallbackData: fallback,
    },
  )
}

interface UseFarmsOptions {
  // mock apr when tvl is 0
  mockApr?: boolean
}

export const useFarmsV3 = ({ mockApr = false }: UseFarmsOptions = {}) => {
  const { chainId } = useActiveChainId()

  const farmV3 = useFarmsV3Public()

  const flamePrice = useFlamePriceAsBN()

  const { data } = useSWR<FarmsV3Response<FarmV3DataWithPriceTVL>>(
    [chainId, 'flame-apr-tvl', farmV3.data],
    async () => {
      const tvls: TvlMap = {}
      if (
        [
          ChainId.FIRECHAIN,
          ChainId.RINIA,
          ChainId.FIRECHAIN_ZKEVM,
          ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER,
          ChainId.ETHEREUM,
          ChainId.GOERLI,
          ChainId.SEPOLIA,
          ChainId.HOLESKY,
          ChainId.BSC,
          ChainId.BSC_TESTNET,
          ChainId.POLYGON,
          ChainId.POLYGON_AMOY,
          ChainId.POLYGON_ZKEVM,
          ChainId.POLYGON_ZKEVM_TESTNET,
          ChainId.CRO,
          ChainId.CRO_TESTNET,
          ChainId.AVALANCHE,
          ChainId.AVALANCHE_FUJI,
          ChainId.FANTOM_OPERA,
          ChainId.FANTOM_TESTNET,
          ChainId.CELO,
          ChainId.CELO_ALFAJORES,
          ChainId.OPTIMISM,
          ChainId.OPTIMISM_GOERLI,
          ChainId.ARBITRUM_ONE,
          ChainId.ARBITRUM_GOERLI,
          ChainId.SHIBARIUM,
          ChainId.SHIBARIUM_TESTNET,
          ChainId.PULSECHAIN,
          ChainId.PULSECHAIN_TESTNET,
          ChainId.XEN,
          ChainId.XEN_DEVNET,
          ChainId.HARMONY,
          ChainId.HARMONY_TESTNET,
          ChainId.COINEX,
          ChainId.COINEX_TESTNET,
          ChainId.DOGECHAIN,
          ChainId.DOGECHAIN_TESTNET,
          ChainId.ENGRAM_TESTNET,
          ChainId.ETHEREUM_CLASSIC,
          ChainId.ETHEREUM_CLASSIC_TESTNET,
          ChainId.FUSION,
          ChainId.FUSION_TESTNET,
          ChainId.HECO,
          ChainId.HECO_TESTNET,
          ChainId.KCC,
          ChainId.KCC_TESTNET,
          ChainId.KLAYTN,
          ChainId.KLAYTN_BAOBAB,
          ChainId.OKXCHAIN,
          ChainId.OKXCHAIN_TESTNET,
          ChainId.THETHA,
          ChainId.THETHA_TESTNET,
          ChainId.ULTRON,
          ChainId.ULTRON_TESTNET,
          ChainId.MOONBEAM,
          ChainId.MOONRIVER,
          ChainId.MOONBASE_ALPHA,
          ChainId.AURORA,
          ChainId.AURORA_TESTNET,
          ChainId.BOBA,
          ChainId.BOBA_GOERLI,
          ChainId.GNOSIS,
          ChainId.GNOSIS_CHIADO,
          ChainId.METIS,
          ChainId.METIS_GOERLI,
          ChainId.TELOS,
          ChainId.TELOS_TESTNET,
          ChainId.SYSCOIN,
          ChainId.SYSCOIN_TANENBAUM,
          ChainId.IOTEX,
          ChainId.IOTEX_TESTNET,
          ChainId.SHIDEN,
          ChainId.ASTAR,
          ChainId.SHIBUNYA,
          ChainId.FUSE,
          ChainId.FUSE_SPARKNET,
          ChainId.VELAS,
          ChainId.VELAS_TESTNET,
          ChainId.REI,
          ChainId.REI_TESTNET,
          ChainId.KEKCHAIN,
          ChainId.KEKCHAIN_TESTNET,
          ChainId.TOMOCHAIN,
          ChainId.TOMOCHAIN_TESTNET,
          ChainId.THUNDERCORE,
          ChainId.THUNDERCORE_TESTNET,
          ChainId.WANCHAIN,
          ChainId.WANCHAIN_TESTNET,
          ChainId.RSK,
          ChainId.RSK_TESTNET,
          ChainId.ELASTOS,
          ChainId.ELASTOS_TESTNET,
          ChainId.CONFLUX,
          ChainId.CONFLUX_TESTNET,
          ChainId.BRISECHAIN,
          ChainId.BRISECHAIN_TESTNET,
          ChainId.MUUCHAIN,
          ChainId.MUUCHAIN_TESTNET,
          ChainId.CANTO,
          ChainId.CANTO_TESTNET,
          ChainId.OASIS_EMERALD,
          ChainId.OASIS_TESTNET,
          ChainId.VISION,
          ChainId.VISION_TESTNET,
          ChainId.STEP,
          ChainId.STEP_TESTNET,
          ChainId.METER,
          ChainId.METER_TESTNET,
          ChainId.GODWOKEN,
          ChainId.GODWOKEN_TESTNET,
          ChainId.CALLISTO,
          ChainId.CALLISTO_TESTNET,
          ChainId.EVMOS,
          ChainId.EVMOS_TESTNET,
          ChainId.ENERGY_WEB_CHAIN,
          ChainId.ENERGY_VOLTA,
          ChainId.BASE,
          ChainId.BASE_GOERLI,
          ChainId.KAVA,
          ChainId.KAVA_TESTNET,
          ChainId.CLOVER,
          ChainId.CLOVER_TESTNET,
          ChainId.DEFICHAIN,
          ChainId.DEFICHAIN_TESTNET,
          ChainId.BRONOS,
          ChainId.BRONOS_TESTNET,
          ChainId.FILECOIN,
          ChainId.FILECOIN_CALIBRATION,
          ChainId.FLARE,
          ChainId.FLARE_TESTNET,
          ChainId.TARAXA,
          ChainId.TARAXA_TESTNET,
          ChainId.ZKSYNC,
          ChainId.ZKSYNC_TESTNET,
          ChainId.LINEA,
          ChainId.LINEA_TESTNET,
          ChainId.BTTCHAIN,
          ChainId.BTTCHAIN_TESTNET,
          ChainId.BOBA_AVAX,
          ChainId.BOBA_BNB,
          ChainId.CMP,
          ChainId.CMP_TESTNET,
          ChainId.CORE,
          ChainId.CORE_TESTNET,
          ChainId.CUBECHAIN,
          ChainId.CUBECHAIN_TESTNET,
          ChainId.DARWINIA_CRAB,
          ChainId.DARWINIA_PANGOLIN,
          ChainId.DIODE_PRETNET,
          ChainId.DIODE_TESTNET,
          ChainId.GOCHAIN,
          ChainId.GOCHAIN_TESTNET,
          ChainId.HAQQ,
          ChainId.HAQQ_TESTEDGE2,
          ChainId.KARDIACHAIN,
          ChainId.KARDIACHAIN_TESTNET,
          ChainId.METADIUM,
          ChainId.METADIUM_TESTNET,
          ChainId.OP_BNB,
          ChainId.OP_BNB_TESTNET,
          ChainId.PALM,
          ChainId.PALM_TESTNET,
          ChainId.POLIS,
          ChainId.POLIS_TESTNET,
          ChainId.SHIBACHAIN,
          ChainId.SMART_BCH,
          ChainId.SMART_BCH_TESTNET,
          ChainId.SONGBIRD_CANARY,
          ChainId.THAICHAIN,
          ChainId.UBIQ,
          ChainId.UBIQ_TESTNET,
          ChainId.VECHAIN,
          ChainId.VECHAIN_TESTNET,
          ChainId.XINFIN,
          ChainId.XINFIN_APOTHEM,
          ChainId.SCROLL,
          ChainId.SCROLL_SEPOLIA,
          ChainId.XRPL_DEVNET,
          ChainId.ZETACHAIN,
          ChainId.ZETACHAIN_ATHENS,
          ChainId.ZYX,
        ].includes(chainId)
      ) {
        const results = await Promise.allSettled(
          farmV3.data.farmsWithPrice.map((f) =>
            fetchWithTimeout(`${FARMS_API}/v3/${chainId}/liquidity/${f.lpAddress}`)
              .then((r) => r.json())
              .catch((err) => {
                console.error(err)
                throw err
              }),
          ),
        )
        results.forEach((r, i) => {
          tvls[farmV3.data.farmsWithPrice[i].lpAddress] =
            r.status === 'fulfilled' ? { ...r.value.formatted, updatedAt: r.value.updatedAt } : null
        })
      }

      const farmWithPriceAndFlameAPR = farmV3.data.farmsWithPrice.map((f) => {
        if (!tvls[f.lpAddress]) {
          return f
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const tvl = tvls[f.lpAddress]!
        // Mock 1$ tvl if the farm doesn't have lp staked
        if (mockApr && tvl?.token0 === '0' && tvl?.token1 === '0') {
          const [token0Price, token1Price] = f.token.sortsBefore(f.quoteToken)
            ? [f.tokenPriceBusd, f.quoteTokenPriceBusd]
            : [f.quoteTokenPriceBusd, f.tokenPriceBusd]
          tvl.token0 = token0Price ? String(1 / Number(token0Price)) : '1'
          tvl.token1 = token1Price ? String(1 / Number(token1Price)) : '1'
        }
        const { activeTvlUSD, activeTvlUSDUpdatedAt, flameApr } = farmFetcherV3.getFlameAprAndTVL(
          f,
          tvl,
          flamePrice.toString(),
          farmV3.data.flamePerSecond,
        )

        return {
          ...f,
          flameApr,
          activeTvlUSD,
          activeTvlUSDUpdatedAt,
        }
      })

      return {
        ...farmV3.data,
        farmsWithPrice: farmWithPriceAndFlameAPR,
      }
    },
    {
      refreshInterval: FAST_INTERVAL * 3,
      dedupingInterval: FAST_INTERVAL,
      keepPreviousData: true,
    },
  )

  return {
    data: (data ?? farmV3.data) as FarmsV3Response<FarmV3DataWithPriceTVL>,
    isLoading: farmV3.isLoading,
    error: farmV3.error,
  }
}

export const useStakedPositionsByUser = (stakedTokenIds: bigint[]) => {
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()
  const masterchefV3 = useMasterchefV3()

  const harvestCalls = useMemo(() => {
    if (!account || !supportedChainIdV3.includes(chainId)) return []
    const callData: Hex[] = []
    for (const stakedTokenId of stakedTokenIds) {
      callData.push(
        encodeFunctionData({
          abi: masterchefV3?.abi,
          functionName: 'harvest',
          args: [stakedTokenId, account],
        }),
      )
    }
    return callData
  }, [account, masterchefV3?.abi, stakedTokenIds, chainId])

  const { data } = useSWR(
    account && ['mcv3-harvest', harvestCalls],
    () => {
      return masterchefV3.simulate.multicall([harvestCalls], { account, value: 0n }).then((res) => {
        return res.result
          .map((r) =>
            decodeFunctionResult({
              abi: masterchefV3.abi,
              functionName: 'harvest',
              data: r,
            }),
          )
          .map((r) => {
            return r
          })
      })
    },
    {
      keepPreviousData: true,
    },
  )

  return { tokenIdResults: data || [], isLoading: harvestCalls.length > 0 && !data }
}

const usePositionsByUserFarms = (
  farmsV3: FarmV3DataWithPrice[],
): {
  farmsWithPositions: FarmV3DataWithPriceAndUserInfo[]
  userDataLoaded: boolean
} => {
  const { address: account } = useAccount()
  const positionManager = useV3NFTPositionManagerContract()
  const masterchefV3 = useMasterchefV3()

  const { tokenIds: stakedTokenIds } = useV3TokenIdsByAccount(masterchefV3?.address, account)

  const stakedIds = useMemo(() => stakedTokenIds || [], [stakedTokenIds])

  const { tokenIds } = useV3TokenIdsByAccount(positionManager?.address, account)

  const uniqueTokenIds = useMemo(() => [...stakedIds, ...tokenIds], [stakedIds, tokenIds])

  const { positions } = useV3PositionsFromTokenIds(uniqueTokenIds)

  const { tokenIdResults, isLoading: isStakedPositionLoading } = useStakedPositionsByUser(stakedIds)

  const [unstakedPositions, stakedPositions] = useMemo(() => {
    if (!positions) return [[], []]
    const unstakedIds = tokenIds.filter((id) => !stakedIds.find((s) => s === id))
    return [
      unstakedIds.map((id) => positions.find((p) => p.tokenId === id)).filter((p) => p?.liquidity > 0n),
      stakedIds.map((id) => positions.find((p) => p.tokenId === id)).filter((p) => p?.liquidity > 0n),
    ]
  }, [positions, stakedIds, tokenIds])

  const pendingFlameByTokenIds = useMemo(
    () =>
      (tokenIdResults as bigint[])?.reduce<IPendingFlameByTokenId>((acc, pendingFlame, i) => {
        const position = stakedPositions[i]

        return pendingFlame && position?.tokenId ? { ...acc, [position.tokenId.toString()]: pendingFlame } : acc
      }, {} as IPendingFlameByTokenId) ?? {},
    [stakedPositions, tokenIdResults],
  )

  // assume that if any of the tokenIds have a valid result, the data is ready
  const userDataLoaded = !isStakedPositionLoading

  const farmsWithPositions = useMemo(
    () =>
      farmsV3.map((farm) => {
        const { feeAmount, token0, token1 } = farm

        const unstaked = unstakedPositions.filter(
          (p) =>
            toLower(p.token0) === toLower(token0.address) &&
            toLower(p.token1) === toLower(token1.address) &&
            feeAmount === p.fee,
        )
        const staked = stakedPositions.filter((p) => {
          return (
            toLower(p.token0) === toLower(token0.address) &&
            toLower(p.token1) === toLower(token1.address) &&
            feeAmount === p.fee
          )
        })

        return {
          ...farm,
          unstakedPositions: unstaked,
          stakedPositions: staked,
          pendingFlameByTokenIds: Object.entries(pendingFlameByTokenIds).reduce<IPendingFlameByTokenId>(
            (acc, [tokenId, flame]) => {
              const foundPosition = staked.find((p) => p.tokenId === BigInt(tokenId))

              if (foundPosition) {
                return { ...acc, [tokenId]: flame }
              }

              return acc
            },
            {},
          ),
        }
      }),
    [farmsV3, pendingFlameByTokenIds, stakedPositions, unstakedPositions],
  )

  return {
    farmsWithPositions,
    userDataLoaded,
  }
}

export function useFarmsV3WithPositionsAndBooster(options: UseFarmsOptions = {}): {
  farmsWithPositions: FarmV3DataWithPriceAndUserInfo[]
  userDataLoaded: boolean
  flamePerSecond: string
  poolLength: number
  isLoading: boolean
} {
  const { data, error: _error, isLoading } = useFarmsV3(options)
  const { data: boosterWhitelist } = useV3BoostedFarm(data?.farmsWithPrice?.map((f) => f.pid))

  return {
    ...usePositionsByUserFarms(
      data.farmsWithPrice?.map((d, index) => ({ ...d, boosted: boosterWhitelist?.[index]?.boosted })),
    ),
    poolLength: data.poolLength,
    flamePerSecond: data.flamePerSecond,
    isLoading,
  }
}

const useV3BoostedFarm = (pids: number[]) => {
  const { chainId } = useActiveChainId()
  const farmBoosterV3Contract = useBFlameFarmBoosterV3Contract()

  const { data } = useSWR(
    chainId && pids.length > 0 && ['v3/boostedFarm', chainId, pids.join('-')],
    () => getV3FarmBoosterWhiteList({ farmBoosterContract: farmBoosterV3Contract, chainId, pids }),
    {
      errorRetryCount: 3,
      errorRetryInterval: 3000,
      keepPreviousData: true,
      refreshInterval: 0,
    },
  )

  return { data }
}

export async function getV3FarmBoosterWhiteList({
  farmBoosterContract,
  chainId,
  pids,
}): Promise<{ pid: number; boosted: boolean }[]> {
  const contracts = pids?.map((pid) => {
    return {
      address: farmBoosterContract.address,
      functionName: 'whiteList',
      abi: bFlameFarmBoosterV3ABI,
      args: [BigInt(pid)],
    }
  })
  const whiteList = await publicClient({ chainId }).multicall({
    contracts,
  })

  if (!whiteList || whiteList?.length !== pids?.length) return []
  return pids?.map((d, index) => ({ pid: d, boosted: whiteList[index].result }))
}
