import { ChainId } from '@donaswap/sdk'

const CHAIN_MAPPING = {
  [ChainId.FIRECHAIN]: 'firechain',
  [ChainId.RINIA]: 'rinia',
  [ChainId.FIRECHAIN_ZKEVM]: 'firechainZkevm',
  [ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER]: 'firechainZkevmGhostrider',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.SEPOLIA]: 'sepolia',
  [ChainId.HOLESKY]: 'holesky',
  [ChainId.BSC]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bscTestnet',
  [ChainId.POLYGON]: 'polygon',
  [ChainId.POLYGON_AMOY]: 'polygonAmoy',
  [ChainId.POLYGON_ZKEVM]: 'polygonZkevm',
  [ChainId.POLYGON_ZKEVM_TESTNET]: 'polygonZkevmTestnet',
  [ChainId.CRO]: 'cronos',
  [ChainId.CRO_TESTNET]: 'cronosTestnet',
  [ChainId.AVALANCHE]: 'avalanche',
  [ChainId.AVALANCHE_FUJI]: 'avalancheFuji',
  [ChainId.FANTOM_OPERA]: 'fantomOpera',
  [ChainId.FANTOM_TESTNET]: 'fantomTestnet',
  [ChainId.CELO]: 'celo',
  [ChainId.CELO_ALFAJORES]: 'celoAlfajores',
  [ChainId.OPTIMISM]: 'optimism',
  [ChainId.OPTIMISM_GOERLI]: 'optimismGoerlie',
  [ChainId.SHIBARIUM]: 'shibarium',
  [ChainId.SHIBARIUM_TESTNET]: 'shibariumTestnet',
  [ChainId.PULSECHAIN]: 'pulsechain',
  [ChainId.PULSECHAIN_TESTNET]: 'pulsechainTestnet',
  [ChainId.XEN]: 'xen',
  [ChainId.XEN_DEVNET]: 'xenDevnet',
  [ChainId.HARMONY]: 'harmony',
  [ChainId.HARMONY_TESTNET]: 'harmonyTestnet',
  [ChainId.COINEX]: 'coinEx',
  [ChainId.COINEX_TESTNET]: 'coinExTestnet',
  [ChainId.DOGECHAIN]: 'dogechain',
  [ChainId.DOGECHAIN_TESTNET]: 'dogechainTestnet',
  [ChainId.ENGRAM_TESTNET]: 'engramTestnet',
  [ChainId.ETHEREUM_CLASSIC]: 'EthereumClassic',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: 'EthereumClassicTestnet',
  [ChainId.FUSION]: 'fusion',
  [ChainId.FUSION_TESTNET]: 'fusionTestnet',
  [ChainId.HECO]: 'heco',
  [ChainId.HECO_TESTNET]: 'hecoTestnet',
  [ChainId.KCC]: 'kcc',
  [ChainId.KCC_TESTNET]: 'kccTestnet',
  [ChainId.KLAYTN]: 'klaytn',
  [ChainId.KLAYTN_BAOBAB]: 'klaytnBaobob',
  [ChainId.OKXCHAIN]: 'okxChain',
  [ChainId.OKXCHAIN_TESTNET]: 'okxChainTestnet',
  [ChainId.THETHA]: 'theta',
  [ChainId.THETHA_TESTNET]: 'thetaTestnet',
  [ChainId.ULTRON]: 'ultron',
  [ChainId.ULTRON_TESTNET]: 'ultronTestnet',
  [ChainId.MOONBEAM]: 'moonbeam',
  [ChainId.MOONRIVER]: 'moonriver',
  [ChainId.MOONBASE_ALPHA]: 'moonbaseAlpha',
  [ChainId.AURORA]: 'aurora',
  [ChainId.AURORA_TESTNET]: 'auroraTestnet',
  [ChainId.BOBA]: 'boba',
  [ChainId.BOBA_GOERLI]: 'bobaGoerli',
  [ChainId.GNOSIS]: 'gnosis',
  [ChainId.GNOSIS_CHIADO]: 'gnosisChiado',
  [ChainId.METIS]: 'metis',
  [ChainId.METIS_GOERLI]: 'metisGoerli',
  [ChainId.TELOS]: 'telos',
  [ChainId.TELOS_TESTNET]: 'telosTestnet',
  [ChainId.SYSCOIN]: 'syscoin',
  [ChainId.SYSCOIN_TANENBAUM]: 'syscoinTanenbaum',
  [ChainId.IOTEX]: 'iotex',
  [ChainId.IOTEX_TESTNET]: 'iotexTestnet',
  [ChainId.SHIDEN]: 'shiden',
  [ChainId.ASTAR]: 'astar',
  [ChainId.SHIBUNYA]: 'shibunya',
  [ChainId.FUSE]: 'fuse',
  [ChainId.FUSE_SPARKNET]: 'fuseSparknet',
  [ChainId.VELAS]: 'velas',
  [ChainId.VELAS_TESTNET]: 'velasTestnet',
  [ChainId.REI]: 'rei',
  [ChainId.REI_TESTNET]: 'reiTestnet',
  [ChainId.KEKCHAIN]: 'kekchain',
  [ChainId.KEKCHAIN_TESTNET]: 'kekchainTestnet',
  [ChainId.TOMOCHAIN]: 'tomochain',
  [ChainId.TOMOCHAIN_TESTNET]: 'tomochainTestnet',
  [ChainId.THUNDERCORE]: 'thundercore',
  [ChainId.THUNDERCORE_TESTNET]: 'thundercoreTestnet',
  [ChainId.WANCHAIN]: 'wanchain',
  [ChainId.WANCHAIN_TESTNET]: 'wanchainTestnet',
  [ChainId.RSK]: 'rsk',
  [ChainId.RSK_TESTNET]: 'rskTestnet',
  [ChainId.ELASTOS]: 'elastos',
  [ChainId.ELASTOS_TESTNET]: 'elastosTestnet',
  [ChainId.CONFLUX]: 'conflux',
  [ChainId.CONFLUX_TESTNET]: 'confluxTestnet',
  [ChainId.BRISECHAIN]: 'brisebriseTestnet',
  [ChainId.BRISECHAIN_TESTNET]: 'briseTestnet',
  [ChainId.MUUCHAIN]: 'muuchain',
  [ChainId.MUUCHAIN_TESTNET]: 'muuchainTestnet',
  [ChainId.CANTO]: 'canto',
  [ChainId.CANTO_TESTNET]: 'cantoTestnet',
  [ChainId.OASIS_EMERALD]: 'oasis',
  [ChainId.OASIS_TESTNET]: 'oasisTestnet',
  [ChainId.VISION]: 'vision',
  [ChainId.VISION_TESTNET]: 'visionTestnet',
  [ChainId.STEP]: 'step',
  [ChainId.STEP_TESTNET]: 'stepTestnet',
  [ChainId.METER]: 'meter',
  [ChainId.METER_TESTNET]: 'meterTestnet',
  [ChainId.GODWOKEN]: 'godwoken',
  [ChainId.GODWOKEN_TESTNET]: 'godwokenTestnet',
  [ChainId.CALLISTO]: 'callisto',
  [ChainId.CALLISTO_TESTNET]: 'callistoTestnet',
  [ChainId.EVMOS]: 'evmos',
  [ChainId.EVMOS_TESTNET]: 'evmosTestnet',
  [ChainId.ENERGY_WEB_CHAIN]: 'energywebchain',
  [ChainId.ENERGY_VOLTA]: 'energyVolta',
  [ChainId.BASE]: 'base',
  [ChainId.BASE_GOERLI]: 'baseGoerli',
  [ChainId.KAVA]: 'kava',
  [ChainId.KAVA_TESTNET]: 'kavaTestnet',
  [ChainId.CLOVER_TESTNET]: 'cloverTestnet',
  [ChainId.CLOVER]: 'clover',
  [ChainId.DEFICHAIN]: 'defichain',
  [ChainId.DEFICHAIN_TESTNET]: 'defichainTestnet',
  [ChainId.BRONOS]: 'bronos',
  [ChainId.BRONOS_TESTNET]: 'bronosTestnet',
  [ChainId.FILECOIN]: 'filecoin',
  [ChainId.FILECOIN_CALIBRATION]: 'filecoinCalibration',
  [ChainId.FLARE]: 'flare',
  [ChainId.FLARE_TESTNET]: 'flareTestnet',
  [ChainId.TARAXA]: 'taraxa',
  [ChainId.TARAXA_TESTNET]: 'taraxaTestnet',
  [ChainId.ARBITRUM_ONE]: 'arbitrum',
  [ChainId.ARBITRUM_GOERLI]: 'arbitrumGoerli',
  [ChainId.ZKSYNC]: 'era',
  [ChainId.ZKSYNC_TESTNET]: 'eraTestnet',
  [ChainId.LINEA]: 'linea',
  [ChainId.LINEA_TESTNET]: 'lineaTestnet',
  [ChainId.BTTCHAIN]: 'bttchain',
  [ChainId.BTTCHAIN_TESTNET]: 'bttchainTestnet',
  [ChainId.BOBA_AVAX]: 'bobaAvax',
  [ChainId.BOBA_BNB]: 'bobaBnb',
  [ChainId.CMP]: 'cmp',
  [ChainId.CMP_TESTNET]: 'cmpTestnet',
  [ChainId.CORE]: 'core',
  [ChainId.CORE_TESTNET]: 'coreTestnet',
  [ChainId.CUBECHAIN]: 'cubechain',
  [ChainId.CUBECHAIN_TESTNET]: 'cubechain',
  [ChainId.DARWINIA_CRAB]: 'darwiniaCrab',
  [ChainId.DARWINIA_PANGOLIN]: 'darwiniaPangolin',
  [ChainId.DIODE_PRETNET]: 'diode',
  [ChainId.DIODE_TESTNET]: 'diodeTestnet',
  [ChainId.GOCHAIN]: 'gochain',
  [ChainId.GOCHAIN_TESTNET]: 'gochainTestnet',
  [ChainId.HAQQ]: 'haqq',
  [ChainId.HAQQ_TESTEDGE2]: 'haqqTestnet',
  [ChainId.KARDIACHAIN]: 'kardiachain',
  [ChainId.KARDIACHAIN_TESTNET]: 'kardiachainTestnet',
  [ChainId.METADIUM]: 'metadium',
  [ChainId.METADIUM_TESTNET]: 'metadiumTestnet',
  [ChainId.OP_BNB]: 'opBnb',
  [ChainId.OP_BNB_TESTNET]: 'opBnbTestnet',
  [ChainId.PALM]: 'palm',
  [ChainId.PALM_TESTNET]: 'palmTestnet',
  [ChainId.POLIS]: 'polis',
  [ChainId.POLIS_TESTNET]: 'polisTestnet',
  [ChainId.SHIBACHAIN]: 'shibachain',
  [ChainId.SMART_BCH]: 'smartBch',
  [ChainId.SMART_BCH_TESTNET]: 'smartBchTestnet',
  [ChainId.SONGBIRD_CANARY]: 'songbird',
  [ChainId.THAICHAIN]: 'thaichain',
  [ChainId.UBIQ]: 'ubiq',
  [ChainId.UBIQ_TESTNET]: 'ubiqTestnet',
  [ChainId.VECHAIN]: 'vechain',
  [ChainId.VECHAIN_TESTNET]: 'vechainTestnet',
  [ChainId.XINFIN]: 'xinfin',
  [ChainId.XINFIN_APOTHEM]: 'xinfinTestnet',
  [ChainId.SCROLL]: 'scroll',
  [ChainId.SCROLL_SEPOLIA]: 'scrollTestnet',
  [ChainId.XRPL_DEVNET]: 'xrplDevnet',
  [ChainId.ZETACHAIN]: 'zetachain',
  [ChainId.ZETACHAIN_ATHENS]: 'zetachainAthens',
  [ChainId.ZYX]: 'zyx',
} as const satisfies Record<number, string>

// use for fetch usd outside of the liquidity pools on Donaswap
export const fetchTokenUSDValue = async (chainId: number, tokenAddresses: string[]) => {
  if (!tokenAddresses.length || !CHAIN_MAPPING[chainId]) return new Map<string, string>()

  const list = tokenAddresses.map((address) => `${CHAIN_MAPPING[chainId]}:${address}`).join(',')

  const result: { coins: { [key: string]: { price: string } } } = await fetch(
    `https://coins.llama.fi/prices/current/${list}`,
  ).then((res) => res.json())

  const commonTokenUSDValue = new Map<string, string>()

  Object.entries(result.coins || {}).forEach(([key, value]) => {
    const [, address] = key.split(':')
    commonTokenUSDValue.set(address, value.price)
  })

  return commonTokenUSDValue
}
