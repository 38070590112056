export const FIRECHAIN_LIST = 'https://tokens.donaswap.io/firechain.json'
export const RINIA_LIST = 'https://tokens.donaswap.io/rinia.json'
export const FIRECHAIN_ZKEVM_LIST = 'https://tokens.donaswap.io/firechain-zkevm.json'
export const FIRECHAIN_ZKEVM_GHOSTRIDER_LIST = 'https://tokens.donaswap.io/firechain-zkevm-ghostrider.json'
export const BSC_LIST = 'https://tokens.donaswap.io/bsc.json'
export const ETHEREUM_LIST = 'https://tokens.donaswap.io/ethereum.json'
export const POLYGON_LIST = 'https://tokens.donaswap.io/polygon.json'
export const POLYGON_ZKEVM_LIST = 'https://tokens.donaswap.io/polygonZkEvm.json'
export const CRO_LIST = 'https://tokens.donaswap.io/cro.json'
export const AVALANCHE_LIST = 'https://tokens.donaswap.io/avalanche.json'
export const FANTOM_LIST = 'https://tokens.donaswap.io/fantom.json'
export const CELO_LIST = 'https://celo-org.github.io/celo-token-list/celo.tokenlist.json'
export const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'
export const ARBITRUM_LIST = 'https://bridge.arbitrum.io/token-list-42161.json'
export const SHIBARIUM_LIST = 'https://tokens.donaswap.io/shibarium.json'
export const PULSECHAIN_LIST = 'https://tokens.donaswap.io/pulsechain.json'
export const XEN_LIST = 'https://tokens.donaswap.io/xen.json'
export const HARMONY_LIST = 'https://tokens.donaswap.io/harmony.json'
export const COINEX = 'https://tokens.donaswap.io/coinex.json'
export const DOGECHAIN_LIST = 'https://tokens.donaswap.io/dogechain.json'
export const CLASSIC_LIST = 'https://tokens.donaswap.io/ethereumClassic.json'
export const FUSION_LIST = 'https://tokens.donaswap.io/fusion.json'
export const HECO_LIST = 'https://tokens.donaswap.io/heco.json'
export const KCC_LIST = 'https://tokens.donaswap.io/kcc.json'
export const KLAYTN_LIST = 'https://tokens.donaswap.io/klaytn.json'
export const OKXCHAIN_LIST = 'https://tokens.donaswap.io/okxchain.json'
export const THETHA_LIST = 'https://tokens.donaswap.io/thetha.json'
export const ULTRON_LIST = 'https://tokens.donaswap.io/ultron.json'
export const MOONBEAM_LIST = 'https://tokens.donaswap.io/moonbeam.json'
export const MOONRIVER_LIST = 'https://tokens.donaswap.io/moonriver.json'
export const AURORA_LIST = 'https://tokens.donaswap.io/aurora.json'
export const BOBA_LIST = 'https://tokens.donaswap.io/boba.json'
export const GNOSIS_LIST = 'https://tokens.donaswap.io/gnosis.json'
export const METIS_LIST = 'https://tokens.donaswap.io/metis.json'
export const TELOS_LIST = 'https://tokens.donaswap.io/telos.json'
export const SYSCOIN_LIST = 'https://tokens.donaswap.io/syscion.json'
export const IOTEX_LIST = 'https://tokens.donaswap.io/iotex.json'
export const ASTAR_LIST = 'https://tokens.donaswap.io/astar.json'
export const SHIDEN_LIST = 'https://tokens.donaswap.io/shiden.json'
export const FUSE_LIST = 'https://tokens.donaswap.io/fuse.json'
export const VELAS_LIST = 'https://tokens.donaswap.io/velas.json'
export const REI_LIST = 'https://tokens.donaswap.io/rei.json'
export const KEKCHAIN_LIST = 'https://tokens.donaswap.io/kekchain.json'
export const TOMOCHAIN_LIST = 'https://tokens.donaswap.io/tomochain.json'
export const THUNDERCORE_LIST = 'https://tokens.donaswap.io/thundercore.json'
export const WANCHAIN_LIST = 'https://tokens.donaswap.io/wanchain.json'
export const RSK_LIST = 'https://tokens.donaswap.io/rsk.json'
export const ELASTOS_LIST = 'https://tokens.donaswap.io/elastos.json'
export const CONFLUX_LIST = 'https://tokens.donaswap.io/conflux.json'
export const BRISECHAIN_LIST = 'https://tokens.donaswap.io/brisechain.json'
export const MUUCHAIN_LIST = 'https://tokens.donaswap.io/muuchain.json'
export const CANTO_LIST = 'https://tokens.donaswap.io/canto.json'
export const OASIS_LIST = 'https://tokens.donaswap.io/oasis.json'
export const VISION_LIST = 'https://tokens.donaswap.io/vision.json'
export const STEP_LIST = 'https://tokens.donaswap.io/step.json'
export const METER_LIST = 'https://tokens.donaswap.io/meter.json'
export const GODWOKEN_LIST = 'https://tokens.donaswap.io/godwoken.json'
export const CALLISTO_LIST = 'https://tokens.donaswap.io/callisto.json'
export const EVMOS_LIST = 'https://tokens.donaswap.io/evmos.json'
export const ENERGY_WEB_CHAIN_LIST = 'https://tokens.donaswap.io/energyWebchain.json'
export const BASE_LIST = 'https://tokens.donaswap.io/base.json'
export const KAVA_LIST = 'https://tokens.donaswap.io/kava.json'
export const CLOVER_LIST = 'https://tokens.donaswap.io/clover.json'
export const DEFICHAIN_LIST = 'https://tokens.donaswap.io/defichain.json'
export const BRONOS_LIST = 'https://tokens.donaswap.io/bronos.json'
export const FILECOIN_LIST = 'https://tokens.donaswap.io/filecoin.json'
export const FLARE_LIST = 'https://tokens.donaswap.io/flare.json'
export const TARAXA_LIST = 'https://tokens.donaswap.io/taraxa.json'
export const ZKSYNC_LIST = 'https://tokens.donaswap.io/zksync.json'
export const LINEA_LIST = 'https://tokens.donaswap.io/linea.json'
export const BTTCHAIN_LIST = 'https://tokens.donaswap.io/bttchain.json'
export const BOBA_AVAX_LIST = 'https://tokens.donaswap.io/bobaAvax.json'
export const BOBA_BNB_LIST = 'https://tokens.donaswap.io/bobaBnb.json'
export const CMP_LIST = 'https://tokens.donaswap.io/cmp.json'
export const CORE_LIST = 'https://tokens.donaswap.io/core.json'
export const CUBECHAIN_LIST = 'https://tokens.donaswap.io/cubechain.json'
export const DARWINIA_CRAB_LIST = 'https://tokens.donaswap.io/darwinia.json'
export const DIODE_PRETNET_LIST = 'https://tokens.donaswap.io/diode.json'
export const GOCHAIN_LIST = 'https://tokens.donaswap.io/gochain.json'
export const HAQQ_LIST = 'https://tokens.donaswap.io/haqq.json'
export const KARDIACHAIN_LIST = 'https://tokens.donaswap.io/kardiachain.json'
export const METADIUM_LIST = 'https://tokens.donaswap.io/metadium.json'
export const PALM_LIST = 'https://tokens.donaswap.io/palm.json'
export const POLIS_LIST = 'https://tokens.donaswap.io/polis.json'
export const SHIBACHAIN_LIST = 'https://tokens.donaswap.io/shibachain.json'
export const SMART_BCH_LIST = 'https://tokens.donaswap.io/smartBch.json'
export const SONGBIRD_CANARY_LIST = 'https://tokens.donaswap.io/songbird.json'
export const THAICHAIN_LIST = 'https://tokens.donaswap.io/thaichain.json'
export const UBIQ_LIST = 'https://tokens.donaswap.io/ubiq.json'
export const VECHAIN_LIST = 'https://tokens.donaswap.io/vechain.json'
export const XINFIN_LIST = 'https://tokens.donaswap.io/xinfin.json'
export const SCROLL_LIST = 'https://tokens.donaswap.io/scroll.json'
export const XRPL_DEVNET_LIST = 'https://tokens.donaswap.io/zeta.json'
export const ZETACHAIN_LIST = 'https://tokens.donaswap.io/zeta.json'
export const ZYX_LIST = 'https://tokens.donaswap.io/zyx.json'

export const DONASWAP_EXTENDED = 'https://tokens.donaswap.io/donaswap-extended.json'
export const DONASWAP_EXTENDED_BSC = 'https://tokens.donaswap.io/donaswap-extended-bsc.json'
export const COINGECKO = 'https://tokens.donaswap.io/coingecko.json'
export const DONASWAP_ETH_DEFAULT = 'https://tokens.donaswap.io/donaswap-eth-default.json'
export const DONASWAP_ETH_MM = 'https://tokens.donaswap.io/donaswap-eth-mm.json'
export const DONASWAP_BSC_MM = 'https://tokens.donaswap.io/donaswap-bnb-mm.json'
export const COINGECKO_ETH = 'https://tokens.coingecko.com/uniswap/all.json'
export const CMC = 'https://tokens.donaswap.io/cmc.json'
export const COMPOUND_LIST = 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'
export const GEMINI_LIST = 'https://www.gemini.com/uniswap/manifest.json'
export const KLEROS_LIST = 't2crtokens.eth'
export const ROLL_LIST = 'https://app.tryroll.com/tokens.json'
export const SET_LIST = 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.tokenlist.json'
export const WRAPPED_LIST = 'wrapped.tokensoft.eth'
export const UNI_LIST = 'https://tokens.donaswap.io/lists/uniswap.json'
export const UNI_EXTENDED_LIST = 'https://tokens.donaswap.io/uniswap-extended.json'
export const AAVE_LIST = 'tokenlist.aave.eth'
export const BA_LIST = 'https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json'
export const CMC_ALL_LIST = 'https://api.coinmarketcap.com/data-api/v3/uniswap/all.json'

export const FIRECHAIN_URLS = [FIRECHAIN_LIST]
export const RINIA_URLS = [RINIA_LIST]
export const FIRECHAIN_ZKEVM_URLS = [FIRECHAIN_ZKEVM_LIST]
export const FIRECHAIN_ZKEVM_GHOSTRIDER_URLS = [FIRECHAIN_ZKEVM_GHOSTRIDER_LIST]
export const ETH_URLS = [
  DONASWAP_ETH_DEFAULT,
  DONASWAP_ETH_MM,
  COINGECKO_ETH,
  COMPOUND_LIST,
  GEMINI_LIST,
  KLEROS_LIST,
  ROLL_LIST,
  SET_LIST,
  WRAPPED_LIST,
  UNI_LIST,
  UNI_EXTENDED_LIST,
  AAVE_LIST,
  BA_LIST,
  CMC_ALL_LIST
]
export const BSC_URLS = [DONASWAP_EXTENDED, DONASWAP_EXTENDED_BSC, CMC, COINGECKO, DONASWAP_BSC_MM]
export const POLYGON_URLS = [POLYGON_LIST]
export const POLYGON_ZKEVM_URLS = [POLYGON_ZKEVM_LIST]
export const CRO_URLS = [CRO_LIST]
export const AVALANCHE_URLS = [AVALANCHE_LIST]
export const FANTOM_URLS = [FANTOM_LIST]
export const CELO_URLS = [CELO_LIST]
export const OPTIMISM_URLS = [OPTIMISM_LIST]
export const ARBITRUM_URLS = [ARBITRUM_LIST]
export const SHIBARIUM_URLS = [SHIBARIUM_LIST]
export const PULSECHAIN_URLS = [PULSECHAIN_LIST]
export const XEN_URLS = [XEN_LIST]
export const HARMONY_URLS = [HARMONY_LIST]
export const COINEX_URLS = [COINEX]
export const DOGECHAIN_URLS = [DOGECHAIN_LIST]
export const CLASSIC_URLS = [CLASSIC_LIST]
export const FUSION_URLS = [FUSION_LIST]
export const HECO_URLS = [HECO_LIST]
export const KCC_URLS = [KCC_LIST]
export const KLAYTN_URLS = [KLAYTN_LIST]
export const OKXCHAIN_URLS = [OKXCHAIN_LIST]
export const THETHA_URLS = [THETHA_LIST]
export const ULTRON_URLS = [ULTRON_LIST]
export const MOONBEAM_URLS = [MOONBEAM_LIST]
export const MOONRIVER_URLS = [MOONRIVER_LIST]
export const AURORA_URLS = [AURORA_LIST]
export const BOBA_URLS = [BOBA_LIST]
export const GNOSIS_URLS = [GNOSIS_LIST]
export const METIS_URLS = [METIS_LIST]
export const TELOS_URLS = [TELOS_LIST]
export const SYSCOIN_URLS = [SYSCOIN_LIST]
export const IOTEX_URLS = [IOTEX_LIST]
export const ASTAR_URLS = [ASTAR_LIST]
export const SHIDEN_URLS = [SHIDEN_LIST]
export const FUSE_URLS = [FUSE_LIST]
export const VELAS_URLS = [VELAS_LIST]
export const REI_URLS = [REI_LIST]
export const KEKCHAIN_URLS = [KEKCHAIN_LIST]
export const TOMOCHAIN_URLS = [TOMOCHAIN_LIST]
export const THUNDERCORE_URLS = [THUNDERCORE_LIST]
export const WANCHAIN_URLS = [WANCHAIN_LIST]
export const RSK_URLS = [RSK_LIST]
export const ELASTOS_URLS = [ELASTOS_LIST]
export const CONFLUX_URLS = [CONFLUX_LIST]
export const BRISECHAIN_URLS = [BRISECHAIN_LIST]
export const MUUCHAIN_URLS = [MUUCHAIN_LIST]
export const CANTO_URLS = [CANTO_LIST]
export const OASIS_URLS = [OASIS_LIST]
export const VISION_URLS = [VISION_LIST]
export const STEP_URLS = [STEP_LIST]
export const METER_URLS = [METER_LIST]
export const GODWOKEN_URLS = [GODWOKEN_LIST]
export const CALLISTO_URLS = [CALLISTO_LIST]
export const EVMOS_URLS = [EVMOS_LIST]
export const ENERGY_WEB_CHAIN_URLS = [ENERGY_WEB_CHAIN_LIST]
export const BASE_URLS = [BASE_LIST]
export const KAVA_URLS = [KAVA_LIST]
export const CLOVER_URLS = [CLOVER_LIST]
export const DEFICHAIN_URLS = [DEFICHAIN_LIST]
export const BRONOS_URLS = [BRONOS_LIST]
export const FILECOIN_URLS = [FILECOIN_LIST]
export const FLARE_URLS = [FLARE_LIST]
export const TARAXA_URLS = [TARAXA_LIST]
export const ZKSYNC_URLS_URLS = [ZKSYNC_LIST]
export const LINEA_URLS = [LINEA_LIST]
export const BTTCHAIN_URLS = [BTTCHAIN_LIST]
export const BOBA_AVAX_URLS = [BOBA_AVAX_LIST]
export const BOBA_BNB_URLS = [BOBA_BNB_LIST]
export const CMP_URLS = [CMP_LIST]
export const CORE_URLS = [CORE_LIST]
export const CUBECHAIN_URLS = [CUBECHAIN_LIST]
export const DARWINIA_CRAB_URLS = [DARWINIA_CRAB_LIST]
export const DIODE_PRETNET_URLS = [DIODE_PRETNET_LIST]
export const GOCHAIN_URLS = [GOCHAIN_LIST]
export const HAQQ_URLS = [HAQQ_LIST]
export const KARDIACHAIN_URLS = [KARDIACHAIN_LIST]
export const METADIUM_URLS = [METADIUM_LIST]
export const PALM_URLS = [PALM_LIST]
export const POLIS_URLS = [POLIS_LIST]
export const SHIBACHAIN_URLS = [SHIBACHAIN_LIST]
export const SMART_BCH_URLS = [SMART_BCH_LIST]
export const SONGBIRD_CANARY_URLS = [SONGBIRD_CANARY_LIST]
export const THAICHAIN_URLS = [THAICHAIN_LIST]
export const UBIQ_URLS = [UBIQ_LIST]
export const VECHAIN_URLS = [VECHAIN_LIST]
export const XINFIN_URLS = [XINFIN_LIST]
export const SCROLL_URLS = [SCROLL_LIST]
export const XRPL_DEVNET_URLS = [XRPL_DEVNET_LIST]
export const ZETACHAIN_URLS = [ZETACHAIN_LIST]
export const ZYX_URLS = [ZYX_LIST]
// List of official tokens list
export const OFFICIAL_LISTS = [DONASWAP_EXTENDED_BSC, DONASWAP_ETH_DEFAULT]

export const UNSUPPORTED_LIST_URLS: string[] = []
export const WARNING_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  RINIA_LIST,
  FIRECHAIN_LIST,
  FIRECHAIN_ZKEVM_LIST,
  FIRECHAIN_ZKEVM_GHOSTRIDER_LIST,
  BSC_LIST,
  ETHEREUM_LIST,
  POLYGON_LIST,
  POLYGON_ZKEVM_LIST,
  CRO_LIST,
  AVALANCHE_LIST,
  FANTOM_LIST,
  CELO_LIST,
  OPTIMISM_LIST,
  ARBITRUM_LIST,
  SHIBARIUM_LIST,
  PULSECHAIN_LIST,
  XEN_LIST,
  HARMONY_LIST,
  COINEX,
  DOGECHAIN_LIST,
  CLASSIC_LIST,
  FUSION_LIST,
  HECO_LIST,
  KCC_LIST,
  KLAYTN_LIST,
  OKXCHAIN_LIST,
  THETHA_LIST,
  ULTRON_LIST,
  MOONBEAM_LIST,
  MOONRIVER_LIST,
  AURORA_LIST,
  BOBA_LIST,
  GNOSIS_LIST,
  METIS_LIST,
  TELOS_LIST,
  SYSCOIN_LIST,
  IOTEX_LIST,
  SHIDEN_LIST,
  ASTAR_LIST,
  FUSE_LIST,
  VELAS_LIST,
  REI_LIST,
  KEKCHAIN_LIST,
  TOMOCHAIN_LIST,
  THUNDERCORE_LIST,
  WANCHAIN_LIST,
  RSK_LIST,
  ELASTOS_LIST,
  CONFLUX_LIST,
  BRISECHAIN_LIST,
  MUUCHAIN_LIST,
  CANTO_LIST,
  OASIS_LIST,
  VISION_LIST,
  STEP_LIST,
  METER_LIST,
  GODWOKEN_LIST,
  CALLISTO_LIST,
  EVMOS_LIST,
  ENERGY_WEB_CHAIN_LIST,
  BASE_LIST,
  KAVA_LIST,
  CLOVER_LIST,
  DEFICHAIN_LIST,
  BRONOS_LIST,
  FILECOIN_LIST,
  FLARE_LIST,
  TARAXA_LIST,
  ZKSYNC_LIST,
  LINEA_LIST,
  BTTCHAIN_LIST,
  BOBA_AVAX_LIST,
  BOBA_BNB_LIST,
  CMP_LIST,
  CORE_LIST,
  CUBECHAIN_LIST,
  DARWINIA_CRAB_LIST,
  DIODE_PRETNET_LIST,
  GOCHAIN_LIST,
  HAQQ_LIST,
  KARDIACHAIN_LIST,
  METADIUM_LIST,
  PALM_LIST,
  POLIS_LIST,
  SHIBACHAIN_LIST,
  SMART_BCH_LIST,
  SONGBIRD_CANARY_LIST,
  THAICHAIN_LIST,
  UBIQ_LIST,
  VECHAIN_LIST,
  XINFIN_LIST,
  SCROLL_LIST,
  ZYX_LIST,
  DONASWAP_EXTENDED,
  DONASWAP_EXTENDED_BSC,
  COINGECKO,
  CMC,
  GEMINI_LIST,
  KLEROS_LIST,
  ROLL_LIST,
  SET_LIST,
  COMPOUND_LIST,
  WRAPPED_LIST,
  UNI_LIST,
  UNI_EXTENDED_LIST,
  AAVE_LIST,
  BA_LIST,
  CMC_ALL_LIST,
  ...BSC_URLS,
  ...ETH_URLS,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
  ...WARNING_LIST_URLS,
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [DONASWAP_EXTENDED_BSC, DONASWAP_ETH_DEFAULT, DONASWAP_ETH_MM]
