import { ChainId } from '@donaswap/sdk'
import { ComputedFarmConfigV3 } from '../../src/types'
import { FarmSupportedChainId } from '../../src'
import { farmsV3 as farm529 } from '../529'
import { farmsV3 as farm917 } from '../917'
import { farmsV3 as farm1 } from '../1'
import { farmsV3 as farm5 } from '../5'
import { farmsV3 as farm11155111 } from '../11155111'
import { farmsV3 as farm17000 } from '../17000'
import { farmsV3 as farm56 } from '../56'
import { farmsV3 as farm97 } from '../97'
import { farmsV3 as farm137 } from '../137'
import { farmsV3 as farm80002 } from '../80002'
import { farmsV3 as farm1101 } from '../1101'
import { farmsV3 as farm1442 } from '../1442'
import { farmsV3 as farm25 } from '../25'
import { farmsV3 as farm338 } from '../338'
import { farmsV3 as farm43114 } from '../43114'
import { farmsV3 as farm43113 } from '../43113'
import { farmsV3 as farm250 } from '../250'
import { farmsV3 as farm4002 } from '../4002'
import { farmsV3 as farm42220 } from '../42220'
import { farmsV3 as farm44787 } from '../44787'
import { farmsV3 as farm10 } from '../10'
import { farmsV3 as farm420 } from '../420'
import { farmsV3 as farm42161 } from '../42161'
import { farmsV3 as farm421613 } from '../421613'
import { farmsV3 as farm109 } from '../109'
import { farmsV3 as farm719 } from '../719'
import { farmsV3 as farm369 } from '../369'
import { farmsV3 as farm943 } from '../943'
import { farmsV3 as farm20221200000 } from '../20221200000'
import { farmsV3 as farm202212 } from '../202212'
import { farmsV3 as farm1666600000 } from '../1666600000'
import { farmsV3 as farm1666700000 } from '../1666700000'
import { farmsV3 as farm52 } from '../52'
import { farmsV3 as farm53 } from '../53'
import { farmsV3 as farm2000 } from '../2000'
import { farmsV3 as farm568 } from '../568'
import { farmsV3 as farm131 } from '../131'
import { farmsV3 as farm61 } from '../61'
import { farmsV3 as farm63 } from '../63'
import { farmsV3 as farm32659 } from '../32659'
import { farmsV3 as farm46688 } from '../46688'
import { farmsV3 as farm128 } from '../128'
import { farmsV3 as farm256 } from '../256'
import { farmsV3 as farm321 } from '../321'
import { farmsV3 as farm322 } from '../322'
import { farmsV3 as farm8217 } from '../8217'
import { farmsV3 as farm1001 } from '../1001'
import { farmsV3 as farm66 } from '../66'
import { farmsV3 as farm65 } from '../65'
import { farmsV3 as farm361 } from '../361'
import { farmsV3 as farm365 } from '../365'
import { farmsV3 as farm1231 } from '../1231'
import { farmsV3 as farm1230 } from '../1230'
import { farmsV3 as farm1284 } from '../1284'
import { farmsV3 as farm1285 } from '../1285'
import { farmsV3 as farm1287 } from '../1287'
import { farmsV3 as farm1313161554 } from '../1313161554'
import { farmsV3 as farm1313161555 } from '../1313161555'
import { farmsV3 as farm288 } from '../288'
import { farmsV3 as farm2888 } from '../2888'
import { farmsV3 as farm100 } from '../100'
import { farmsV3 as farm10200 } from '../10200'
import { farmsV3 as farm1088 } from '../1088'
import { farmsV3 as farm599 } from '../599'
import { farmsV3 as farm40 } from '../40'
import { farmsV3 as farm41 } from '../41'
import { farmsV3 as farm57 } from '../57'
import { farmsV3 as farm5700 } from '../5700'
import { farmsV3 as farm4689 } from '../4689'
import { farmsV3 as farm4690 } from '../4690'
import { farmsV3 as farm336 } from '../336'
import { farmsV3 as farm592 } from '../592'
import { farmsV3 as farm81 } from '../81'
import { farmsV3 as farm122 } from '../122'
import { farmsV3 as farm123 } from '../123'
import { farmsV3 as farm106 } from '../106'
import { farmsV3 as farm111 } from '../111'
import { farmsV3 as farm55555 } from '../55555'
import { farmsV3 as farm55556 } from '../55556'
import { farmsV3 as farm420420 } from '../420420'
import { farmsV3 as farm420666 } from '../420666'
import { farmsV3 as farm88 } from '../88'
import { farmsV3 as farm89 } from '../89'
import { farmsV3 as farm108 } from '../108'
import { farmsV3 as farm18 } from '../18'
import { farmsV3 as farm888 } from '../888'
import { farmsV3 as farm999 } from '../999'
import { farmsV3 as farm30 } from '../30'
import { farmsV3 as farm31 } from '../31'
import { farmsV3 as farm20 } from '../20'
import { farmsV3 as farm21 } from '../21'
import { farmsV3 as farm1030 } from '../1030'
import { farmsV3 as farm71 } from '../71'
import { farmsV3 as farm32520 } from '../32520'
import { farmsV3 as farm64668 } from '../64668'
import { farmsV3 as farm20402 } from '../20402'
import { farmsV3 as farm42110 } from '../42110'
import { farmsV3 as farm7700 } from '../7700'
import { farmsV3 as farm7701 } from '../7701'
import { farmsV3 as farm42262 } from '../42262'
import { farmsV3 as farm42261 } from '../42261'
import { farmsV3 as farm888888 } from '../888888'
import { farmsV3 as farm666666 } from '../666666'
import { farmsV3 as farm1234 } from '../1234'
import { farmsV3 as farm12345 } from '../12345'
import { farmsV3 as farm82 } from '../82'
import { farmsV3 as farm83 } from '../83'
import { farmsV3 as farm71402 } from '../71402'
import { farmsV3 as farm71401 } from '../71401'
import { farmsV3 as farm820 } from '../820'
import { farmsV3 as farm20729 } from '../20729'
import { farmsV3 as farm9001 } from '../9001'
import { farmsV3 as farm9000 } from '../9000'
import { farmsV3 as farm246 } from '../246'
import { farmsV3 as farm73799 } from '../73799'
import { farmsV3 as farm8453 } from '../8453'
import { farmsV3 as farm84531 } from '../84531'
import { farmsV3 as farm2222 } from '../2222'
import { farmsV3 as farm2221 } from '../2221'
import { farmsV3 as farm1024 } from '../1024'
import { farmsV3 as farm1023 } from '../1023'
import { farmsV3 as farm1131 } from '../1131'
import { farmsV3 as farm1132 } from '../1132'
import { farmsV3 as farm1039 } from '../1039'
import { farmsV3 as farm1038 } from '../1038'
import { farmsV3 as farm314 } from '../314'
import { farmsV3 as farm314159 } from '../314159'
import { farmsV3 as farm14 } from '../14'
import { farmsV3 as farm114 } from '../114'
import { farmsV3 as farm841 } from '../841'
import { farmsV3 as farm842 } from '../842'
import { farmsV3 as farm324 } from '../324'
import { farmsV3 as farm280 } from '../280'
import { farmsV3 as farm59144 } from '../59144'
import { farmsV3 as farm59140 } from '../59140'
import { farmsV3 as farm204 } from '../204'
import { farmsV3 as farm5611 } from '../5611'
import { farmsV3 as farm43288 } from '../43288'
import { farmsV3 as farm56288 } from '../56288'
import { farmsV3 as farm199 } from '../199'
import { farmsV3 as farm1029 } from '../1029'
import { farmsV3 as farm256256 } from '../256256'
import { farmsV3 as farm512512 } from '../512512'
import { farmsV3 as farm1116 } from '../1116'
import { farmsV3 as farm1115 } from '../1115'
import { farmsV3 as farm1818 } from '../1818'
import { farmsV3 as farm1819 } from '../1819'
import { farmsV3 as farm44 } from '../44'
import { farmsV3 as farm43 } from '../43'
import { farmsV3 as farm15 } from '../15'
import { farmsV3 as farm13 } from '../13'
import { farmsV3 as farm60 } from '../60'
import { farmsV3 as farm31337 } from '../31337'
import { farmsV3 as farm11235 } from '../11235'
import { farmsV3 as farm54211 } from '../54211'
import { farmsV3 as farm24 } from '../24'
import { farmsV3 as farm242 } from '../242'
import { farmsV3 as farm11 } from '../11'
import { farmsV3 as farm12 } from '../12'
import { farmsV3 as farm11297108109 } from '../11297108109'
import { farmsV3 as farm11297108099 } from '../11297108099'
import { farmsV3 as farm333999 } from '../333999'
import { farmsV3 as farm333888 } from '../333888'
import { farmsV3 as farm27 } from '../27'
import { farmsV3 as farm10000 } from '../10000'
import { farmsV3 as farm10001 } from '../10001'
import { farmsV3 as farm19 } from '../19'
import { farmsV3 as farm7 } from '../7'
import { farmsV3 as farm8 } from '../8'
import { farmsV3 as farm9 } from '../9'
import { farmsV3 as farm100009 } from '../100009'
import { farmsV3 as farm100010 } from '../100010'
import { farmsV3 as farm50 } from '../50'
import { farmsV3 as farm51 } from '../51'
import { farmsV3 as farm534352 } from '../534352'
import { farmsV3 as farm534351 } from '../534351'
import { farmsV3 as farm1440002 } from '../1440002'
import { farmsV3 as farm7000 } from '../7000'
import { farmsV3 as farm7001 } from '../7001'
import { farmsV3 as farm55 } from '../55'

import { farmsV3 as farm814 } from '../814'
import { farmsV3 as farm3885 } from '../3885'

export const farmsV3ConfigChainMap: Record<FarmSupportedChainId, ComputedFarmConfigV3[]> = {
  [ChainId.FIRECHAIN]: farm529,
  [ChainId.RINIA]: farm917,
  [ChainId.FIRECHAIN_ZKEVM]: farm814,
  [ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER]: farm3885,
  [ChainId.ETHEREUM]: farm1,
  [ChainId.GOERLI]: farm5,
  [ChainId.SEPOLIA]: farm11155111,
  [ChainId.HOLESKY]: farm17000,
  [ChainId.BSC]: farm56,
  [ChainId.BSC_TESTNET]: farm97,
  [ChainId.POLYGON]: farm137,
  [ChainId.POLYGON_AMOY]: farm80002,
  [ChainId.POLYGON_ZKEVM]: farm1101,
  [ChainId.POLYGON_ZKEVM_TESTNET]: farm1442,
  [ChainId.CRO]: farm25,
  [ChainId.CRO_TESTNET]: farm338,
  [ChainId.AVALANCHE]: farm43114,
  [ChainId.AVALANCHE_FUJI]: farm43113,
  [ChainId.FANTOM_OPERA]: farm250,
  [ChainId.FANTOM_TESTNET]: farm4002,
  [ChainId.CELO]: farm42220,
  [ChainId.CELO_ALFAJORES]: farm44787,
  [ChainId.OPTIMISM]: farm10,
  [ChainId.OPTIMISM_GOERLI]: farm420,
  [ChainId.ARBITRUM_ONE]: farm42161,
  [ChainId.ARBITRUM_GOERLI]: farm421613,
  [ChainId.SHIBARIUM]: farm109,
  [ChainId.SHIBARIUM_TESTNET]: farm719,
  [ChainId.PULSECHAIN]: farm369,
  [ChainId.PULSECHAIN_TESTNET]: farm943,
  [ChainId.XEN]: farm20221200000,
  [ChainId.XEN_DEVNET]: farm202212,
  [ChainId.HARMONY]: farm1666600000,
  [ChainId.HARMONY_TESTNET]: farm1666700000,
  [ChainId.COINEX]: farm52,
  [ChainId.COINEX_TESTNET]: farm53,
  [ChainId.DOGECHAIN]: farm2000,
  [ChainId.DOGECHAIN_TESTNET]: farm568,
  [ChainId.ENGRAM_TESTNET]: farm131,
  [ChainId.ETHEREUM_CLASSIC]: farm61,
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: farm63,
  [ChainId.FUSION]: farm32659,
  [ChainId.FUSION_TESTNET]: farm46688,
  [ChainId.HECO]: farm128,
  [ChainId.HECO_TESTNET]: farm256,
  [ChainId.KCC]: farm321,
  [ChainId.KCC_TESTNET]: farm322,
  [ChainId.KLAYTN]: farm8217,
  [ChainId.KLAYTN_BAOBAB]: farm1001,
  [ChainId.OKXCHAIN]: farm66,
  [ChainId.OKXCHAIN_TESTNET]: farm65,
  [ChainId.THETHA]: farm361,
  [ChainId.THETHA_TESTNET]: farm365,
  [ChainId.ULTRON]: farm1231,
  [ChainId.ULTRON_TESTNET]: farm1230,
  [ChainId.MOONBEAM]: farm1284,
  [ChainId.MOONRIVER]: farm1285,
  [ChainId.MOONBASE_ALPHA]: farm1287,
  [ChainId.AURORA]: farm1313161554,
  [ChainId.AURORA_TESTNET]: farm1313161555,
  [ChainId.BOBA]: farm288,
  [ChainId.BOBA_GOERLI]: farm2888,
  [ChainId.GNOSIS]: farm100,
  [ChainId.GNOSIS_CHIADO]: farm10200,
  [ChainId.METIS]: farm1088,
  [ChainId.METIS_GOERLI]: farm599,
  [ChainId.TELOS]: farm40,
  [ChainId.TELOS_TESTNET]: farm41,
  [ChainId.SYSCOIN]: farm57,
  [ChainId.SYSCOIN_TANENBAUM]: farm5700,
  [ChainId.IOTEX]: farm4689,
  [ChainId.IOTEX_TESTNET]: farm4690,
  [ChainId.SHIDEN]: farm336,
  [ChainId.ASTAR]: farm592,
  [ChainId.SHIBUNYA]: farm81,
  [ChainId.FUSE]: farm122,
  [ChainId.FUSE_SPARKNET]: farm123,
  [ChainId.VELAS]: farm106,
  [ChainId.VELAS_TESTNET]: farm111,
  [ChainId.REI]: farm55555,
  [ChainId.REI_TESTNET]: farm55556,
  [ChainId.KEKCHAIN]: farm420420,
  [ChainId.KEKCHAIN_TESTNET]: farm420666,
  [ChainId.TOMOCHAIN]: farm88,
  [ChainId.TOMOCHAIN_TESTNET]: farm89,
  [ChainId.THUNDERCORE]: farm108,
  [ChainId.THUNDERCORE_TESTNET]: farm18,
  [ChainId.WANCHAIN]: farm888,
  [ChainId.WANCHAIN_TESTNET]: farm999,
  [ChainId.RSK]: farm30,
  [ChainId.RSK_TESTNET]: farm31,
  [ChainId.ELASTOS]: farm20,
  [ChainId.ELASTOS_TESTNET]: farm21,
  [ChainId.CONFLUX]: farm1030,
  [ChainId.CONFLUX_TESTNET]: farm71,
  [ChainId.BRISECHAIN]: farm32520,
  [ChainId.BRISECHAIN_TESTNET]: farm64668,
  [ChainId.MUUCHAIN]: farm20402,
  [ChainId.MUUCHAIN_TESTNET]: farm42110,
  [ChainId.CANTO]: farm7700,
  [ChainId.CANTO_TESTNET]: farm7701,
  [ChainId.OASIS_EMERALD]: farm42262,
  [ChainId.OASIS_TESTNET]: farm42261,
  [ChainId.VISION]: farm888888,
  [ChainId.VISION_TESTNET]: farm666666,
  [ChainId.STEP]: farm1234,
  [ChainId.STEP_TESTNET]: farm12345,
  [ChainId.METER]: farm82,
  [ChainId.METER_TESTNET]: farm83,
  [ChainId.GODWOKEN]: farm71402,
  [ChainId.GODWOKEN_TESTNET]: farm71401,
  [ChainId.CALLISTO]: farm820,
  [ChainId.CALLISTO_TESTNET]: farm20729,
  [ChainId.EVMOS]: farm9001,
  [ChainId.EVMOS_TESTNET]: farm9000,
  [ChainId.ENERGY_WEB_CHAIN]: farm246,
  [ChainId.ENERGY_VOLTA]: farm73799,
  [ChainId.BASE]: farm8453,
  [ChainId.BASE_GOERLI]: farm84531,
  [ChainId.KAVA]: farm2222,
  [ChainId.KAVA_TESTNET]: farm2221,
  [ChainId.CLOVER]: farm1024,
  [ChainId.CLOVER_TESTNET]: farm1023,
  [ChainId.DEFICHAIN]: farm1131,
  [ChainId.DEFICHAIN_TESTNET]: farm1132,
  [ChainId.BRONOS]: farm1039,
  [ChainId.BRONOS_TESTNET]: farm1038,
  [ChainId.FILECOIN]: farm314,
  [ChainId.FILECOIN_CALIBRATION]: farm314159,
  [ChainId.FLARE]: farm14,
  [ChainId.FLARE_TESTNET]: farm114,
  [ChainId.TARAXA]: farm841,
  [ChainId.TARAXA_TESTNET]: farm842,
  [ChainId.ZKSYNC]: farm324,
  [ChainId.ZKSYNC_TESTNET]: farm280,
  [ChainId.LINEA]: farm59144,
  [ChainId.LINEA_TESTNET]: farm59140,
  [ChainId.BTTCHAIN]: farm199,
  [ChainId.BTTCHAIN_TESTNET]: farm1029,
  [ChainId.BOBA_AVAX]: farm43288,
  [ChainId.BOBA_BNB]: farm56288,
  [ChainId.CMP]: farm256256,
  [ChainId.CMP_TESTNET]: farm512512,
  [ChainId.CORE]: farm1116,
  [ChainId.CORE_TESTNET]: farm1115,
  [ChainId.CUBECHAIN]: farm1818,
  [ChainId.CUBECHAIN_TESTNET]: farm1819,
  [ChainId.DARWINIA_CRAB]: farm44,
  [ChainId.DARWINIA_PANGOLIN]: farm43,
  [ChainId.DIODE_PRETNET]: farm15,
  [ChainId.DIODE_TESTNET]: farm13,
  [ChainId.GOCHAIN]: farm60,
  [ChainId.GOCHAIN_TESTNET]: farm31337,
  [ChainId.HAQQ]: farm11235,
  [ChainId.HAQQ_TESTEDGE2]: farm54211,
  [ChainId.KARDIACHAIN]: farm24,
  [ChainId.KARDIACHAIN_TESTNET]: farm242,
  [ChainId.METADIUM]: farm11,
  [ChainId.METADIUM_TESTNET]: farm12,
  [ChainId.OP_BNB]: farm204,
  [ChainId.OP_BNB_TESTNET]: farm5611,
  [ChainId.PALM]: farm11297108109,
  [ChainId.PALM_TESTNET]: farm11297108099,
  [ChainId.POLIS]: farm333999,
  [ChainId.POLIS_TESTNET]: farm333888,
  [ChainId.SHIBACHAIN]: farm27,
  [ChainId.SMART_BCH]: farm10000,
  [ChainId.SMART_BCH_TESTNET]: farm10001,
  [ChainId.SONGBIRD_CANARY]: farm19,
  [ChainId.THAICHAIN]: farm7,
  [ChainId.UBIQ]: farm8,
  [ChainId.UBIQ_TESTNET]: farm9,
  [ChainId.VECHAIN]: farm100009,
  [ChainId.VECHAIN_TESTNET]: farm100010,
  [ChainId.XINFIN]: farm50,
  [ChainId.XINFIN_APOTHEM]: farm51,
  [ChainId.SCROLL]: farm534352,
  [ChainId.SCROLL_SEPOLIA]: farm534351,
  [ChainId.XRPL_DEVNET]: farm1440002,
  [ChainId.ZETACHAIN]: farm7000,
  [ChainId.ZETACHAIN_ATHENS]: farm7001,
  [ChainId.ZYX]: farm55,
}
