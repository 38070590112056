import { __privateAdd, __async, __privateGet, __spreadProps, __spreadValues, __privateSet } from '../chunk-WE74IZDZ.mjs';
import { getAddress, UserRejectedRequestError, ResourceNotFoundRpcError, createWalletClient, custom } from 'viem';
import { Connector, ConnectorNotFoundError, ChainNotConfiguredError } from '@wagmi/core';

// connectors/utils.ts
function normalizeChainId(chainId) {
  if (typeof chainId === "string")
    return Number.parseInt(chainId, chainId.trim().substring(0, 2) === "0x" ? 16 : 10);
  if (typeof chainId === "bigint")
    return Number(chainId);
  return chainId;
}

// connectors/blocto/blocto.ts
var chainIdToNetwork = {
  529: "firechain",
  917: "rinia",
  814: "firechain-zkevm",
  3885: "firechain-zkevm-ghostrider",
  1: "mainnet",
  3: "ropsten",
  4: "rinkeby",
  5: "goerli",
  11155111: "sepolia",
  17e3: "holesky",
  42: "kovan",
  56: "bsc",
  97: "bsc-testnet",
  137: "polygon",
  1101: "polygon-zkevm",
  1442: "polygon-zkevm-testnet",
  80002: "amoy",
  43114: "avalanche",
  43113: "fuji",
  250: "fantom",
  4002: "fantom-testnet",
  42220: "celo",
  44787: "alfajores",
  10: "optimism",
  420: "optimism-testnet",
  42161: "artbitrum",
  421613: "artbitrum-testnet",
  109: "shibarium",
  719: "shibarium-testnet",
  369: "pulsechain",
  943: "pulsechain-testnet",
  202212e5: "xen",
  202212: "xen-devnet",
  16666e5: "harmony",
  16667e5: "harmony-testnet",
  52: "coinex",
  53: "coinex-testnet",
  2e3: "dogecoin",
  568: "dogecoin-testnet",
  131: "engram-testnet",
  61: "etc",
  63: "etc-mordor",
  32659: "fusion",
  46688: "fusion-testnet",
  128: "heco",
  256: "eco-testnet",
  321: "kcc",
  322: "kcc-testnet",
  8217: "klaytn",
  1001: "baobab",
  66: "okxchain",
  65: "okxchain-testnet",
  361: "theta",
  365: "theta-testnet",
  1231: "ultron",
  1230: "ultron-testnet",
  1284: "moonbeam",
  1285: "moonriver",
  1287: "moonbase-alpha",
  1313161554: "aurora",
  1313161555: "aurora-testnet",
  288: "boba",
  2888: "boba-goerli",
  100: "gnosis",
  10200: "chiado",
  1088: "metis",
  599: "metis-goerli",
  40: "telos",
  41: "telos-testnet ",
  57: "syscoin",
  5700: "tanenbaum",
  4689: "iotex",
  4690: "iotex-testnet",
  336: "shiden",
  592: "astar",
  81: "shibunya",
  122: "fuse",
  123: "sparknet",
  106: "velas",
  111: "velas-testnet",
  55555: "rei",
  55556: "rei-testnet",
  420420: "kekchain",
  420666: "kekchain-testnet",
  88: "tomochain",
  89: "tomochain-testnet",
  108: "thundercore",
  18: "thundercore-testnet",
  888: "wanchain",
  999: "wanchain-testnet",
  30: "rsk",
  31: "rsk-testnet",
  20: "elastos",
  21: "elastos-testnet",
  1030: "conflux",
  71: "conflux-testnet",
  32520: "brisechain",
  64668: "brisechain-testnet",
  20402: "muuchain",
  42110: "muuchain-testnet",
  7700: "canto",
  7701: "canto-testnet",
  42262: "oasis",
  42261: "oasis-testnet",
  888888: "vision",
  666666: "vision-testnet",
  1234: "step",
  12345: "step-testnet",
  82: "meter",
  83: "meter-testnet",
  71402: "godwoken",
  71401: "godwoken-testnet",
  820: "callisto",
  20729: "callisto-testnet",
  9001: "evmos",
  9e3: "evmos-testnet",
  246: "energy-webchain",
  73799: "energy-volta",
  8453: "base",
  84531: "base-goerli",
  2222: "kava",
  2221: "kava-testnet",
  1024: "clover",
  1023: "clover-testnet",
  1131: "defichain",
  1132: "defichain-testnet",
  1039: "bronos",
  1038: "bronos-testnet",
  314: "filecoin",
  314159: "filecoin-calibration",
  14: "flare",
  114: "flare-testnet",
  841: "taraxa",
  842: "taraxa-testnet",
  324: "zksync",
  280: "zksync-testnet",
  59140: "linea-testnet",
  59144: "linea",
  204: "opBnb",
  5611: "opBnbTestnet",
  43288: "",
  56288: "",
  199: "",
  1029: "",
  256256: "",
  512512: "",
  1116: "",
  1115: "",
  1818: "",
  1819: "",
  44: "",
  43: "",
  15: "",
  13: "",
  60: "",
  31337: "",
  11235: "",
  54211: "",
  24: "",
  242: "",
  11: "",
  12: "",
  11297108109: "",
  11297108099: "",
  333999: "",
  333888: "",
  27: "",
  1e4: "",
  10001: "",
  19: "",
  7: "",
  8: "",
  9: "",
  100009: "",
  100010: "",
  50: "",
  51: "",
  534352: "",
  534351: "",
  7e3: "",
  7001: "",
  55: ""
};
var _provider;
var BloctoConnector = class extends Connector {
  constructor(config = {
    options: { defaultChainId: 56 }
  }) {
    var _a;
    const chains = (_a = config.chains) == null ? void 0 : _a.filter((c) => !!chainIdToNetwork[c.id]);
    super({
      chains,
      options: config.options
    });
    this.id = "blocto";
    this.name = "Blocto";
    this.ready = typeof window !== "undefined";
    __privateAdd(this, _provider, void 0);
    this.onAccountsChanged = (accounts) => {
      if (accounts.length === 0)
        this.emit("disconnect");
      else {
        let account = accounts[0];
        if (typeof account === "string" && !account.startsWith("0x")) {
          account = `0x${account}`;
        }
        this.emit("change", {
          account: getAddress(account)
        });
      }
    };
    this.onChainChanged = (chainId) => {
      const id = normalizeChainId(chainId);
      const unsupported = this.isChainUnsupported(id);
      this.emit("change", { chain: { id, unsupported } });
    };
    this.onDisconnect = () => {
      this.emit("disconnect");
    };
  }
  connect() {
    return __async(this, arguments, function* ({ chainId } = {}) {
      try {
        const provider = yield this.getProvider({ chainId });
        if (!provider)
          throw new ConnectorNotFoundError();
        if (provider.on) {
          provider.on("accountsChanged", this.onAccountsChanged);
          provider.on("chainChanged", this.onChainChanged);
          provider.on("disconnect", this.onDisconnect);
        }
        this.emit("message", { type: "connecting" });
        const account = yield this.getAccount();
        const id = yield this.getChainId();
        const unsupported = this.isChainUnsupported(id);
        return { account, chain: { id, unsupported }, provider };
      } catch (error) {
        this.disconnect();
        if (this.isUserRejectedRequestError(error))
          throw new UserRejectedRequestError(error);
        if (error.code === -32002)
          throw new ResourceNotFoundRpcError(error);
        throw error;
      }
    });
  }
  getProvider() {
    return __async(this, arguments, function* ({ chainId } = {}) {
      if (!__privateGet(this, _provider) || chainId) {
        const rpc = this.chains.reduce(
          // eslint-disable-next-line @typescript-eslint/no-shadow
          (rpc2, chain) => __spreadProps(__spreadValues({}, rpc2), { [chain.id]: chain.rpcUrls.default.http[0] }),
          {}
        );
        let targetChainId = chainId;
        if (!targetChainId) {
          const fallbackChainId = this.options.defaultChainId;
          if (fallbackChainId && !this.isChainUnsupported(fallbackChainId))
            targetChainId = fallbackChainId;
        }
        if (!targetChainId)
          throw new ChainNotConfiguredError({ chainId: targetChainId || 0, connectorId: this.id });
        const BloctoSDK = (yield import('@blocto/sdk')).default;
        __privateSet(this, _provider, new BloctoSDK({
          appId: this.options.appId,
          ethereum: {
            chainId: targetChainId,
            rpc: rpc[targetChainId]
          }
        }).ethereum);
      }
      if (!__privateGet(this, _provider))
        throw new ConnectorNotFoundError();
      return __privateGet(this, _provider);
    });
  }
  isAuthorized() {
    return __async(this, null, function* () {
      try {
        const provider = yield this.getProvider();
        if (!provider)
          throw new ConnectorNotFoundError();
        const accounts = provider.accounts;
        const account = accounts[0];
        return !!account;
      } catch (e) {
        return false;
      }
    });
  }
  getWalletClient() {
    return __async(this, arguments, function* ({ chainId } = {}) {
      const [provider, account] = yield Promise.all([this.getProvider({ chainId }), this.getAccount()]);
      const chain = this.chains.find((x) => x.id === chainId) || this.chains[0];
      if (!provider)
        throw new Error("provider is required.");
      return createWalletClient({
        account,
        chain,
        transport: custom(provider)
      });
    });
  }
  getAccount() {
    return __async(this, null, function* () {
      const provider = yield this.getProvider();
      if (!provider)
        throw new ConnectorNotFoundError();
      const accounts = yield provider.request({
        method: "eth_requestAccounts"
      });
      let account = accounts[0];
      if (typeof account === "string" && !account.startsWith("0x")) {
        account = `0x${account}`;
      }
      return getAddress(account);
    });
  }
  getChainId() {
    return __async(this, null, function* () {
      const provider = yield this.getProvider();
      if (!provider)
        throw new ConnectorNotFoundError();
      return provider.request({ method: "eth_chainId" }).then(normalizeChainId);
    });
  }
  disconnect() {
    return __async(this, null, function* () {
      const provider = yield this.getProvider();
      if (!(provider == null ? void 0 : provider.removeListener))
        return;
      provider.removeListener("accountsChanged", this.onAccountsChanged);
      provider.removeListener("chainChanged", this.onChainChanged);
      provider.removeListener("disconnect", this.onDisconnect);
    });
  }
  isUserRejectedRequestError(error) {
    return error.code === 4001;
  }
};
_provider = new WeakMap();

export { BloctoConnector };
