import { Address, PublicClient, formatUnits } from 'viem'
import BN from 'bignumber.js'
import { BIG_TWO, BIG_ZERO } from '@donaswap/utils/bigNumber'
import { ChainId } from '@donaswap/sdk'
import { getFarmsPrices } from './farmPrices'
import { fetchPublicFarmsData } from './fetchPublicFarmData'
import { fetchStableFarmData } from './fetchStableFarmData'
import { isStableFarm, SerializedFarmConfig } from '../types'
import { getFullDecimalMultiplier } from './getFullDecimalMultiplier'
import { FarmSupportedChainId } from '../masterChefV3Addresses'
import { supportedChainIdV2 } from '../supportedChainIdV2'

const evmNativeStableLpMap: Record<
  FarmSupportedChainId,
  {
    address: Address
    wNative: string
    stable: string
  }
> = {
  [ChainId.FIRECHAIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFIRE',
    stable: 'USDT',
  },
  [ChainId.RINIA]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFIRE',
    stable: 'USDT',
  },
  [ChainId.FIRECHAIN_ZKEVM]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.ETHEREUM]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDC',
  },
  [ChainId.GOERLI]: {
    address: '0xf5bf0C34d3c428A74Ceb98d27d38d0036C587200',
    wNative: 'WETH',
    stable: 'tUSDC',
  },
  [ChainId.SEPOLIA]: {
    address: '0xf5bf0C34d3c428A74Ceb98d27d38d0036C587200',
    wNative: 'WETH',
    stable: 'tUSDC',
  },
  [ChainId.HOLESKY]: {
    address: '0xf5bf0C34d3c428A74Ceb98d27d38d0036C587200',
    wNative: 'WETH',
    stable: 'tUSDC',
  },
  [ChainId.BSC]: {
    address: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    wNative: 'WBNB',
    stable: 'BUSD',
  },
  [ChainId.BSC_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBNB',
    stable: 'BUSD',
  },
  [ChainId.POLYGON]: {
    address: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    wNative: 'WMATIC',
    stable: 'BUSD',
  },
  [ChainId.POLYGON_AMOY]: {
    address: '0x7569a77F0366D4408BD43973c28cc313D92B4c7c',
    wNative: 'WMATIC',
    stable: 'USDT',
  },
  [ChainId.POLYGON_ZKEVM]: {
    address: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    wNative: 'WMATIC',
    stable: 'BUSD',
  },
  [ChainId.POLYGON_ZKEVM_TESTNET]: {
    address: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    wNative: 'WMATIC',
    stable: 'BUSD',
  },
  [ChainId.CRO]: {
    address: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    wNative: 'WCRO',
    stable: 'BUSD',
  },
  [ChainId.CRO_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCRO',
    stable: 'USDT',
  },
  [ChainId.AVALANCHE]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WAVAX',
    stable: 'BUSD',
  },
  [ChainId.AVALANCHE_FUJI]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WAVAX',
    stable: 'USDT',
  },
  [ChainId.FANTOM_OPERA]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFTM',
    stable: 'BUSD',
  },
  [ChainId.FANTOM_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFTM',
    stable: 'USDT',
  },
  [ChainId.CELO]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFTM',
    stable: 'BUSD',
  },
  [ChainId.CELO_ALFAJORES]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCELO',
    stable: 'USDT',
  },
  [ChainId.OPTIMISM]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFTM',
    stable: 'BUSD',
  },
  [ChainId.OPTIMISM_GOERLI]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.ARBITRUM_ONE]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'BUSD',
  },
  [ChainId.ARBITRUM_GOERLI]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.SHIBARIUM]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WBONE',
    stable: 'BUSD',
  },
  [ChainId.SHIBARIUM_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WBONE',
    stable: 'USDT',
  },
  [ChainId.PULSECHAIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WPLS',
    stable: 'BUSD',
  },
  [ChainId.PULSECHAIN_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WPLS',
    stable: 'USDT',
  },
  [ChainId.XEN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WXN',
    stable: 'BUSD',
  },
  [ChainId.XEN_DEVNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WXN',
    stable: 'USDT',
  },
  [ChainId.HARMONY]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WONE',
    stable: 'BUSD',
  },
  [ChainId.HARMONY_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WONE',
    stable: 'USDT',
  },
  [ChainId.COINEX]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCET',
    stable: 'BUSD',
  },
  [ChainId.COINEX_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCET',
    stable: 'USDT',
  },
  [ChainId.DOGECHAIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WDOGE',
    stable: 'BUSD',
  },
  [ChainId.DOGECHAIN_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WDOGE',
    stable: 'USDT',
  },
  [ChainId.ENGRAM_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WGRAM',
    stable: 'USDT',
  },
  [ChainId.ETHEREUM_CLASSIC]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETC',
    stable: 'BUSD',
  },
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETC',
    stable: 'USDT',
  },
  [ChainId.FUSION]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFSN',
    stable: 'BUSD',
  },
  [ChainId.FUSION_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFSN',
    stable: 'USDT',
  },
  [ChainId.HECO]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WHT',
    stable: 'BUSD',
  },
  [ChainId.HECO_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WHT',
    stable: 'USDT',
  },
  [ChainId.KCC]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WKCS',
    stable: 'BUSD',
  },
  [ChainId.KCC_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WKCS',
    stable: 'USDT',
  },
  [ChainId.KLAYTN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WKLAY',
    stable: 'BUSD',
  },
  [ChainId.KLAYTN_BAOBAB]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WKLAY',
    stable: 'USDT',
  },
  [ChainId.OKXCHAIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WOKT',
    stable: 'BUSD',
  },
  [ChainId.OKXCHAIN_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WOKT',
    stable: 'USDT',
  },
  [ChainId.THETHA]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WTFUEL',
    stable: 'BUSD',
  },
  [ChainId.THETHA_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WTFUEL',
    stable: 'USDT',
  },
  [ChainId.ULTRON]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WULX',
    stable: 'BUSD',
  },
  [ChainId.ULTRON_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WULX',
    stable: 'USDT',
  },
  [ChainId.MOONBEAM]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WGLMR',
    stable: 'BUSD',
  },
  [ChainId.MOONRIVER]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WMOVR',
    stable: 'USDT',
  },
  [ChainId.MOONBASE_ALPHA]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WDEV',
    stable: 'USDT',
  },
  [ChainId.AURORA]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.AURORA_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.BOBA]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.BOBA_GOERLI]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.GNOSIS]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WXDAI',
    stable: 'USDT',
  },
  [ChainId.GNOSIS_CHIADO]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WXDAI',
    stable: 'USDT',
  },
  [ChainId.METIS]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WMETIS',
    stable: 'USDT',
  },
  [ChainId.METIS_GOERLI]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WMETIS',
    stable: 'USDT',
  },
  [ChainId.TELOS]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WTLOS',
    stable: 'USDT',
  },
  [ChainId.TELOS_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WTLOS',
    stable: 'USDT',
  },
  [ChainId.SYSCOIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WSYS',
    stable: 'USDT',
  },
  [ChainId.SYSCOIN_TANENBAUM]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WSYS',
    stable: 'USDT',
  },
  [ChainId.IOTEX]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WIOTX',
    stable: 'USDT',
  },
  [ChainId.IOTEX_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WIOTX',
    stable: 'USDT',
  },
  [ChainId.SHIDEN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WSDN',
    stable: 'USDT',
  },
  [ChainId.ASTAR]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WASTR',
    stable: 'USDT',
  },
  [ChainId.SHIBUNYA]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WSBY',
    stable: 'USDT',
  },
  [ChainId.FUSE]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFUSE',
    stable: 'USDT',
  },
  [ChainId.FUSE_SPARKNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFUSE',
    stable: 'USDT',
  },
  [ChainId.VELAS]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WVLX',
    stable: 'USDT',
  },
  [ChainId.VELAS_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WVLX',
    stable: 'USDT',
  },
  [ChainId.REI]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WREI',
    stable: 'USDT',
  },
  [ChainId.REI_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WREI',
    stable: 'USDT',
  },
  [ChainId.KEKCHAIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WKEK',
    stable: 'USDT',
  },
  [ChainId.KEKCHAIN_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WKEK',
    stable: 'USDT',
  },
  [ChainId.TOMOCHAIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WTOMO',
    stable: 'USDT',
  },
  [ChainId.TOMOCHAIN_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WTOMO',
    stable: 'USDT',
  },
  [ChainId.THUNDERCORE]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WTT',
    stable: 'USDT',
  },
  [ChainId.THUNDERCORE_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WTT',
    stable: 'USDT',
  },
  [ChainId.WANCHAIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WWAN',
    stable: 'USDT',
  },
  [ChainId.WANCHAIN_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WWAN',
    stable: 'USDT',
  },
  [ChainId.RSK]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WRBTC',
    stable: 'USDT',
  },
  [ChainId.RSK_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WRBTC',
    stable: 'USDT',
  },
  [ChainId.ELASTOS]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WELA',
    stable: 'USDT',
  },
  [ChainId.ELASTOS_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WELA',
    stable: 'USDT',
  },
  [ChainId.CONFLUX]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCFX',
    stable: 'USDT',
  },
  [ChainId.CONFLUX_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCFX',
    stable: 'USDT',
  },
  [ChainId.BRISECHAIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WBRISE',
    stable: 'USDT',
  },
  [ChainId.BRISECHAIN_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WBRISE',
    stable: 'USDT',
  },
  [ChainId.MUUCHAIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WMUU',
    stable: 'USDT',
  },
  [ChainId.MUUCHAIN_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WMUU',
    stable: 'USDT',
  },
  [ChainId.CANTO]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCANTO',
    stable: 'USDT',
  },
  [ChainId.CANTO_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCANTO',
    stable: 'USDT',
  },
  [ChainId.OASIS_EMERALD]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WROSE',
    stable: 'USDT',
  },
  [ChainId.OASIS_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WROSE',
    stable: 'USDT',
  },
  [ChainId.VISION]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WVS',
    stable: 'USDT',
  },
  [ChainId.VISION_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WVS',
    stable: 'USDT',
  },
  [ChainId.STEP]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFITFI',
    stable: 'USDT',
  },
  [ChainId.STEP_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFITFI',
    stable: 'USDT',
  },
  [ChainId.METER]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WMTR',
    stable: 'USDT',
  },
  [ChainId.METER_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WMTR',
    stable: 'USDT',
  },
  [ChainId.GODWOKEN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WPCKB',
    stable: 'USDT',
  },
  [ChainId.GODWOKEN_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WPCKB',
    stable: 'USDT',
  },
  [ChainId.CALLISTO]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCLO',
    stable: 'USDT',
  },
  [ChainId.CALLISTO_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCLO',
    stable: 'USDT',
  },
  [ChainId.EVMOS]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WEVMOS',
    stable: 'USDT',
  },
  [ChainId.EVMOS_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WEVMOS',
    stable: 'USDT',
  },
  [ChainId.ENERGY_WEB_CHAIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WEWT',
    stable: 'USDT',
  },
  [ChainId.ENERGY_VOLTA]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WEWT',
    stable: 'USDT',
  },
  [ChainId.BASE]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.BASE_GOERLI]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.KAVA]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WKAVA',
    stable: 'USDT',
  },
  [ChainId.KAVA_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WKAVA',
    stable: 'USDT',
  },
  [ChainId.CLOVER]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCLV',
    stable: 'USDT',
  },
  [ChainId.CLOVER_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WCLV',
    stable: 'USDT',
  },
  [ChainId.DEFICHAIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WDFI',
    stable: 'USDT',
  },
  [ChainId.DEFICHAIN_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WDFI',
    stable: 'USDT',
  },
  [ChainId.BRONOS]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WBRO',
    stable: 'USDT',
  },
  [ChainId.BRONOS_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WBRO',
    stable: 'USDT',
  },
  [ChainId.FILECOIN]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFIL',
    stable: 'USDT',
  },
  [ChainId.FILECOIN_CALIBRATION]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WFIL',
    stable: 'USDT',
  },
  [ChainId.FLARE]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.FLARE_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.TARAXA]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.TARAXA_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.ZKSYNC]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.ZKSYNC_TESTNET]: {
    address: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.LINEA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.LINEA_TESTNET]: {
    address: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.BTTCHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBTT',
    stable: 'USDT',
  },
  [ChainId.BTTCHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBTT',
    stable: 'USDT',
  },
  [ChainId.BOBA_AVAX]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBOBA',
    stable: 'USDT',
  },
  [ChainId.BOBA_BNB]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBOBA',
    stable: 'USDT',
  },
  [ChainId.CMP]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCMP',
    stable: 'USDT',
  },
  [ChainId.CMP_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCMP',
    stable: 'USDT',
  },
  [ChainId.CORE]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCORE',
    stable: 'USDT',
  },
  [ChainId.CORE_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCORE',
    stable: 'USDT',
  },
  [ChainId.CUBECHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCUBE',
    stable: 'USDT',
  },
  [ChainId.CUBECHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCUBE',
    stable: 'USDT',
  },
  [ChainId.DARWINIA_CRAB]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WCRAB',
    stable: 'USDT',
  },
  [ChainId.DARWINIA_PANGOLIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPRING',
    stable: 'USDT',
  },
  [ChainId.DIODE_PRETNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WDIODE',
    stable: 'USDT',
  },
  [ChainId.DIODE_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WDIODE',
    stable: 'USDT',
  },
  [ChainId.GOCHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WGO',
    stable: 'USDT',
  },
  [ChainId.GOCHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WGO',
    stable: 'USDT',
  },
  [ChainId.HAQQ]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WISLM',
    stable: 'USDT',
  },
  [ChainId.HAQQ_TESTEDGE2]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WISLM',
    stable: 'USDT',
  },
  [ChainId.KARDIACHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKAI',
    stable: 'USDT',
  },
  [ChainId.KARDIACHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKAI',
    stable: 'USDT',
  },
  [ChainId.METADIUM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WMETA',
    stable: 'USDT',
  },
  [ChainId.METADIUM_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WKAL',
    stable: 'USDT',
  },
  [ChainId.OP_BNB]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBNB',
    stable: 'USDT',
  },
  [ChainId.OP_BNB_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBNB',
    stable: 'USDT',
  },
  [ChainId.PALM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPALM',
    stable: 'USDT',
  },
  [ChainId.PALM_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPALM',
    stable: 'USDT',
  },
  [ChainId.POLIS]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPOLIS',
    stable: 'USDT',
  },
  [ChainId.POLIS_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WPOLIS',
    stable: 'USDT',
  },
  [ChainId.SHIBACHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WSHIB',
    stable: 'USDT',
  },
  [ChainId.SMART_BCH]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBCH',
    stable: 'USDT',
  },
  [ChainId.SMART_BCH_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WBCH',
    stable: 'USDT',
  },
  [ChainId.SONGBIRD_CANARY]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WSNB',
    stable: 'USDT',
  },
  [ChainId.THAICHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WTCH',
    stable: 'USDT',
  },
  [ChainId.UBIQ]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WUBQ',
    stable: 'USDT',
  },
  [ChainId.UBIQ_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WUBQ',
    stable: 'USDT',
  },
  [ChainId.VECHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WVET',
    stable: 'USDT',
  },
  [ChainId.VECHAIN_TESTNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WVET',
    stable: 'USDT',
  },
  [ChainId.XINFIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXDC',
    stable: 'USDT',
  },
  [ChainId.XINFIN_APOTHEM]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WXDC',
    stable: 'USDT',
  },
  [ChainId.SCROLL]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.SCROLL_SEPOLIA]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.XRPL_DEVNET]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.ZETACHAIN]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.ZETACHAIN_ATHENS]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WETH',
    stable: 'USDT',
  },
  [ChainId.ZYX]: {
    address: '0x4E96D2e92680Ca65D58A0e2eB5bd1c0f44cAB897',
    wNative: 'WZYX',
    stable: 'USDT',
  },
}

export const getTokenAmount = (balance: BN, decimals: number) => {
  return balance.div(getFullDecimalMultiplier(decimals))
}

export type FetchFarmsParams = {
  farms: SerializedFarmConfig[]
  provider: ({ chainId }: { chainId: number }) => PublicClient
  isTestnet: boolean
  masterChefAddress: string
  chainId: number
  totalRegularAllocPoint: bigint
  totalSpecialAllocPoint: bigint
}

export async function farmV2FetchFarms({
  farms,
  provider,
  isTestnet,
  masterChefAddress,
  chainId,
  totalRegularAllocPoint,
  totalSpecialAllocPoint,
}: FetchFarmsParams) {
  if (!supportedChainIdV2.includes(chainId)) {
    return []
  }

  const stableFarms = farms.filter(isStableFarm)

  const [stableFarmsResults, poolInfos, lpDataResults] = await Promise.all([
    fetchStableFarmData(stableFarms, chainId, provider),
    fetchMasterChefData(farms, isTestnet, provider, masterChefAddress),
    fetchPublicFarmsData(farms, chainId, provider, masterChefAddress),
  ])

  const stableFarmsData = (stableFarmsResults as StableLpData[]).map(formatStableFarm)

  const stableFarmsDataMap = stableFarms.reduce<Record<number, FormatStableFarmResponse>>((map, farm, index) => {
    return {
      ...map,
      [farm.pid]: stableFarmsData[index],
    }
  }, {})

  const lpData = lpDataResults.map(formatClassicFarmResponse)

  const farmsData = farms.map((farm, index) => {
    try {
      return {
        ...farm,
        ...(stableFarmsDataMap[farm.pid]
          ? getStableFarmDynamicData({
              ...lpData[index],
              ...stableFarmsDataMap[farm.pid],
              token0Decimals: farm.token.decimals,
              token1Decimals: farm.quoteToken.decimals,
              price1: stableFarmsDataMap[farm.pid].price1,
            })
          : getClassicFarmsDynamicData({
              ...lpData[index],
              ...stableFarmsDataMap[farm.pid],
              token0Decimals: farm.token.decimals,
              token1Decimals: farm.quoteToken.decimals,
            })),
        ...getFarmAllocation({
          allocPoint: poolInfos[index]?.allocPoint,
          isRegular: poolInfos[index]?.isRegular,
          totalRegularAllocPoint,
          totalSpecialAllocPoint,
        }),
      }
    } catch (error) {
      console.error(error, farm, index, {
        allocPoint: poolInfos[index]?.allocPoint,
        isRegular: poolInfos[index]?.isRegular,
        token0Decimals: farm.token.decimals,
        token1Decimals: farm.quoteToken.decimals,
        totalRegularAllocPoint,
        totalSpecialAllocPoint,
      })
      throw error
    }
  })

  const farmsDataWithPrices = getFarmsPrices(farmsData, evmNativeStableLpMap[chainId as FarmSupportedChainId], 18)

  return farmsDataWithPrices
}

const masterChefV2Abi = [
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'poolInfo',
    outputs: [
      { internalType: 'uint256', name: 'accFlamePerShare', type: 'uint256' },
      { internalType: 'uint256', name: 'lastRewardBlock', type: 'uint256' },
      { internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
      { internalType: 'uint256', name: 'totalBoostedShare', type: 'uint256' },
      { internalType: 'bool', name: 'isRegular', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'poolLength',
    outputs: [{ internalType: 'uint256', name: 'pools', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalRegularAllocPoint',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSpecialAllocPoint',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: '_isRegular', type: 'bool' }],
    name: 'flamePerBlock',
    outputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const

const masterChefFarmCalls = (farm: SerializedFarmConfig, masterChefAddress: string) => {
  const { pid } = farm

  return pid || pid === 0
    ? ({
        abi: masterChefV2Abi,
        address: masterChefAddress as Address,
        functionName: 'poolInfo',
        args: [BigInt(pid)],
      } as const)
    : null
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const fetchMasterChefData = async (
  farms: SerializedFarmConfig[],
  isTestnet: boolean,
  provider: ({ chainId }: { chainId: number }) => PublicClient,
  masterChefAddress: string,
) => {
  try {
    const masterChefCalls = farms.map((farm) => masterChefFarmCalls(farm, masterChefAddress))
    const masterChefAggregatedCalls = masterChefCalls.filter(notEmpty)

    const chainId = isTestnet ? ChainId.BSC_TESTNET : ChainId.BSC
    const masterChefMultiCallResult = await provider({ chainId }).multicall({
      contracts: masterChefAggregatedCalls,
      allowFailure: false,
    })

    let masterChefChunkedResultCounter = 0
    return masterChefCalls.map((masterChefCall) => {
      if (masterChefCall === null) {
        return null
      }
      const data = masterChefMultiCallResult[masterChefChunkedResultCounter]
      masterChefChunkedResultCounter++
      return {
        accFlamePerShare: data[0],
        lastRewardBlock: data[1],
        allocPoint: data[2],
        totalBoostedShare: data[3],
        isRegular: data[4],
      }
    })
  } catch (error) {
    console.error('MasterChef Pool info data error', error)
    throw error
  }
}

export const fetchMasterChefV2Data = async ({
  provider,
  isTestnet,
  masterChefAddress,
}: {
  provider: ({ chainId }: { chainId: number }) => PublicClient
  isTestnet: boolean
  masterChefAddress: Address
}) => {
  try {
    const chainId = isTestnet ? ChainId.BSC_TESTNET : ChainId.BSC
    const [poolLength, totalRegularAllocPoint, totalSpecialAllocPoint, flamePerBlock] = await provider({
      chainId,
    }).multicall({
      contracts: [
        {
          abi: masterChefV2Abi,
          address: masterChefAddress,
          functionName: 'poolLength',
        },
        {
          abi: masterChefV2Abi,
          address: masterChefAddress,
          functionName: 'totalRegularAllocPoint',
        },
        {
          abi: masterChefV2Abi,
          address: masterChefAddress,
          functionName: 'totalSpecialAllocPoint',
        },
        {
          abi: masterChefV2Abi,
          address: masterChefAddress,
          functionName: 'flamePerBlock',
          args: [true],
        },
      ],
      allowFailure: false,
    })

    return {
      poolLength,
      totalRegularAllocPoint,
      totalSpecialAllocPoint,
      flamePerBlock,
    }
  } catch (error) {
    console.error('Get MasterChef data error', error)
    throw error
  }
}

type StableLpData = [balanceResponse, balanceResponse, balanceResponse, balanceResponse]

type FormatStableFarmResponse = {
  tokenBalanceLP: BN
  quoteTokenBalanceLP: BN
  price1: bigint
}

const formatStableFarm = (stableFarmData: StableLpData): FormatStableFarmResponse => {
  const [balance1, balance2, _, _price1] = stableFarmData
  return {
    tokenBalanceLP: new BN(balance1.toString()),
    quoteTokenBalanceLP: new BN(balance2.toString()),
    price1: _price1,
  }
}

const getStableFarmDynamicData = ({
  lpTokenBalanceMC,
  lpTotalSupply,
  quoteTokenBalanceLP,
  tokenBalanceLP,
  token0Decimals,
  token1Decimals,
  price1,
}: FormatClassicFarmResponse & {
  token1Decimals: number
  token0Decimals: number
  price1: bigint
}) => {
  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = getTokenAmount(tokenBalanceLP, token0Decimals)
  const quoteTokenAmountTotal = getTokenAmount(quoteTokenBalanceLP, token1Decimals)

  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenRatio =
    !lpTotalSupply.isZero() && !lpTokenBalanceMC.isZero() ? lpTokenBalanceMC.div(lpTotalSupply) : BIG_ZERO

  const tokenPriceVsQuote = formatUnits(price1, token0Decimals)

  // Amount of quoteToken in the LP that are staked in the MC
  const quoteTokenAmountMcFixed = quoteTokenAmountTotal.times(lpTokenRatio)

  // Amount of token in the LP that are staked in the MC
  const tokenAmountMcFixed = tokenAmountTotal.times(lpTokenRatio)

  const quoteTokenAmountMcFixedByTokenAmount = tokenAmountMcFixed.times(tokenPriceVsQuote)

  const lpTotalInQuoteToken = quoteTokenAmountMcFixed.plus(quoteTokenAmountMcFixedByTokenAmount)

  return {
    tokenAmountTotal: tokenAmountTotal.toString(),
    quoteTokenAmountTotal: quoteTokenAmountTotal.toString(),
    lpTotalSupply: lpTotalSupply.toString(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toString(),
    tokenPriceVsQuote,
  }
}

type balanceResponse = bigint

export type ClassicLPData = [balanceResponse, balanceResponse, balanceResponse, balanceResponse]

type FormatClassicFarmResponse = {
  tokenBalanceLP: BN
  quoteTokenBalanceLP: BN
  lpTokenBalanceMC: BN
  lpTotalSupply: BN
}

const formatClassicFarmResponse = (farmData: ClassicLPData): FormatClassicFarmResponse => {
  const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply] = farmData
  return {
    tokenBalanceLP: new BN(tokenBalanceLP.toString()),
    quoteTokenBalanceLP: new BN(quoteTokenBalanceLP.toString()),
    lpTokenBalanceMC: new BN(lpTokenBalanceMC.toString()),
    lpTotalSupply: new BN(lpTotalSupply.toString()),
  }
}

interface FarmAllocationParams {
  allocPoint?: bigint
  isRegular?: boolean
  totalRegularAllocPoint: bigint
  totalSpecialAllocPoint: bigint
}

const getFarmAllocation = ({
  allocPoint,
  isRegular,
  totalRegularAllocPoint,
  totalSpecialAllocPoint,
}: FarmAllocationParams) => {
  const _allocPoint = allocPoint ? new BN(allocPoint.toString()) : BIG_ZERO
  const totalAlloc = isRegular ? totalRegularAllocPoint : totalSpecialAllocPoint
  const poolWeight = !!totalAlloc && !!_allocPoint ? _allocPoint.div(totalAlloc.toString()) : BIG_ZERO

  return {
    poolWeight: poolWeight.toString(),
    multiplier: !_allocPoint.isZero() ? `${+_allocPoint.div(10).toString()}X` : `0X`,
  }
}

const getClassicFarmsDynamicData = ({
  lpTokenBalanceMC,
  lpTotalSupply,
  quoteTokenBalanceLP,
  tokenBalanceLP,
  token0Decimals,
  token1Decimals,
}: FormatClassicFarmResponse & {
  token0Decimals: number
  token1Decimals: number
  lpTokenStakedAmount?: string
}) => {
  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = getTokenAmount(tokenBalanceLP, token0Decimals)
  const quoteTokenAmountTotal = getTokenAmount(quoteTokenBalanceLP, token1Decimals)

  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenRatio =
    !lpTotalSupply.isZero() && !lpTokenBalanceMC.isZero() ? lpTokenBalanceMC.div(lpTotalSupply) : BIG_ZERO

  // // Amount of quoteToken in the LP that are staked in the MC
  const quoteTokenAmountMcFixed = quoteTokenAmountTotal.times(lpTokenRatio)

  // // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = quoteTokenAmountMcFixed.times(BIG_TWO)

  return {
    tokenAmountTotal: tokenAmountTotal.toString(),
    quoteTokenAmountTotal: quoteTokenAmountTotal.toString(),
    lpTotalSupply: lpTotalSupply.toString(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toString(),
    tokenPriceVsQuote:
      !quoteTokenAmountTotal.isZero() && !tokenAmountTotal.isZero()
        ? quoteTokenAmountTotal.div(tokenAmountTotal).toString()
        : BIG_ZERO.toString(),
    lpTokenStakedAmount: lpTokenBalanceMC.toString(),
  }
}
