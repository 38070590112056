import { ChainId } from '@donaswap/sdk'

import { pools as firechainPools, livePools as firechainLivePools } from './529'
import { pools as riniaPools, livePools as riniaLivePools } from './917'
import { pools as firechainZkevmPools, livePools as firechainZkevmLivePools } from './814'
import { pools as firechainZkevmGhostriderPools, livePools as firechainZkevmGhostriderLivePools } from './3885'
import { pools as ethPools, livePools as ethLivePools } from './1'
import { pools as goerliPools, livePools as goerliLivePools } from './5'
import { pools as sepoliaPools, livePools as sepoliaLivePools } from './11155111'
import { pools as holeskyPools, livePools as holeskyLivePools } from './17000'
import { pools as bscPools, livePools as bscLivePools } from './56'
import { pools as bscTestnetPools, livePools as bscTestnetLivePools } from './97'
import { pools as polygonPools, livePools as polygonLivePools } from './137'
import { pools as polygonAmoyPools, livePools as polygonAmoyLivePools } from './80002'
import { pools as polygonZkEvmPools, livePools as polygonZkEvmLivePools } from './1101'
import { pools as polygonZkEvmTestnetPools, livePools as polygonZkEvmTestnetLivePools } from './1442'
import { pools as cronosPools, livePools as cronosLivePools } from './25'
import { pools as cronosTestnetPools, livePools as cronosTestnetLivePools } from './338'
import { pools as avalanchePools, livePools as avalancheLivePools } from './43114'
import { pools as avalancheFujiPools, livePools as avalancheFujiLivePools } from './43113'
import { pools as fantomOperaPools, livePools as fantomOperaLivePools } from './250'
import { pools as fantomTestnetPools, livePools as fantomTestnetLivePools } from './4002'
import { pools as celoPools, livePools as celoLivePools } from './42220'
import { pools as celoAlfajoresPools, livePools as celoAlfajoresLivePools } from './44787'
import { pools as optimismPools, livePools as optimismLivePools } from './10'
import { pools as optimismGoerliPools, livePools as optimismGoerliLivePools } from './420'
import { pools as arbitrumPools, livePools as arbitrumLivePools } from './42161'
import { pools as arbitrumGoerliPools, livePools as arbitrumGoerliLivePools } from './421613'
import { pools as shibariumPools, livePools as shibariumLivePools } from './109'
import { pools as shibariumTestnetPools, livePools as shibariumTestnetLivePools } from './719'
import { pools as pulsechainPools, livePools as pulsechainLivePools } from './369'
import { pools as pulsechainTestnetPools, livePools as pulsechainTestnetLivePools } from './943'
import { pools as XenPools, livePools as XenLivePools } from './20221200000'
import { pools as XenDevnetPools, livePools as XenDevnetLivePools } from './202212'
import { pools as harmonyPools, livePools as harmonyLivePools } from './1666600000'
import { pools as harmonyTestnetPools, livePools as harmonyTestnetLivePools } from './1666700000'
import { pools as coinExPools, livePools as coinExLivePools } from './52'
import { pools as coinExTestnetPools, livePools as coinExTestnetLivePools } from './53'
import { pools as dogechainPools, livePools as dogechainLivePools } from './2000'
import { pools as dogechainTestnetPools, livePools as dogechainTestnetLivePools } from './568'
import { pools as engramTestnetPools, livePools as engramTestnetLivePools } from './131'
import { pools as etcPools, livePools as etcLivePools } from './61'
import { pools as etcTestnetPools, livePools as etcTestnetLivePools } from './63'
import { pools as fusionPools, livePools as fusionLivePools } from './32659'
import { pools as fusionTestnetPools, livePools as fusionTestnetLivePools } from './46688'
import { pools as hecoPools, livePools as hecoLivePools } from './128'
import { pools as hecoTestnetPools, livePools as hecoTestnetLivePools } from './256'
import { pools as kccPools, livePools as kccLivePools } from './321'
import { pools as kccTestnetPools, livePools as kccTestnetLivePools } from './322'
import { pools as klaytnPools, livePools as klaytnLivePools } from './8217'
import { pools as klaytnBaobabPools, livePools as klaytnBaobabLivePools } from './1001'
import { pools as okxchainPools, livePools as okxchainLivePools } from './66'
import { pools as okxchainTestnetPools, livePools as okxchainTestnetLivePools } from './65'
import { pools as thetaPools, livePools as thetaLivePools } from './361'
import { pools as thetaTestnetPools, livePools as thetaTestnetLivePools } from './365'
import { pools as ultronPools, livePools as ultronLivePools } from './1231'
import { pools as ultronTestnetPools, livePools as ultronTestnetLivePools } from './1230'
import { pools as moonbeamPools, livePools as moonbeamLivePools } from './1284'
import { pools as moonriverPools, livePools as moonriverLivePools } from './1285'
import { pools as moonbaseAlphaPools, livePools as moonbaseAlphaLivePools } from './1287'
import { pools as auroraPools, livePools as auroraLivePools } from './1313161554'
import { pools as auroraTestnetPools, livePools as auroraTestnetLivePools } from './1313161555'
import { pools as bobaPools, livePools as bobaLivePools } from './288'
import { pools as bobaGoerliPools, livePools as bobaGoerliLivePools } from './2888'
import { pools as gnosisPools, livePools as gnosisLivePools } from './100'
import { pools as gnosisChiadoPools, livePools as gnosisChiadoLivePools } from './10200'
import { pools as metisPools, livePools as metisLivePools } from './1088'
import { pools as metisGoerliPools, livePools as metisGoerliLivePools } from './599'
import { pools as telosPools, livePools as telosLivePools } from './40'
import { pools as telosTestnetPools, livePools as telosTestnetLivePools } from './41'
import { pools as syscoinPools, livePools as syscoinLivePools } from './57'
import { pools as syscoinTanenbaumPools, livePools as syscoinTanenbaumLivePools } from './5700'
import { pools as iotexPools, livePools as iotexLivePools } from './4689'
import { pools as iotexTestnetPools, livePools as iotexTestnetLivePools } from './4690'
import { pools as shidenPools, livePools as shidenLivePools } from './336'
import { pools as astarPools, livePools as astarLivePools } from './592'
import { pools as shibunyaTestnetPools, livePools as shibunyaTestnetLivePools } from './81'
import { pools as fusePools, livePools as fuseLivePools } from './122'
import { pools as fuseSparknetPools, livePools as fuseSparknetLivePools } from './123'
import { pools as velasPools, livePools as velasLivePools } from './106'
import { pools as velasTestnetsPools, livePools as velasTestnetsLivePools } from './111'
import { pools as reiPools, livePools as reiLivePools } from './55555'
import { pools as reiTestnetPools, livePools as reiTestnetLivePools } from './55556'
import { pools as kekchainPools, livePools as kekchainLivePools } from './420420'
import { pools as kekchainTestnetPools, livePools as kekchainTestnetLivePools } from './420666'
import { pools as tomochainPools, livePools as tomochainLivePools } from './88'
import { pools as tomochainTestnetPools, livePools as tomochainTestnetLivePools } from './89'
import { pools as thundercorePools, livePools as thundercoreLivePools } from './108'
import { pools as thundercoreTestnetPools, livePools as thundercoreTestnetLivePools } from './18'
import { pools as wanchainPools, livePools as wanchainLivePools } from './888'
import { pools as wanchainTestnetPools, livePools as wanchainTestnetLivePools } from './999'
import { pools as rskPools, livePools as rskLivePools } from './30'
import { pools as rskTestnetPools, livePools as rskTestnetLivePools } from './31'
import { pools as elastosPools, livePools as elastosLivePools } from './20'
import { pools as elastosTestnetPools, livePools as elastosTestnetLivePools } from './21'
import { pools as confluxPools, livePools as confluxLivePools } from './1030'
import { pools as confluxTestnetPools, livePools as confluxTestnetLivePools } from './71'
import { pools as brisechainPools, livePools as brisechainLivePools } from './32520'
import { pools as brisechainTestnetPools, livePools as brisechainTestnetLivePools } from './64668'
import { pools as muuchainPools, livePools as muuchainLivePools } from './20402'
import { pools as muuchainTestnetPools, livePools as muuchainTestnetLivePools } from './42110'
import { pools as cantoPools, livePools as cantoLivePools } from './7700'
import { pools as cantoTestnetPools, livePools as cantoTestnetLivePools } from './7701'
import { pools as oasisEmeraldPools, livePools as oasisEmeraldLivePools } from './42262'
import { pools as oasisTestnetPools, livePools as oasisTestnetLivePools } from './42261'
import { pools as visionPools, livePools as visionLivePools } from './888888'
import { pools as visionTestnetPools, livePools as visionTestnetLivePools } from './666666'
import { pools as stepPools, livePools as stepLivePools } from './1234'
import { pools as stepTestnetPools, livePools as stepTestnetLivePools } from './12345'
import { pools as meterPools, livePools as meterLivePools } from './82'
import { pools as meterTestnetPools, livePools as meterTestnetLivePools } from './83'
import { pools as godwokenPools, livePools as godwokenLivePools } from './71402'
import { pools as godwokenTestnetPools, livePools as godwokenTestnetLivePools } from './71401'
import { pools as callistoPools, livePools as callistoLivePools } from './820'
import { pools as callistoTestnetPools, livePools as callistoTestnetLivePools } from './20729'
import { pools as evmosPools, livePools as evmosLivePools } from './9001'
import { pools as evmosTestnetPools, livePools as evmosTestnetLivePools } from './9000'
import { pools as energyWebChainPools, livePools as energyWebChainLivePools } from './246'
import { pools as energyVoltaPools, livePools as energyVoltaLivePools } from './73799'
import { pools as basePools, livePools as baseLivePools } from './8453'
import { pools as baseGoerliPools, livePools as baseGoerliLivePools } from './84531'
import { pools as kavaPools, livePools as kavaLivePools } from './2222'
import { pools as kavaTestnetPools, livePools as kavaTestnetLivePools } from './2221'
import { pools as cloverPools, livePools as cloverLivePools } from './1024'
import { pools as cloverTestnetPools, livePools as cloverTestnetLivePools } from './1023'
import { pools as defichainPools, livePools as defichainLivePools } from './1131'
import { pools as defichainTestnetPools, livePools as defichainTestnetLivePools } from './1132'
import { pools as bronosPools, livePools as bronosLivePools } from './1039'
import { pools as bronosTestnetPools, livePools as bronosTestnetLivePools } from './1038'
import { pools as filecoinPools, livePools as filecoinLivePools } from './314'
import { pools as filecoinCalibrationPools, livePools as filecoinCalibrationLivePools } from './314159'
import { pools as flarePools, livePools as flareLivePools } from './14'
import { pools as flareTestnetPools, livePools as flareTestnetLivePools } from './114'
import { pools as taraxaPools, livePools as taraxaLivePools } from './841'
import { pools as taraxaTestnetPools, livePools as taraxaTestnetLivePools } from './842'
import { pools as zksyncPools, livePools as zksyncLivePools } from './324'
import { pools as zksyncTestnetPools, livePools as zksyncTestnetLivePools } from './280'
import { pools as lineaPools, livePools as lineaLivePools } from './59144'
import { pools as lineaTestnetPools, livePools as lineaTestnetLivePools } from './59140'
import { pools as opBnbPools, livePools as opBnbLivePools } from './204'
import { pools as opBnbTestnetPools, livePools as opBnbTestnetLivePools } from './5611'
import { pools as bobaAvaxPools, livePools as bobaAvaxLivePools } from './43288'
import { pools as bobaBnbPools, livePools as bobaBnbLivePools } from './56288'
import { pools as bttchainPools, livePools as bttchainLivePools } from './199'
import { pools as bttchainTestnetPools, livePools as bttchainTestnetLivePools } from './1029'
import { pools as cmpPools, livePools as cmpLivePools } from './256256'
import { pools as cmpTestnetPools, livePools as cmpTestnetLivePools } from './512512'
import { pools as corePools, livePools as coreLivePools } from './1116'
import { pools as coreTestnetPools, livePools as coreTestnetLivePools } from './1115'
import { pools as cubechainPools, livePools as cubechainLivePools } from './1818'
import { pools as cubechainTestnetPools, livePools as cubechainTestnetLivePools } from './1819'
import { pools as darwiniaCrabPools, livePools as darwiniaCrabLivePools } from './44'
import { pools as darwiniaPangolinPools, livePools as darwiniaPangolinLivePools } from './43'
import { pools as diodePretnetPools, livePools as diodePretnetLivePools } from './15'
import { pools as diodeTestnetPools, livePools as diodeTestnetLivePools } from './13'
import { pools as gochainPools, livePools as gochainLivePools } from './60'
import { pools as gochainTestnetPools, livePools as gochainTestnetLivePools } from './31337'
import { pools as haqqPools, livePools as haqqLivePools } from './11235'
import { pools as haqqTestedgePools, livePools as haqqTestedgeLivePools } from './54211'
import { pools as kardiachainPools, livePools as kardiachainLivePools } from './24'
import { pools as kardiachainTestnetPools, livePools as kardiachainTestnetLivePools } from './242'
import { pools as metadiumPools, livePools as metadiumLivePools } from './11'
import { pools as metadiumTestnetPools, livePools as metadiumTestnetLivePools } from './12'
import { pools as palmPools, livePools as palmLivePools } from './11297108109'
import { pools as palmTestnetPools, livePools as palmTestnetLivePools } from './11297108099'
import { pools as polisPools, livePools as polisLivePools } from './333999'
import { pools as polisTestnetPools, livePools as polisTestnetLivePools } from './333888'
import { pools as shibachainPools, livePools as shibachainLivePools } from './27'
import { pools as smartBchPools, livePools as smartBchLivePools } from './10000'
import { pools as smartBchTestnetPools, livePools as smartBchTestnetLivePools } from './10001'
import { pools as songbirdCanaryPools, livePools as songbirdCanaryLivePools } from './19'
import { pools as thaichainPools, livePools as thaichainLivePools } from './7'
import { pools as ubiqPools, livePools as ubiqLivePools } from './8'
import { pools as ubiqTestnetPools, livePools as ubiqTestnetLivePools } from './9'
import { pools as vechainPools, livePools as vechainLivePools } from './100009'
import { pools as vechainTestnetPools, livePools as vechainTestnetLivePools } from './100010'
import { pools as xinfinPools, livePools as xinfinLivePools } from './50'
import { pools as xinfinApothemPools, livePools as xinfinApothemLivePools } from './51'
import { pools as scrollPools, livePools as scrollLivePools } from './534352'
import { pools as scrollSepoliaPools, livePools as scrollSepoliaLivePools } from './534351'
import { pools as xrplDevnetPools, livePools as xrplDevnetLivePools } from './1440002'
import { pools as zetachainPools, livePools as zetachainLivePools } from './7000'
import { pools as zetachainAthensPools, livePools as zetachainAthensLivePools } from './7001'
import { pools as zyxPools, livePools as zyxLivePools } from './55'

import { SerializedPool } from '../../types'
import { SupportedChainId } from '../supportedChains'
import { isPoolsSupported } from '../../utils/isPoolsSupported'

export type PoolsConfigByChain<TChainId extends ChainId> = {
  [chainId in TChainId]: SerializedPool[]
}

export const POOLS_CONFIG_BY_CHAIN = {
  [ChainId.FIRECHAIN]: firechainPools,
  [ChainId.RINIA]: riniaPools,
  [ChainId.FIRECHAIN_ZKEVM]: firechainZkevmPools,
  [ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER]: firechainZkevmGhostriderPools,
  [ChainId.ETHEREUM]: ethPools,
  [ChainId.GOERLI]: goerliPools,
  [ChainId.SEPOLIA]: sepoliaPools,
  [ChainId.HOLESKY]: holeskyPools,
  [ChainId.BSC]: bscPools,
  [ChainId.BSC_TESTNET]: bscTestnetPools,
  [ChainId.POLYGON]: polygonPools,
  [ChainId.POLYGON_AMOY]: polygonAmoyPools,
  [ChainId.POLYGON_ZKEVM]: polygonZkEvmPools,
  [ChainId.POLYGON_ZKEVM_TESTNET]: polygonZkEvmTestnetPools,
  [ChainId.CRO]: cronosPools,
  [ChainId.CRO_TESTNET]: cronosTestnetPools,
  [ChainId.AVALANCHE]: avalanchePools,
  [ChainId.AVALANCHE_FUJI]: avalancheFujiPools,
  [ChainId.FANTOM_OPERA]: fantomOperaPools,
  [ChainId.FANTOM_TESTNET]: fantomTestnetPools,
  [ChainId.CELO]: celoPools,
  [ChainId.CELO_ALFAJORES]: celoAlfajoresPools,
  [ChainId.OPTIMISM]: optimismPools,
  [ChainId.OPTIMISM_GOERLI]: optimismGoerliPools,
  [ChainId.ARBITRUM_ONE]: arbitrumPools,
  [ChainId.ARBITRUM_GOERLI]: arbitrumGoerliPools,
  [ChainId.SHIBARIUM]: shibariumPools,
  [ChainId.SHIBARIUM_TESTNET]: shibariumTestnetPools,
  [ChainId.PULSECHAIN]: pulsechainPools,
  [ChainId.PULSECHAIN_TESTNET]: pulsechainTestnetPools,
  [ChainId.XEN]: XenPools,
  [ChainId.XEN_DEVNET]: XenDevnetPools,
  [ChainId.HARMONY]: harmonyPools,
  [ChainId.HARMONY_TESTNET]: harmonyTestnetPools,
  [ChainId.COINEX]: coinExPools,
  [ChainId.COINEX_TESTNET]: coinExTestnetPools,
  [ChainId.DOGECHAIN]: dogechainPools,
  [ChainId.DOGECHAIN_TESTNET]: dogechainTestnetPools,
  [ChainId.ENGRAM_TESTNET]: engramTestnetPools,
  [ChainId.ETHEREUM_CLASSIC]: etcPools,
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: etcTestnetPools,
  [ChainId.FUSION]: fusionPools,
  [ChainId.FUSION_TESTNET]: fusionTestnetPools,
  [ChainId.HECO]: hecoPools,
  [ChainId.HECO_TESTNET]: hecoTestnetPools,
  [ChainId.KCC]: kccPools,
  [ChainId.KCC_TESTNET]: kccTestnetPools,
  [ChainId.KLAYTN]: klaytnPools,
  [ChainId.KLAYTN_BAOBAB]: klaytnBaobabPools,
  [ChainId.OKXCHAIN]: okxchainPools,
  [ChainId.OKXCHAIN_TESTNET]: okxchainTestnetPools,
  [ChainId.THETHA]: thetaPools,
  [ChainId.THETHA_TESTNET]: thetaTestnetPools,
  [ChainId.ULTRON]: ultronPools,
  [ChainId.ULTRON_TESTNET]: ultronTestnetPools,
  [ChainId.MOONBEAM]: moonbeamPools,
  [ChainId.MOONRIVER]: moonriverPools,
  [ChainId.MOONBASE_ALPHA]: moonbaseAlphaPools,
  [ChainId.AURORA]: auroraPools,
  [ChainId.AURORA_TESTNET]: auroraTestnetPools,
  [ChainId.BOBA]: bobaPools,
  [ChainId.BOBA_GOERLI]: bobaGoerliPools,
  [ChainId.GNOSIS]: gnosisPools,
  [ChainId.GNOSIS_CHIADO]: gnosisChiadoPools,
  [ChainId.METIS]: metisPools,
  [ChainId.METIS_GOERLI]: metisGoerliPools,
  [ChainId.TELOS]: telosPools,
  [ChainId.TELOS_TESTNET]: telosTestnetPools,
  [ChainId.SYSCOIN]: syscoinPools,
  [ChainId.SYSCOIN_TANENBAUM]: syscoinTanenbaumPools,
  [ChainId.IOTEX]: iotexPools,
  [ChainId.IOTEX_TESTNET]: iotexTestnetPools,
  [ChainId.SHIDEN]: shidenPools,
  [ChainId.ASTAR]: astarPools,
  [ChainId.SHIBUNYA]: shibunyaTestnetPools,
  [ChainId.FUSE]: fusePools,
  [ChainId.FUSE_SPARKNET]: fuseSparknetPools,
  [ChainId.VELAS]: velasPools,
  [ChainId.VELAS_TESTNET]: velasTestnetsPools,
  [ChainId.REI]: reiPools,
  [ChainId.REI_TESTNET]: reiTestnetPools,
  [ChainId.KEKCHAIN]: kekchainPools,
  [ChainId.KEKCHAIN_TESTNET]: kekchainTestnetPools,
  [ChainId.TOMOCHAIN]: tomochainPools,
  [ChainId.TOMOCHAIN_TESTNET]: tomochainTestnetPools,
  [ChainId.THUNDERCORE]: thundercorePools,
  [ChainId.THUNDERCORE_TESTNET]: thundercoreTestnetPools,
  [ChainId.WANCHAIN]: wanchainPools,
  [ChainId.WANCHAIN_TESTNET]: wanchainTestnetPools,
  [ChainId.RSK]: rskPools,
  [ChainId.RSK_TESTNET]: rskTestnetPools,
  [ChainId.ELASTOS]: elastosPools,
  [ChainId.ELASTOS_TESTNET]: elastosTestnetPools,
  [ChainId.CONFLUX]: confluxPools,
  [ChainId.CONFLUX_TESTNET]: confluxTestnetPools,
  [ChainId.BRISECHAIN]: brisechainPools,
  [ChainId.BRISECHAIN_TESTNET]: brisechainTestnetPools,
  [ChainId.MUUCHAIN]: muuchainPools,
  [ChainId.MUUCHAIN_TESTNET]: muuchainTestnetPools,
  [ChainId.CANTO]: cantoPools,
  [ChainId.CANTO_TESTNET]: cantoTestnetPools,
  [ChainId.OASIS_EMERALD]: oasisEmeraldPools,
  [ChainId.OASIS_TESTNET]: oasisTestnetPools,
  [ChainId.VISION]: visionPools,
  [ChainId.VISION_TESTNET]: visionTestnetPools,
  [ChainId.STEP]: stepPools,
  [ChainId.STEP_TESTNET]: stepTestnetPools,
  [ChainId.METER]: meterPools,
  [ChainId.METER_TESTNET]: meterTestnetPools,
  [ChainId.GODWOKEN]: godwokenPools,
  [ChainId.GODWOKEN_TESTNET]: godwokenTestnetPools,
  [ChainId.CALLISTO]: callistoPools,
  [ChainId.CALLISTO_TESTNET]: callistoTestnetPools,
  [ChainId.EVMOS]: evmosPools,
  [ChainId.EVMOS_TESTNET]: evmosTestnetPools,
  [ChainId.ENERGY_WEB_CHAIN]: energyWebChainPools,
  [ChainId.ENERGY_VOLTA]: energyVoltaPools,
  [ChainId.BASE]: basePools,
  [ChainId.BASE_GOERLI]: baseGoerliPools,
  [ChainId.KAVA]: kavaPools,
  [ChainId.KAVA_TESTNET]: kavaTestnetPools,
  [ChainId.CLOVER]: cloverPools,
  [ChainId.CLOVER_TESTNET]: cloverTestnetPools,
  [ChainId.DEFICHAIN]: defichainPools,
  [ChainId.DEFICHAIN_TESTNET]: defichainTestnetPools,
  [ChainId.BRONOS]: bronosPools,
  [ChainId.BRONOS_TESTNET]: bronosTestnetPools,
  [ChainId.FILECOIN]: filecoinPools,
  [ChainId.FILECOIN_CALIBRATION]: filecoinCalibrationPools,
  [ChainId.FLARE]: flarePools,
  [ChainId.FLARE_TESTNET]: flareTestnetPools,
  [ChainId.TARAXA]: taraxaPools,
  [ChainId.TARAXA_TESTNET]: taraxaTestnetPools,
  [ChainId.ZKSYNC]: zksyncPools,
  [ChainId.ZKSYNC_TESTNET]: zksyncTestnetPools,
  [ChainId.LINEA]: lineaPools,
  [ChainId.LINEA_TESTNET]: lineaTestnetPools,
  [ChainId.BTTCHAIN]: bttchainPools,
  [ChainId.BTTCHAIN_TESTNET]: bttchainTestnetPools,
  [ChainId.BOBA_AVAX]: bobaAvaxPools,
  [ChainId.BOBA_BNB]: bobaBnbPools,
  [ChainId.CMP]: cmpPools,
  [ChainId.CMP_TESTNET]: cmpTestnetPools,
  [ChainId.CORE]: corePools,
  [ChainId.CORE_TESTNET]: coreTestnetPools,
  [ChainId.CUBECHAIN]: cubechainPools,
  [ChainId.CUBECHAIN_TESTNET]: cubechainTestnetPools,
  [ChainId.DARWINIA_CRAB]: darwiniaCrabPools,
  [ChainId.DARWINIA_PANGOLIN]: darwiniaPangolinPools,
  [ChainId.DIODE_PRETNET]: diodePretnetPools,
  [ChainId.DIODE_TESTNET]: diodeTestnetPools,
  [ChainId.GOCHAIN]: gochainPools,
  [ChainId.GOCHAIN_TESTNET]: gochainTestnetPools,
  [ChainId.HAQQ]: haqqPools,
  [ChainId.HAQQ_TESTEDGE2]: haqqTestedgePools,
  [ChainId.KARDIACHAIN]: kardiachainPools,
  [ChainId.KARDIACHAIN_TESTNET]: kardiachainTestnetPools,
  [ChainId.METADIUM]: metadiumPools,
  [ChainId.METADIUM_TESTNET]: metadiumTestnetPools,
  [ChainId.OP_BNB]: opBnbPools,
  [ChainId.OP_BNB_TESTNET]: opBnbTestnetPools,
  [ChainId.PALM]: palmPools,
  [ChainId.PALM_TESTNET]: palmTestnetPools,
  [ChainId.POLIS]: polisPools,
  [ChainId.POLIS_TESTNET]: polisTestnetPools,
  [ChainId.SHIBACHAIN]: shibachainPools,
  [ChainId.SMART_BCH]: smartBchPools,
  [ChainId.SMART_BCH_TESTNET]: smartBchTestnetPools,
  [ChainId.SONGBIRD_CANARY]: songbirdCanaryPools,
  [ChainId.THAICHAIN]: thaichainPools,
  [ChainId.UBIQ]: ubiqPools,
  [ChainId.UBIQ_TESTNET]: ubiqTestnetPools,
  [ChainId.VECHAIN]: vechainPools,
  [ChainId.VECHAIN_TESTNET]: vechainTestnetPools,
  [ChainId.XINFIN]: xinfinPools,
  [ChainId.XINFIN_APOTHEM]: xinfinApothemPools,
  [ChainId.SCROLL]: scrollPools,
  [ChainId.SCROLL_SEPOLIA]: scrollSepoliaPools,
  [ChainId.XRPL_DEVNET]: xrplDevnetPools,
  [ChainId.ZETACHAIN]: zetachainPools,
  [ChainId.ZETACHAIN_ATHENS]: zetachainAthensPools,
  [ChainId.ZYX]: zyxPools,
} as PoolsConfigByChain<SupportedChainId>

export const LIVE_POOLS_CONFIG_BY_CHAIN = {
  [ChainId.FIRECHAIN]: firechainLivePools,
  [ChainId.RINIA]: riniaLivePools,
  [ChainId.FIRECHAIN_ZKEVM]: firechainZkevmLivePools,
  [ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER]: firechainZkevmGhostriderLivePools,
  [ChainId.ETHEREUM]: ethLivePools,
  [ChainId.GOERLI]: goerliLivePools,
  [ChainId.SEPOLIA]: sepoliaLivePools,
  [ChainId.HOLESKY]: holeskyLivePools,
  [ChainId.BSC]: bscLivePools,
  [ChainId.BSC_TESTNET]: bscTestnetLivePools,
  [ChainId.POLYGON]: polygonLivePools,
  [ChainId.POLYGON_AMOY]: polygonAmoyLivePools,
  [ChainId.POLYGON_ZKEVM]: polygonZkEvmLivePools,
  [ChainId.POLYGON_ZKEVM_TESTNET]: polygonZkEvmTestnetLivePools,
  [ChainId.CRO]: cronosLivePools,
  [ChainId.CRO_TESTNET]: cronosTestnetLivePools,
  [ChainId.AVALANCHE]: avalancheLivePools,
  [ChainId.AVALANCHE_FUJI]: avalancheFujiLivePools,
  [ChainId.FANTOM_OPERA]: fantomOperaLivePools,
  [ChainId.FANTOM_TESTNET]: fantomTestnetLivePools,
  [ChainId.CELO]: celoLivePools,
  [ChainId.CELO_ALFAJORES]: celoAlfajoresLivePools,
  [ChainId.OPTIMISM]: optimismLivePools,
  [ChainId.OPTIMISM_GOERLI]: optimismGoerliLivePools,
  [ChainId.ARBITRUM_ONE]: arbitrumLivePools,
  [ChainId.ARBITRUM_GOERLI]: arbitrumGoerliLivePools,
  [ChainId.SHIBARIUM]: shibariumLivePools,
  [ChainId.SHIBARIUM_TESTNET]: shibariumTestnetLivePools,
  [ChainId.PULSECHAIN]: pulsechainLivePools,
  [ChainId.PULSECHAIN_TESTNET]: pulsechainTestnetLivePools,
  [ChainId.XEN]: XenLivePools,
  [ChainId.XEN_DEVNET]: XenDevnetLivePools,
  [ChainId.HARMONY]: harmonyLivePools,
  [ChainId.HARMONY_TESTNET]: harmonyTestnetLivePools,
  [ChainId.COINEX]: coinExLivePools,
  [ChainId.COINEX_TESTNET]: coinExTestnetLivePools,
  [ChainId.DOGECHAIN]: dogechainLivePools,
  [ChainId.DOGECHAIN_TESTNET]: dogechainTestnetLivePools,
  [ChainId.ENGRAM_TESTNET]: engramTestnetLivePools,
  [ChainId.ETHEREUM_CLASSIC]: etcLivePools,
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: etcTestnetLivePools,
  [ChainId.FUSION]: fusionLivePools,
  [ChainId.FUSION_TESTNET]: fusionTestnetLivePools,
  [ChainId.HECO]: hecoLivePools,
  [ChainId.HECO_TESTNET]: hecoTestnetLivePools,
  [ChainId.KCC]: kccLivePools,
  [ChainId.KCC_TESTNET]: kccTestnetLivePools,
  [ChainId.KLAYTN]: klaytnLivePools,
  [ChainId.KLAYTN_BAOBAB]: klaytnBaobabLivePools,
  [ChainId.OKXCHAIN]: okxchainLivePools,
  [ChainId.OKXCHAIN_TESTNET]: okxchainTestnetLivePools,
  [ChainId.THETHA]: thetaLivePools,
  [ChainId.THETHA_TESTNET]: thetaTestnetLivePools,
  [ChainId.ULTRON]: ultronLivePools,
  [ChainId.ULTRON_TESTNET]: ultronTestnetLivePools,
  [ChainId.MOONBEAM]: moonbeamLivePools,
  [ChainId.MOONRIVER]: moonriverLivePools,
  [ChainId.MOONBASE_ALPHA]: moonbaseAlphaLivePools,
  [ChainId.AURORA]: auroraLivePools,
  [ChainId.AURORA_TESTNET]: auroraTestnetLivePools,
  [ChainId.BOBA]: bobaLivePools,
  [ChainId.BOBA_GOERLI]: bobaGoerliLivePools,
  [ChainId.GNOSIS]: gnosisLivePools,
  [ChainId.GNOSIS_CHIADO]: gnosisChiadoLivePools,
  [ChainId.METIS]: metisLivePools,
  [ChainId.METIS_GOERLI]: metisGoerliLivePools,
  [ChainId.TELOS]: telosLivePools,
  [ChainId.TELOS_TESTNET]: telosTestnetLivePools,
  [ChainId.SYSCOIN]: syscoinLivePools,
  [ChainId.SYSCOIN_TANENBAUM]: syscoinTanenbaumLivePools,
  [ChainId.IOTEX]: iotexLivePools,
  [ChainId.IOTEX_TESTNET]: iotexTestnetLivePools,
  [ChainId.SHIDEN]: shidenLivePools,
  [ChainId.ASTAR]: astarLivePools,
  [ChainId.SHIBUNYA]: shibunyaTestnetLivePools,
  [ChainId.FUSE]: fuseLivePools,
  [ChainId.FUSE_SPARKNET]: fuseSparknetLivePools,
  [ChainId.VELAS]: velasLivePools,
  [ChainId.VELAS_TESTNET]: velasTestnetsLivePools,
  [ChainId.REI]: reiLivePools,
  [ChainId.REI_TESTNET]: reiTestnetLivePools,
  [ChainId.KEKCHAIN]: kekchainLivePools,
  [ChainId.KEKCHAIN_TESTNET]: kekchainTestnetLivePools,
  [ChainId.TOMOCHAIN]: tomochainLivePools,
  [ChainId.TOMOCHAIN_TESTNET]: tomochainTestnetLivePools,
  [ChainId.THUNDERCORE]: thundercoreLivePools,
  [ChainId.THUNDERCORE_TESTNET]: thundercoreTestnetLivePools,
  [ChainId.WANCHAIN]: wanchainLivePools,
  [ChainId.WANCHAIN_TESTNET]: wanchainTestnetLivePools,
  [ChainId.RSK]: rskLivePools,
  [ChainId.RSK_TESTNET]: rskTestnetLivePools,
  [ChainId.ELASTOS]: elastosLivePools,
  [ChainId.ELASTOS_TESTNET]: elastosTestnetLivePools,
  [ChainId.CONFLUX]: confluxLivePools,
  [ChainId.CONFLUX_TESTNET]: confluxTestnetLivePools,
  [ChainId.BRISECHAIN]: brisechainLivePools,
  [ChainId.BRISECHAIN_TESTNET]: brisechainTestnetLivePools,
  [ChainId.MUUCHAIN]: muuchainLivePools,
  [ChainId.MUUCHAIN_TESTNET]: muuchainTestnetLivePools,
  [ChainId.CANTO]: cantoLivePools,
  [ChainId.CANTO_TESTNET]: cantoTestnetLivePools,
  [ChainId.OASIS_EMERALD]: oasisEmeraldLivePools,
  [ChainId.OASIS_TESTNET]: oasisTestnetLivePools,
  [ChainId.VISION]: visionLivePools,
  [ChainId.VISION_TESTNET]: visionTestnetLivePools,
  [ChainId.STEP]: stepLivePools,
  [ChainId.STEP_TESTNET]: stepTestnetLivePools,
  [ChainId.METER]: meterLivePools,
  [ChainId.METER_TESTNET]: meterTestnetLivePools,
  [ChainId.GODWOKEN]: godwokenLivePools,
  [ChainId.GODWOKEN_TESTNET]: godwokenTestnetLivePools,
  [ChainId.CALLISTO]: callistoLivePools,
  [ChainId.CALLISTO_TESTNET]: callistoTestnetLivePools,
  [ChainId.EVMOS]: evmosLivePools,
  [ChainId.EVMOS_TESTNET]: evmosTestnetLivePools,
  [ChainId.ENERGY_WEB_CHAIN]: energyWebChainLivePools,
  [ChainId.ENERGY_VOLTA]: energyVoltaLivePools,
  [ChainId.BASE]: baseLivePools,
  [ChainId.BASE_GOERLI]: baseGoerliLivePools,
  [ChainId.KAVA]: kavaLivePools,
  [ChainId.KAVA_TESTNET]: kavaTestnetLivePools,
  [ChainId.CLOVER]: cloverLivePools,
  [ChainId.CLOVER_TESTNET]: cloverTestnetLivePools,
  [ChainId.DEFICHAIN]: defichainLivePools,
  [ChainId.DEFICHAIN_TESTNET]: defichainTestnetLivePools,
  [ChainId.BRONOS]: bronosLivePools,
  [ChainId.BRONOS_TESTNET]: bronosTestnetLivePools,
  [ChainId.FILECOIN]: filecoinLivePools,
  [ChainId.FILECOIN_CALIBRATION]: filecoinCalibrationLivePools,
  [ChainId.FLARE]: flareLivePools,
  [ChainId.FLARE_TESTNET]: flareTestnetLivePools,
  [ChainId.TARAXA]: taraxaLivePools,
  [ChainId.TARAXA_TESTNET]: taraxaTestnetLivePools,
  [ChainId.ZKSYNC]: zksyncLivePools,
  [ChainId.ZKSYNC_TESTNET]: zksyncTestnetLivePools,
  [ChainId.LINEA]: lineaLivePools,
  [ChainId.LINEA_TESTNET]: lineaTestnetLivePools,
  [ChainId.BTTCHAIN]: bttchainLivePools,
  [ChainId.BTTCHAIN_TESTNET]: bttchainTestnetLivePools,
  [ChainId.BOBA_AVAX]: bobaAvaxLivePools,
  [ChainId.BOBA_BNB]: bobaBnbLivePools,
  [ChainId.CMP]: cmpLivePools,
  [ChainId.CMP_TESTNET]: cmpTestnetLivePools,
  [ChainId.CORE]: coreLivePools,
  [ChainId.CORE_TESTNET]: coreTestnetLivePools,
  [ChainId.CUBECHAIN]: cubechainLivePools,
  [ChainId.CUBECHAIN_TESTNET]: cubechainTestnetLivePools,
  [ChainId.DARWINIA_CRAB]: darwiniaCrabLivePools,
  [ChainId.DARWINIA_PANGOLIN]: darwiniaPangolinLivePools,
  [ChainId.DIODE_PRETNET]: diodePretnetLivePools,
  [ChainId.DIODE_TESTNET]: diodeTestnetLivePools,
  [ChainId.GOCHAIN]: gochainLivePools,
  [ChainId.GOCHAIN_TESTNET]: gochainTestnetLivePools,
  [ChainId.HAQQ]: haqqLivePools,
  [ChainId.HAQQ_TESTEDGE2]: haqqTestedgeLivePools,
  [ChainId.KARDIACHAIN]: kardiachainLivePools,
  [ChainId.KARDIACHAIN_TESTNET]: kardiachainTestnetLivePools,
  [ChainId.METADIUM]: metadiumLivePools,
  [ChainId.METADIUM_TESTNET]: metadiumTestnetLivePools,
  [ChainId.OP_BNB]: opBnbLivePools,
  [ChainId.OP_BNB_TESTNET]: opBnbTestnetLivePools,
  [ChainId.PALM]: palmLivePools,
  [ChainId.PALM_TESTNET]: palmTestnetLivePools,
  [ChainId.POLIS]: polisLivePools,
  [ChainId.POLIS_TESTNET]: polisTestnetLivePools,
  [ChainId.SHIBACHAIN]: shibachainLivePools,
  [ChainId.SMART_BCH]: smartBchLivePools,
  [ChainId.SMART_BCH_TESTNET]: smartBchTestnetLivePools,
  [ChainId.SONGBIRD_CANARY]: songbirdCanaryLivePools,
  [ChainId.THAICHAIN]: thaichainLivePools,
  [ChainId.UBIQ]: ubiqLivePools,
  [ChainId.UBIQ_TESTNET]: ubiqTestnetLivePools,
  [ChainId.VECHAIN]: vechainLivePools,
  [ChainId.VECHAIN_TESTNET]: vechainTestnetLivePools,
  [ChainId.XINFIN]: xinfinLivePools,
  [ChainId.XINFIN_APOTHEM]: xinfinApothemLivePools,
  [ChainId.SCROLL]: scrollLivePools,
  [ChainId.SCROLL_SEPOLIA]: scrollSepoliaLivePools,
  [ChainId.XRPL_DEVNET]: xrplDevnetLivePools,
  [ChainId.ZETACHAIN]: zetachainLivePools,
  [ChainId.ZETACHAIN_ATHENS]: zetachainAthensLivePools,
  [ChainId.ZYX]: zyxLivePools,
} as PoolsConfigByChain<SupportedChainId>

export const getPoolsConfig = (chainId: ChainId) => {
  if (!isPoolsSupported(chainId)) {
    return undefined
  }
  return POOLS_CONFIG_BY_CHAIN[chainId]
}

export const getLivePoolsConfig = (chainId: ChainId) => {
  if (!isPoolsSupported(chainId)) {
    return undefined
  }
  return LIVE_POOLS_CONFIG_BY_CHAIN[chainId]
}

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = 20000000000000n
export const DURATION_FACTOR = 31536000n
