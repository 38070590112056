import { __async } from '../chunk-WE74IZDZ.mjs';
import { ConnectorNotFoundError } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { getConfig } from '@wagmi/core';
import '@wagmi/core/window';
import { UserRejectedRequestError, ResourceUnavailableRpcError, getAddress } from 'viem';

var mappingNetwork = {
  529: "firechain",
  917: "rinia",
  814: "firechain-zkevm",
  3885: "firechain-zkevm-ghostrider",
  1: "eth-mainnet",
  5: "eth-goerli",
  56: "bsc-mainnet",
  97: "bsc-testnet",
  11155111: "sepolia",
  17e3: "holesky",
  137: "polygon-mainnet",
  80002: "polygon-amoy",
  1101: "polygon-zkevm",
  1442: "polygon-zkevm-testnet",
  43114: "avalanche",
  43113: "avalanche-fuji",
  250: "fantom-opera",
  4002: "fantom-testnet",
  42220: "celo",
  44787: "celo-alfajores",
  10: "optimism",
  420: "optimism-testnet",
  42161: "artbitrum",
  421613: "artbitrum-testnet",
  109: "shibarium",
  719: "shibarium-testnet",
  369: "pulsechain",
  943: "pulsechain-testnet",
  202212e5: "xen",
  202212: "xen-devnet",
  16666e5: "harmony",
  16667e5: "harmony-testnet",
  52: "coinex",
  53: "coinex-testnet",
  2e3: "dogecoin",
  568: "dogecoin-testnet",
  131: "engram-testnet",
  61: "etc",
  63: "etc-mordor",
  32659: "fusion",
  46688: "fusion-testnet",
  128: "heco",
  256: "eco-testnet",
  321: "kcc",
  322: "kcc-testnet",
  8217: "klaytn",
  1001: "klaytn-baobab",
  66: "okxchain",
  65: "okxchain-testnet",
  361: "theta",
  365: "theta-testnet",
  1231: "ultron",
  1230: "ultron-testnet",
  1284: "moonbeam",
  1285: "moonriver",
  1287: "moonbase-alpha",
  1313161554: "aurora",
  1313161555: "aurora-testnet",
  288: "boba",
  2888: "boba-goerli",
  100: "gnosis",
  10200: "gnosis-chiado",
  1088: "metis",
  599: "metis-goerli",
  40: "telos",
  41: "telos-testnet ",
  57: "syscoin",
  5700: "syscoin-tanenbaum",
  4689: "iotex",
  4690: "iotex-testnet",
  336: "shiden",
  592: "astar",
  81: "shibunya",
  122: "fuse",
  123: "fuse-sparknet",
  106: "velas",
  111: "velas-testnet",
  55555: "rei",
  55556: "rei-testnet",
  420420: "kekchain",
  420666: "kekchain-testnet",
  88: "tomochain",
  89: "tomochain-testnet",
  108: "thundercore",
  18: "thundercore-testnet",
  888: "wanchain",
  999: "wanchain-testnet",
  30: "rsk",
  31: "rsk-testnet",
  20: "elastos",
  21: "elastos-testnet",
  1030: "conflux",
  71: "conflux-testnet",
  32520: "brisechain",
  64668: "brisechain-testnet",
  20402: "muuchain",
  42110: "muuchain-testnet",
  7700: "canto",
  7701: "canto-testnet",
  42262: "oasis",
  42261: "oasis-testnet",
  888888: "vision",
  666666: "vision-testnet",
  1234: "step",
  12345: "step-testnet",
  82: "meter",
  83: "meter-testnet",
  71402: "godwoken",
  71401: "godwoken-testnet",
  820: "callisto",
  20729: "callisto-testnet",
  9001: "evmos",
  9e3: "evmos-testnet",
  246: "energy-webchain",
  73799: "energy-volta",
  8453: "base",
  84531: "base-goerli",
  2222: "kava",
  2221: "kava-testnet",
  1024: "clover",
  1023: "clover-testnet",
  1131: "defichain",
  1132: "defichain-testnet",
  1039: "bronos",
  1038: "bronos-testnet",
  314: "filecoin",
  314159: "filecoin-calibration",
  14: "flare",
  114: "flare-testnet",
  841: "taraxa",
  842: "taraxa-testnet",
  324: "zksync",
  280: "zksync-testnet",
  59140: "linea-testnet",
  59144: "linea",
  204: "opBnb",
  5611: "opBnbTestnet",
  43288: "",
  56288: "",
  199: "",
  1029: "",
  256256: "",
  512512: "",
  1116: "",
  1115: "",
  1818: "",
  1819: "",
  44: "",
  43: "",
  15: "",
  13: "",
  60: "",
  31337: "",
  11235: "",
  54211: "",
  24: "",
  242: "",
  11: "",
  12: "",
  11297108109: "",
  11297108099: "",
  333999: "",
  333888: "",
  27: "",
  1e4: "",
  10001: "",
  19: "",
  7: "",
  8: "",
  9: "",
  100009: "",
  100010: "",
  50: "",
  51: "",
  534352: "",
  534351: "",
  7e3: "",
  7001: "",
  55: ""
};
function getTrustWalletProvider() {
  var _a;
  const isTrustWallet = (ethereum) => {
    const trustWallet = !!ethereum.isTrust;
    return trustWallet;
  };
  const injectedProviderExist = typeof window !== "undefined" && typeof window.ethereum !== "undefined";
  if (!injectedProviderExist) {
    return;
  }
  if (isTrustWallet(window.ethereum)) {
    return window.ethereum;
  }
  if ((_a = window.ethereum) == null ? void 0 : _a.providers) {
    return window.ethereum.providers.find(isTrustWallet);
  }
  return window.trustwallet;
}
var TrustWalletConnector = class extends InjectedConnector {
  constructor({
    chains: _chains,
    options: _options
  } = {}) {
    var _a, _b;
    const options = {
      name: "Trust Wallet",
      shimDisconnect: (_a = _options == null ? void 0 : _options.shimDisconnect) != null ? _a : false,
      shimChainChangedDisconnect: (_b = _options == null ? void 0 : _options.shimChainChangedDisconnect) != null ? _b : true
    };
    const chains = _chains == null ? void 0 : _chains.filter((c) => !!mappingNetwork[c.id]);
    super({
      chains,
      options
    });
    this.id = "trustWallet";
  }
  handleFailedConnect(error) {
    if (this.isUserRejectedRequestError(error)) {
      throw new UserRejectedRequestError(error);
    }
    if (error.code === -32002) {
      throw new ResourceUnavailableRpcError(error);
    }
    throw error;
  }
  connect() {
    return __async(this, arguments, function* ({ chainId } = {}) {
      var _a, _b, _c, _d;
      try {
        const provider = yield this.getProvider();
        if (!provider) {
          throw new ConnectorNotFoundError();
        }
        if (provider.on) {
          provider.on("accountsChanged", this.onAccountsChanged);
          provider.on("chainChanged", this.onChainChanged);
          provider.on("disconnect", this.onDisconnect);
        }
        this.emit("message", { type: "connecting" });
        let account = null;
        if (((_a = this.options) == null ? void 0 : _a.shimDisconnect) && !((_b = getConfig().storage) == null ? void 0 : _b.getItem(this.shimDisconnectKey))) {
          account = yield this.getAccount().catch(() => null);
          const isConnected = !!account;
          if (isConnected) {
            try {
              yield provider.request({
                method: "wallet_requestPermissions",
                params: [{ eth_accounts: {} }]
              });
              account = yield this.getAccount();
            } catch (error) {
              if (this.isUserRejectedRequestError(error)) {
                throw new UserRejectedRequestError(error);
              }
            }
          }
        }
        if (!account) {
          const accounts = yield provider.request({
            method: "eth_requestAccounts"
          });
          account = getAddress(accounts[0]);
        }
        let id = yield this.getChainId();
        let unsupported = this.isChainUnsupported(id);
        if (chainId && id !== chainId) {
          const chain = yield this.switchChain(chainId);
          id = chain.id;
          unsupported = this.isChainUnsupported(id);
        }
        if ((_c = this.options) == null ? void 0 : _c.shimDisconnect) {
          (_d = getConfig().storage) == null ? void 0 : _d.setItem(this.shimDisconnectKey, true);
        }
        return { account, chain: { id, unsupported }, provider };
      } catch (error) {
        this.handleFailedConnect(error);
      }
    });
  }
  getProvider() {
    return __async(this, null, function* () {
      return getTrustWalletProvider();
    });
  }
};

export { TrustWalletConnector, getTrustWalletProvider };
